<template>
    <div class="col-md-12 mt-3 map">
        <div v-html="ifarm"></div>
    </div>
</template>

<script>

export default {
    name: 'Iframe-Map',
    props: {
        ifarm: String,
    },
}
</script>

<style scoped>
.map{
    display: flex;
    justify-content: center;
}
.map div{
    width: 100% !important;
}

</style>