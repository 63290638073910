import { render, staticRenderFns } from "./Suggestion.vue?vue&type=template&id=b309660a&scoped=true&"
import script from "./Suggestion.vue?vue&type=script&lang=js&"
export * from "./Suggestion.vue?vue&type=script&lang=js&"
import style0 from "./Suggestion.vue?vue&type=style&index=0&id=b309660a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b309660a",
  null
  
)

export default component.exports