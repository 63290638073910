<template>
    <div class="container-fluid ">
        <!-- <Rubrique></Rubrique> -->
    <PaginationTitle :article="hotel" class="mt-5"></PaginationTitle>

        <div class="container">
            <div class="row ">
                <div class="col-md-9 search">
                    <div class="row mt-5 ">
                        <div class="col-md-12 py-3">
                            <carousel :starting-image="2" :images="images" :activite_id="hotel.id"
                                @AddFavorite="ajtFavorite($event)" :auto-slide-interval="5000"
                                :show-progress-bar="false">
                            </carousel>
                            <div class="card-body">
                                <Information :hotel="hotel" :totalEtoiles="totalEtoiles" />
                                <!-- <table class="table table-striped">
                                    <thead>
                                        <tr style="color: white" class="text-center">
                                            <th scope="col"
                                                style="background-color: #b90000;border-top-left-radius: 25px;">
                                                Activité
                                            </th>
                                            <th scope="col" style="background-color: #274f47;">Durée</th>
                                            <th scope="col"
                                                style="background-color: #de6c0b;border-top-right-radius: 25px;">
                                                Prix à partir de</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="col-md-6 py-3" v-for="type_activite of items"
                                            v-bind:key="type_activite.id">
                                            <td class="text-center" v-html="type_activite.activite">
                                            </td>
                                            <td>{{ type_activite.duree }}</td>
                                            <td class="text-center">{{ type_activite.prix_actv }} dhs</td>
                                        </tr>
                                    </tbody>
                                </table> -->
                                <!-- <p style="color:#0172ae;font-size: 25px">Langues parlées</p>
                                <span class="card-description mb-4" style="font-size: 18px;padding-right:6px;"
                                    v-bind:key="langue.id" v-for="langue of langues">{{ langue.langue.langue }}</span> -->
                            </div>
                            <SocialMedia :hotel="hotel" />
                            <div class="col-md-12 mt-3 commentaires">
                                <Commentaire :commentaires="commentaires"  :map="hotel.map" :errors="errors" :guide_id="hotel.id"
                                    @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)"
                                    @DeleteComment="supComment($event)" />
                            </div>
                        </div>
                        <ReservtionModal title="guide touristiques" :guide_id="hotel.id" :errors="errors"
                            :owner_email="hotel.user ? hotel.user.email : ''" @Reserver="reserverGuide($event)" />
                    </div>
                </div>
                <Sidebar></Sidebar>
            </div>
        </div>
    </div>
</template>
<script>


import http from "./../../http-common";
import Carousel from './../Outils/Carousel.vue'
// import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ReservtionModal from "./CardComponents/ReservtionModal.vue";
import Information from "./CardComponents/Information.vue";
import SocialMedia from "./CardComponents/SocialMedia.vue";
import Commentaire from "./CardComponents/Commentaire.vue";
import PaginationTitle from "./PaginationTitle.vue";
import seo from "../../seo.js";
import  $i18n  from '../../i18n.js';
export default {
    metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.hotel,this.images);
    }
    else{
      return seo.metaInfo_ar(this.hotel,this.images);
    }
  },
    name: "HotelViewComponent",
    components: { Carousel, /*Rubrique,*/ Sidebar, ReservtionModal, Information, SocialMedia, Commentaire,PaginationTitle },
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            fields: ['Type de Chambre', 'Nombre de personne', 'Options', 'Prix à partie de'],
            user_id: localStorage.getItem('id'),
            guide: [],
            items: [],
            commentaires: [],
            langues: [],
            images: [],
            errors: [],
            hotel: [],
            //items:this.hotels,
            perPage: 2,
            currentPage: 1
        }
    },
    mounted() {
        const id = this.id;
        //console.log(id);
        http.get(`/guides-touristique/${id}`)
            .then((response) => {
                var listT = [];
                this.hotel = response.data
                response.data.images.map(function (value) {
                    listT.push(value.image);
                });
                if (this.hotel.image && this.hotel.image !== '') {
                    listT.push(this.hotel.image);
                }
                this.images = listT;
                this.items = this.hotel.type_activite;
                this.commentaires = this.hotel.commentaires;
                let userId = this.user_id;
                this.commentaires = this.commentaires.map(function (field) {
                    return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
                })
                this.langues = this.hotel.langue_guides;
            })
            .catch(e => {
                this.errors.push(e)
            })
    },
    methods: {
        ajtFavorite(event) {
            http.post('/favorites', event)
                .then(() => {
                    this.$toaster.success('Votre activités touristiques a été enregistré.')
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.errors = e.response.data.errors;
                        // this.$toaster.error(e.response)
                    }
                })
        },
        ajtComment(event) {
            http.post('/commentaires', event)
                .then(() => {
                    this.getCommentaires();
                    this.$toaster.success(this.$t('toaster.commentaires.ajouter'))
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.errors = e.response.data.errors;
                        // this.$toaster.error(e.response)
                    }
                })
        },
        mdfComment(event) {
            http.post(`/commentaires/${event.id}`, event).then(() => {
                this.getCommentaires();
                this.$toaster.error(this.$t('toaster.commentaires.modifier'))
            })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        supComment(event) {
            http.delete(`/commentaires/${event.id}`).then(() => {
                this.getCommentaires();
                this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
            })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
        },
        getCommentaires() {
            const id = this.id;
            http.get(`/guides-touristique/${id}`)
                .then((response) => {
                    this.commentaires = response.data.commentaires
                    let userId = this.user_id;
                    this.commentaires = this.commentaires.map(function (field) {
                        return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
                    })
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        async reserverGuide(event) {
            await http.post('/reservations', event)
                .then(() => {
                    this.$root.$emit('bv::hide::modal', 'modal-reservation');
                    this.$toaster.error('Votre Bien a été réserver.')
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        getResteOfImageNumbre() {
            return this.images.length > 4 ? ' + ' + (this.images.length - 4) : '  ';
        },
    },
    computed: {
        lists() {
            //const options = this.items
            // Return just page of items needed options ,nbr_personnes
            let list = [];

            /*this.items.map(function(value) {
              list.push([value['type'],value['nbr_personnes'],value['options'],value['prix']]);
            });*/
            return list
        },
        totalEtoiles() {
            return this.guide.nbr_etoiles > 5 ? 5 : this.guide.nbr_etoiles
        },
        id() {
            // We will see what `params` is shortly
            return this.$route.params.id

        }
    }
}
/*
export default {
  data () {
    return {
      currentPage: 1,
      perPage: 5,
    }
  },
  computed: {
    lists () {
      const items = this.$store.getters.loadedLists
      // Return just page of items needed
      return items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      )
    },
    totalRows () {
      return this.$store.getters.loadedLists.length
    }
  }
}
 */
</script>

