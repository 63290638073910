<template>
    <div class="col-md-12 mb-5">
        <div class="col-md-12 line"></div>
        <div class="row espace-voyageur top-forme justify-content-center">
            <div class="col-md-10 bg-white dir-rtl">
                <h1 class=""></h1>
                <!-- <form action=""> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="nom">{{ $t('forms.inscription.nom') }}</label>
                            <input type="text" v-model="user.nom" readonly class="form-control" id="nom" placeholder="">
                            <span class="text-danger" v-if="errors.nom">
                                {{ errors.nom[0] }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="prenom">{{ $t('forms.inscription.prenom') }}</label>
                            <input type="text" v-model="user.prenom" readonly class="form-control" id="prenom" placeholder="">
                            <span class="text-danger" v-if="errors.prenom">
                                {{ errors.prenom[0] }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="pseudo">{{ $t('forms.inscription.pseudo') }}</label>
                            <input type="text" v-model="user.pseudo" class="form-control" id="pseudo" placeholder="">
                            <span class="text-danger" v-if="errors.pseudo">
                                {{ errors.pseudo[0] }}
                            </span>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="username">{{ $t('forms.inscription.nom_utilisateur')
                            }}</label>
                            <input type="text" v-model="user.name" readonly class="form-control" id="username"
                                placeholder="">
                            <span class="text-danger" v-if="errors.name">
                                {{ errors.name[0] }}
                            </span>
                        </div>
                    </div> -->
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="email">{{ $t('forms.inscription.email') }}</label>
                            <input type="email" class="form-control" v-model="user.email" readonly placeholder="">
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-4">
                        <button type="submit" class="btn btn-modifer" @click.prevent="ModifierInfo(user.id)">Modifier</button>
                    </div>
                </div>
                <!-- </form> -->
            </div>
        </div>
    </div>

</template>

<script>
import User from "@/apis/User";
export default {
    name: "InfoDuCompte",
    components: {
    },
    data() {
        return {
            user: {
                nom: "",
                prenom: "",
                name: "",
                pseudo: "",
            },
            errors: [],
            currentUser: {},
            token: localStorage.getItem('token'),
            isLoggedIn: false
        }
    },
    methods: {
        getUser() {
            User.auth().then(response => {
                this.user = response.data;
            }).catch((errors) => {
                console.log(errors)
            });
        },
        ModifierInfo() {
            User.updateprofil(this.user)
                .then(() => {
                    //this.$router.push({ name: "CompteInfo" });
                    this.$toaster.success('Votre compte a été mis à jour.')
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        }

    },
    created() {
        this.getUser()

        this.isCreated = true
    },
}
</script>

<style scoped>
.line {

    border-bottom: 5px solid #ff8601;

}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    border: 2px solid #ff8601;
}

.control-label {
    color: #ff8601;
}

.btn-modifer {
    background-color: #ff8601 !important;
    background: #ff8601 !important;
    font-family: GillSansMT !important;
    font-size: 20px !important;
    color: white !important;
    padding: 0px 40px !important;
    border-radius: 25px !important;
}
</style>