<template>
  <div class="container-fluid">
    <div class="container ">
      <div class="row">
        <div class="col-md-12 page-404" style="padding-top: 100px">
            <div class="row justify-content-center">
                <div class="col-md-6">
                     <div class="text-center">
                  <h2 class="title">      
                      <i class='fa fa-envelope' style='color:#de6401;font-size:100px;margin-bottom:20px;'></i><br>
                     Confirmez votre adresse e-mail</h2>


                            <div v-if="showMessage" class="alert alert-warning alert-dismissible fade show" role="alert">
                              <strong style="text-transform:uppercase;">Attention :  </strong> {{ message }}
                              <button type="button" class="close" @click="showMessage = false;" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>


                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input type="text" name="" id=""  v-model="form.email" class="form-control text-center"  placeholder="" aria-describedby="helpId">
                                    
                                </div>
                                  <div class="form-group">
                                    <label for="">Code vérification</label>
                                    <input type="text" name="" id="" v-model="form.codeVerification" class="form-control text-center" placeholder="" aria-describedby="helpId">
                                    
                                </div>


                                <button class="btn btn-info pl-5 pr-5" type="button" @click="Envoyer()">Valider</button>

                          

             </div>
                </div>
            </div>
           
        </div>
 
      </div>
    </div>
  </div>
</template>

<script>

import http from "./../http-common";

export default {
  name: "VerificationFormComponent",
  components: {  },
   data() {
    return {
      form: {        
        email: '',
        codeVerification: '',        
      },
      message:'',
      showMessage:false,
    }
  },
  methods: {
    
    Envoyer() {
       
      http.post('/verifier', this.form)
        .then((response) => {

          //this.$toaster.success(response.data)
          if(response.status === 200){
                this.$router.push('/verification-email-succes');
             }else if(response.status === 201 || response.status === 202 || response.status === 203 ){
                this.message = response.data;
                this.showMessage = true;
             }
        })
        .catch(function (error) {
            if (error.response) {
             
             /*if(error.response.status === 401){
                this.message = error.response.data;
                this.showMessage = true;
             }else if(error.response.status === 402){
                console.log( error.response.data);
                this.showMessage = true;
             }else if(error.response.status === 403){
                this.message = error.response.data;
                this.showMessage = true;
             }*/
                console.log(error.response.data);
            }
        })
    }

  }
}
</script>

<style scoped>

.btn-info {
    color: #fff;
    background-color: #de6401;
    border-color: #de6401;
}
.btn-info:hover {
    color: #de6401;
    background-color: #ffffff;
    border-color: #de6401;
}

</style>