<template>
  <div class="col-md-12 hotel-rs">
    <div class="row">
      <div class="col-md-7 col-12 part1">
        <p v-if="hotel && hotel.whatsapp">
          <a
            v-if="hotel.whatsapp.length > 1"
            :href="
              'https://web.whatsapp.com/send?phone=' +
              '+212' +
              hotel.whatsapp.substring(1)
            "
            target="_blank"
          >
            <img class="card-img-top" src="@/assets/images/icone-ws.png" />
            <span>{{ hotel.whatsapp }}</span>
          </a>
        </p>
        <p v-if="hotel && hotel.tel">
          <span v-if="hotel.tel.length > 1">
            <a
              :href="
                'https://web.whatsapp.com/send?phone=' +
                '+212' +
                hotel.tel.substring(1)
              "
              target="_blank"
            >
              <i class="fa fa-phone fa-xl"></i>
              <span >{{ hotel.tel }}</span>
            </a>
          </span>
        </p>
        <p v-if="hotel && hotel.website">
          <img class="card-img-top" style="word-break: break-all;" src="@/assets/images/icone-web.png" />
          <!-- <span>{{ hotel.website }} </span> -->
          <a :href="hotel.website" target="blanck">{{ $t('details_service.website') }}  </a>
        </p>
        <p v-if="hotel && hotel.facebook">
          <img class="card-img-top" src="@/assets/images/icone-fb.png" />
          <!-- <span>{{ hotel.facebook }} </span> -->
          <a :href="hotel.facebook" style="word-break: break-all;" target="blanck">{{ $t('details_service.facebook') }} </a>
        </p>
        <p v-if="hotel && hotel.instagram">
          <img class="card-img-top" style="word-break: break-all;" src="@/assets/images/icone-insta.png" />
          <!-- <span>{{ hotel.instagram }} </span> -->
          <a :href="hotel.instagram" target="blanck">{{ $t('details_service.instagram') }} </a>
        </p>
      </div>
      <div class="col-md-5  col-12 part2">
        <!-- <img v-if="this.$i18n.locale == 'Français'" v-b-modal.modal-reservation class="card-img-top" src="@/assets/images/reservez.png"> -->
        <!-- <img v-else v-b-modal.modal-reservation class="card-img-top" src="@/assets/images/reservez-arabe.png"> -->
        <input type="button" class="btn btn-reservez" v-b-modal.modal-reservation  v-if="this.$i18n.locale == 'Français'" value="Contacter" />
        <input type="button" class="btn btn-reservez" v-b-modal.modal-reservation  v-else-if="this.$i18n.locale == 'English'" value="Contact" />
        <input type="button" class="btn btn-reservez" v-b-modal.modal-reservation  v-else value="تواصل" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  props: {
    hotel: [],
  },
};
</script>
<style>
.btn-reservez {
  color: #e26e0a !important;
  border: 2px solid #e26e0a !important;
  border-radius: 60px !important;
  font-size: x-large !important;
  padding: 3px 35px 3px 35px !important;
  font-weight: 500 !important;
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.btn-reservez:hover {
  color: #355b53 !important;
}
.part2 {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.part1 p{
  display: flex;
  word-wrap: break-word;
  align-items: center;
  font-size: 18px;
}
.part1 p a{
  max-width:100%;
  word-wrap: break-word;
}
.part1 p{
  font-size: 18px;
}
.part1 p img,.part1 p i{
  margin-right: 13px;
}
</style>