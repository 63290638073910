import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";
import fr from "./locales/fr.json";
import Vue from "vue";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || "Français",
  messages: {
    en: en,
    العربية: ar,
    Français: fr,
  },
});

export default i18n;


// const messages = {
//   ar: {
//     message: {
//       hello: "{name} مرحبا",
//       welcome: "اهلا وسهلا",
//       yes_button: "نعم",
//       no_button: "لا",
//     },
//   },
//   en: {
//     message: {
//       hello: "Hello {name}",
//       welcome: "Welcome!",
//       yes_button: "Yes",
//       no_button: "No!",
//     },
//   },
//   de: {
//     message: {
//       hello: "Guten Tag, {name}!",
//       welcome: "Willkommen!",
//       yes_button: "Ja",
//       no_button: "Nein!",
//     },
//   },
// };
