import Api from "./Api";

export default {
  updateHebergement(idHeberegement, typeService, form) {
    var path = "hotels";
    if (typeService == "hotel") path = "hotels";
    else if (typeService == "riad") path = "riads";
    else if (typeService == "maison-hote") path = "maisons";
    else if (typeService == "restaurant") path = "restaurants";
    else if (typeService == "activite") path = "activites";
    else if (typeService == "guide") path = "guides-touristique";
    else if (typeService == "appart-hotels") path = "appart-hotels";



    if (typeof idHeberegement !== "undefined" && idHeberegement !== "")
      return Api().post(`${path}/${idHeberegement}`, form);
    else return Api().post(`${path}`, form);
  },

  getHeberegement(idUser, typeService) {
    var path = "hotels/findbyuserid";
    if (typeService == "hotel") path = "hotels/findbyuserid";
    else if (typeService == "riad") path = "riads/findbyuserid";
    else if (typeService == "maison-hote") path = "maisons/findbyuserid";
    else if (typeService == "restaurant") path = "restaurants/findbyuserid";
    else if (typeService == "activite") path = "activites/findbyuserid";
    else if (typeService == "appart-hotels") path = "appart-hotels/findbyuserid";

    return Api().get(`${path}/${idUser}`);
  },
};
