<template>
  <div class="container-fluid ">
    <Rubrique></Rubrique>
    <div class="container">
      <div class="row pt-5">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-12 "  id="scroll">
              <h1 class="mb-4"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                  src="@/assets/images/img-bladi.png" />{{ $t('rubrique.lieux_visiter') }}</h1>
              <div class="row search" >
                <div class="col-md-12">
                  <div class="form-group">
                    <input class="form-control form-control-sm " type="text" v-model="search"
                      style="font-family: 'Lato', cursive;padding-left: 10px" placeholder="Recherche par mots clés...">
                  </div>
                </div>
              </div>
              <div class="row actualites mt-4">
                <ListInfo :posts="lists" :slug="slug"></ListInfo>
                <!-- <div class="col-md-4 py-3" v-for="lieu of lists" v-bind:key="lieu.id">
                  <router-link v-bind:to="'/lieux-a-visiter/' + lieu.slug">
                    <div class="card" style="">
                      <img v-bind:src="BaseUrl + '/storage/' + lieu.image" class="card-img-top" />
                      <div class="card-body">
                        <h5 class="card-title" v-if="$i18n.locale === 'Français'">{{ lieu.titre ??  lieu.titre_ar }}</h5>
                        <h5 class="card-title" v-else>{{ lieu.titre_ar ??  lieu.titre}}</h5>
                        <div v-if="lieu.description_ar != null || lieu.description != null">
                          <p class="card-description" v-if="$i18n.locale === 'Français'">{{lieu.description ? (lieu.description.substring(0, 230) + ".." ): (lieu.description_ar.substring(0, 230) + ".." ) }}</p>
                          <p class="card-description" v-else>{{lieu.description_ar ? (lieu.description_ar.substring(0, 230) + ".." ): (lieu.description.substring(0, 230) + ".." ) }}</p>
                        </div>
                        <span class="float-right lire-plus">{{ $t('details_service.lire_plus') }} <b-icon-arrow-right>
                          </b-icon-arrow-right></span>
                      </div>
                    </div>
                  </router-link>
                </div> -->
                <div class="col-md-12 ">
                  <!-- d-flex justify-content-center -->
                  <div class="overflow-auto d-flex justify-content-center pt-5">
                    <b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill">
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>

</template>

<script>

import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ListInfo from "@/components/ListInfo.vue";
import  $i18n  from '../i18n.js';
export default {
  metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return {
        title: 'Lieux à visiter',
        meta: [
          {
            name: "description",
            content: 'Notre blog réunit les meilleurs lieux à visiter au Maroc, pour une expérience de voyage inoubliable.',
          },
        ],
      };
    }
    else{
      return {
        title: 'أماكن لزيارتها',
        meta: [
          {
            name: "description",
            content: 'اكتشفوا افضل الأماكن السياحية في المغرب، لتجربة سفر لا تُنسى.',
          },
        ],
      };
    }
    },
  name: "LieuxVisiterComponent",
  components: { Rubrique, Sidebar,ListInfo },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      lieux: [],
      errors: [],
      //items:this.hotels,
      perPage: 6,
      currentPage: 1,
      search: '',
      slug:'lieux-a-visiter',
      totalRows : 0,
    }
  },
  mounted() {
    http.get('/lieux')
      .then((response) => {
        //console.warn(response.data.data);
        this.lieux = response.data
        /*.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
    )*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    gettotalRows(list) {
      this.totalRows =  list.length
    }
  },
  computed: {
    lists() {
      const items = this.lieux.filter((item) => {
        return item.titre.toLowerCase().includes(this.search.toLowerCase())
          || item.titre_ar.toLowerCase().includes(this.search.toLowerCase())
          || item.seo_keywords.toLowerCase().includes(this.search.toLowerCase())
          || item.description.toLowerCase().includes(this.search.toLowerCase())
          || item.description_ar.toLowerCase().includes(this.search.toLowerCase())
      })
      this.gettotalRows(items)
      return items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        )
    }
    // ,
    // totalRows() {
    //   return this.lieux.length
    // }
  }
}

</script>

<style scoped>
#gallery {
  line-height: 0;
  -webkit-column-count: 3;
  /* split it into 5 columns */
  -webkit-column-gap: 5px;
  /* give it a 5px gap between columns */
  -moz-column-count: 3;
  -moz-column-gap: 5px;
  column-count: 3;
  column-gap: 5px;
}


#gallery img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 5px;
  /* to match column gap */
  border-radius: 15px;
}


@media (max-width: 1200px) {
  #gallery {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 1000px) {
  #gallery {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  #gallery {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}


@media (max-width: 400px) {
  #gallery {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}



#gallery img:hover {
  filter: none;
}
</style>