<template>
  <div class="container-fluid">
    <div class="container min-height mt-5">
      <div class="row espace-prof top-forme justify-content-center text-left">
        <div class="col-md-10 bg-white  shadow">
          <RubriquePro />
          <div class="row info-compte-body p-5 dir-rtl">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="email">{{ $t('forms.inscription.nom') }}</label>
                <input type="text" class="form-control" v-model="form.nom">
                <span class="text-danger" v-if="errors.nom">
                  {{ errors.nom[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="email">{{ $t('forms.inscription.prenom') }}</label>
                <input type="text" class="form-control" v-model="form.prenom">
                <span class="text-danger" v-if="errors.prenom">
                  {{ errors.prenom[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="email">{{ $t('forms.inscription.email') }}</label>
                <input type="email" class="form-control" v-model="form.email">
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="confirmez-email">{{ $t('forms.inscription.confirm_email') }}</label>
                <input type="email" v-model="form.confirmation_de_adresse_email" class="form-control">
                <span class="text-danger" v-if="errors.confirmation_de_adresse_email">
                  {{ errors.confirmation_de_adresse_email[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="password">{{ $t('forms.inscription.mdp') }}</label>
                <div class="input-group ">
                  <input v-if="showPassword" type="text" v-model="form.password" class="form-control">
                  <input v-else type="password" v-model="form.password" class="form-control">
                  <span class="input-group-append">
                    <button class="btn border-left-0 border password-eye" @click="toggleShow" type="button">
                      <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </button>
                  </span>
                </div>
                <label class="control-label color-gray" for="password">{{ $t('espace_professionnel.mdp_min') }}</label>
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="confirmez-password">{{ $t('forms.inscription.confirm_mdp') }}</label>
                <div class="input-group ">
                  <input v-if="showPassword2" type="text" class="form-control"
                    v-model="form.confirmation_de_mot_de_passe">
                  <input v-else type="password" class="form-control" v-model="form.confirmation_de_mot_de_passe">
                  <span class="input-group-append">
                    <button class="btn border-left-0 border password-eye" @click="toggleShow2" type="button">
                      <i class="fas" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i>
                    </button>
                  </span>
                </div>
                <span class="text-danger" v-if="errors.confirmation_de_mot_de_passe">
                  {{ errors.confirmation_de_mot_de_passe[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row mt-4">
                <div>
                  <h4 class="type-service">{{ $t('forms.inscription.type_service') }} </h4>
                </div>
                <b-form-group style="" label="" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-1" v-model="form.type_service" :aria-describedby="ariaDescribedby"
                    name="radio-options" size="lg">
                    <div class="col-md-12 ">
                      <b-form-radio value="hotel">{{ $t('rubrique.hotels') }}</b-form-radio>
                    </div>
                    <div class="col-md-12 ">
                      <b-form-radio value="riad">{{ $t('rubrique.riads') }}</b-form-radio>
                    </div>
                    <div class="col-md-12 ">
                      <b-form-radio value="maison-hote">{{ $t('rubrique.maisons_hotes') }}</b-form-radio>
                    </div>
                    <div class="col-md-12 ">
                      <b-form-radio value="restaurant">{{ $t('rubrique.restaurants') }}</b-form-radio>
                    </div>
                    <div class="col-md-12 ">
                      <b-form-radio value="activite">{{ $t('rubrique.activite_touristique') }}</b-form-radio>
                    </div>
                    <div class="col-md-12 ">
                      <b-form-radio value="guide">{{ $t('rubrique.guides_touristique') }}</b-form-radio>
                    </div>
                    <div class="col-md-12 ">
                      <b-form-radio value="appart-hotels">{{ $t('rubrique.apparthot') }}</b-form-radio>
                    </div>
                  </b-form-radio-group>
                </b-form-group>
                <span class="text-danger" v-if="errors.type_service">
                  {{ errors.type_service[0] }}
                </span>
              </div>
            </div>
            <div :class="[this.$i18n.locale === 'Français' ? '' : 'float-right', 'col-md-12 mt-5 validation']">
              <button type="submit" class="bg-msafar-primary btn rounded-pill pl-4 pr-5 pt-2 pb-2 text-white"
                @click="register" :disabled="loading">
                <i class="fas fa-check-circle mr-3"></i>
                <span v-if="!loading">{{ $t('forms.inscription.valider_pro') }}</span>
                <span v-else>Loading...</span>
              </button>
              <!-- <div :class="this.$i18n.locale === 'Français' ? 'float-right' : 'float-left'">
                <a @click="etapeSuivante()"
                  class="bg-msafar-primary btn text-white rounded-pill  pl-5 pr-5 pt-2 pb-2">{{
                      $t('forms.inscription.etapes')
                  }}
                  <span class="h1 color-msafar-primary flesh-profil" style="">
                    <b-icon-chevron-right></b-icon-chevron-right>
                  </span>
                </a>
              </div> -->
            </div>
            <!--
            <div class="col-md-12 mt-5 text-center connectez-vous">
              <h6>Vous avez déjà un compte ?</h6>
              <img class="image-bladi" src="@/assets/images/img-bladi.png" />
              <router-link to="/login">CONNECTEZ-VOUS</router-link><img class="image-bladi-right"
                src="@/assets/images/img-bladi.png" />
            </div>
          -->
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import RubriquePro from "@/components/EspaceProfessionnel/RubriquePro";
import User from "@/apis/User";


export default {
  name: "ProfessionnelDashbord",
  components: { RubriquePro },
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      query: '',
      form: {
        loading: false,
        name: "",
        nom: "",
        prenom: "",
        email: "",
        type_service: "",
        subject: null,
        password: "",
        confirmation_de_adresse_email: "",
        confirmation_de_mot_de_passe: ""
      },
      errors: [],
      // options: [
      //   { text: 'Hôtels', value: 'hotel' },
      //   { text: 'Riads', value: 'riad' },
      //   { text: 'Maisons d\'hôtes', value: 'maison-hote' },
      //   { text: 'Restaurants', value: 'restaurant' },
      //   { text: 'Activités touristiques', value: 'activite' },
      //   { text: 'Guides touristiques', value: 'guide' }
      // ],
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    etapeSuivante() {
      this.$toaster.info('Merci de valider le formulaire pour passer à l\'étape suivante');
    },
    register() {
      this.loading = true;
      /* form: {
               email: "",
               password: "",
               device_name: "browser",
           },*/

      User.registerPro(this.form)
        .then((response) => {
          this.$user.value = response.data
          this.$typeService.value = this.form.type_service
          this.$router.push({ name: "Login" });
          this.$toaster.success('Votre compte a bien été créé. Connectez-vous maintenant pour ajouter votre ' + this.$typeService.value);
          // this.$router.push({ name: "MonCompte" });

          // var loginForm = { email: this.form.email, password: this.form.password };
          // User.login(loginForm)
          //   .then((response) => {
          //     this.$toaster.success('Votre espace professionnel a été bien créé.')
          //     this.$root.$emit("login", true);
          //     localStorage.setItem('token', response.data.token);
          //     localStorage.setItem('id', response.data.user.id);
          //     localStorage.setItem('role_id', response.data.user.role_id);
          //     this.$router.push({ name: "InformationCompteProfil" });
          //     this.$router.go();
          //   })
          //   .catch(error => {
          //     if (error.response.status === 422) {
          //       this.errors = error.response.data.errors;
          //     } else if (error.response.status === 401) {
          //       this.message = error.response.data;
          //       let message = error.response.data.message;
          //       this.$toaster.error(message)
          //     }
          //   });


        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        }).finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
<style scoped>
/* .validation {
  direction: ltr;
  text-align: justify;
} */

.password-eye {
  position: absolute !important;
  right: 0px;
  border: none !important;
  z-index: 10 !important;
}
</style>