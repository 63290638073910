<template>

  <div class="container contact">
    <div class="row mb-5 dir-rtl">
      <div class="col-md-12 mt-5 mb-5">
        <h1 class=""><img src="@/assets/images/img-bladi.png" style="margin-top: -15px;  width: 55px;" />
          {{$t('navbar.contact')}}
        </h1>
      </div>
      <div class="col-md-6 part-1">
        <h1 class="title">
          {{$t('contact_page.demande')}} <br> {{$t('contact_page.quelque')}}
        </h1>
        <div class="info mt-4">
          <div class="email">
            <div class="icon"><i class="fa fa-envelope"></i></div>
            <div class="detail"><span>Contact@msafar.ma</span></div>
          </div>
          <div class="phone">
            <div class="icon"><i class="fa fa-phone"></i></div>
            <div class="detail">
              <span>+212(0) 636 30 30 03</span>
              <span>+212(0) 522 22 85 27</span>
              <span>+212(0) 522 22 85 26</span>
            </div>
          </div>
          <div class="adresse">
            <div class="icon"> <i class="fa fa-map-marker-alt"></i></div>
            <div class="detail"><span>{{$t('contact_page.adresse')}}</span></div>
          </div>
        </div>
      </div>
      <div class="col-md-6 form">
        <div class="row mb-3">
          <div class="form-group col-6 nom-group">
            <label for="nom">{{$t('contact_page.nom')}}</label>
            <input type="text" name="" id="nom" v-model="form.username" class="form-control" placeholder=""
              aria-describedby="helpId">
          </div>
          <div class="form-group col-6 email-group">
            <label for="email">{{$t('contact_page.email')}}</label>
            <input type="text" name="" id="email" class="form-control" v-model="form.email" placeholder=""
              aria-describedby="helpId">
          </div>
        </div>
        <div class="form-group col-12 message-group p-0">
          <label for="message">{{$t('contact_page.message')}}</label>
          <textarea class="form-control" name="" id="message" v-model="form.message" rows="3"></textarea>
        </div>
        <button type="submit" class="btn mt-4 border-pill btn-envoyer float-right" @click="Envoyer">{{$t('details_service.envoyer')}}</button>
      </div>
    </div>
  </div>
</template>

<script>

import http from "./../http-common";

export default {
  metaInfo() {
      return {
        title: 'Msafar.ma | Page contact',
        meta: [
          {
            name: "description",
            content: "Avec Msafar.ma restez en contact avec les coups de cœur de vos voyages au Maroc.",
          },
        ],
      };
    },
  name: "ContactComponent",
  components: {},
  data() {
    return {
      form: {
        username: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    Envoyer() {

      http.post('/contact', this.form)
        .then((response) => {
          console.log(response);
          this.$toaster.success('Votre message a bien été envoyé')
        })
        .catch(e => {
          this.errors.push(e)
        })
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .about-text {
    margin-left: 60px;
    width: 299px;
  }

  .bg-about {
    background: url("@/assets/images/bg-about-mobile.png") no-repeat;
    background-size: 100% 100%;
    height: 1097px;
    width: 423px;
    margin: auto;

  }
}

/* envolpe color : #d98335 */
/* phone color : #2ba0cf */
/*  mapmarker color : #a94241 */

/* btn envoyer color : #cb6d11 */
/* btn envoyer hover color : #038dc5 */




.contact .title {
  color: #018ec6;
  font-size: 30px;
}

.contact .info span {
  margin-left: 20px;
  height: 100%;
  display: block;
}

.contact .info .phone,
.contact .info .email,
.contact .info .adresse {
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
}

.contact .info .icon i {
  font-size: 20px;
}

/* envolpe color : #d98335 */
/* phone color : #2ba0cf */
/*  mapmarker color : #a94241 */

.contact .info .phone .icon i {
  color: #f47f01;
}

.contact .info .email .icon i {
  color: #2ba0cf;
}

.contact .info .adresse .icon i {
  color: #a94241;
}

.contact .info .icon {
  float: left;
}

.contact .info .detail {
  float: left;
  max-width: 290px;
}

.contact .info .phone,
.contact .info .email,
.contact .info .adresse {
  margin-top: 20px;
}

.contact .form input,
.contact .form textarea {
  border-radius: 25px;
  background-color: #eeeeee;
}

.contact .form .btn-envoyer {
  border-radius: 25px;
  padding: 5px 30px;
  background-color: #cb6d11;
  color: white;
}

.contact .form .btn-envoyer:hover {
  background-color: #038dc5;
}


/* top half circle nom color : #1f584e */
/* top half circle email color : #b4051f  */

/* top half circle messsage color : #f47f01 */
/* top half circle messsage color : #018ec6 */
.contact .form .nom-group::before {
  background-color: #1f584e;
  right: 50px;
  top: 7px;
}

.contact .form .email-group::before {
  background-color: #b4051f;
  right: 50px;
  top: 7px;
}

.contact .form .message-group::before {
  background-color: #f47f01;
  right: 130px;
  top: 7px;
}

.contact .form .message-group::after {
  background-color: #018ec6;
}

.contact .form .nom-group::before,
.contact .form .email-group::before,
.contact .form .message-group::before {
  content: '';
  width: 50px;
  height: 25px;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  position: absolute;

}

.contact .form .message-group::after {
  content: '';
  width: 60px;
  height: 30px;
  border-bottom-left-radius: 110px;
  border-bottom-right-radius: 110px;
  position: absolute;
  left: 150px;
  bottom: -30px;
}

@media screen and (max-width: 980px) {
  .contact .part-1 {
    margin-bottom: 100px;
    padding-left: 40px;
  }
}
</style>