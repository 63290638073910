<template>
  <div class="AddPartage container">
    <div class="col-md-12 d-flex justify-content-end">
      <a
        style="color: #0056b3; display: flex; align-items: center; font-size: 42px; margin-bottom: 20px; cursor: pointer;">
        <b-icon style="margin-left: 20px; margin-right: 20px;" class="float-right" v-b-modal.modal-add
          icon="plus-square" font-scale="1" variant="grey" v-b-popover.hover.top="$t('rubrique.ajt-part')">
        </b-icon>
      </a>
    </div>
    <b-modal ref="modal-add" id="modal-add" size="lg" centered hide-footer>
      <div>
        <div class="modal-body espace-voyageur dir-rtl" style="margin-top: -33px;">
          <div class="row">
            <div class="col-md-12 mb-md-0 mb-5">
              <div class="container m-4">
                <h3>
                  <img style="margin-top: -15px; margin-right: 15px; width: 55px;"
                    src="@/assets/images/img-bladi.png" />
                  {{ $t('pop_up.form_partage.titre') }}
                </h3>
                <div class="row mt-4">
                  <div class="col-md-11">
                    <div class="form-group">
                      <label for="name">{{ $t('pop_up.form_partage.votre_titre') }}</label>
                      <input type="text" id="name" v-model="form.titre" class="form-control">
                      <span class="text-danger" v-if="errors.titre">{{ errors.titre[0] }}</span>
                    </div>
                  </div>
                  <div class="col-md-11 mt-3">
                    <div class="form-group">
                      <label for="message">{{ $t('pop_up.form_partage.votre_message') }}</label>
                      <textarea id="message" rows="6" cols="10" class="form-control md-textarea"
                        v-model="form.description"></textarea>
                      <span class="text-danger" v-if="errors.description">{{ errors.description[0] }}</span>
                    </div>
                  </div>
                  <div class="col-md-11 mt-3">
                    <div class="form-group">
                      <label for="ville">{{ $t('recherche.villes') }}</label>
                      <select id="ville" class="form-control" v-model="form.ville_id">
                        <option value="" disabled>{{ $t("recherche.villes") }}</option>
                        <option v-for="ville in villes" :key="ville.id" :value="ville.id">
                          {{ $i18n.locale === "Français" || $i18n.locale === "English" ? ville.ville : ville.ville_ar }}
                        </option>
                      </select>
                      <span class="text-danger" v-if="errors.ville_id">{{ errors.ville_id[0] }}</span>
                    </div>
                  </div>
                  <div class="col-md-2 mt-3">
                    <div class="form-group image-upload">
                      <label for="file-input1">{{ $t('pop_up.form_partage.votre_image') }}</label>
                      <label for="file-input1">
                        <img src="@/assets/images/partage-icon.png" height="100" width="100" />
                      </label>
                      <input id="file-input1" type="file" @change="onFileChange" class="form-control" />
                      <span class="text-danger" v-if="errors.image">{{ errors.image[0] }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-5">
                    <img v-if="preview" :src="preview" width="100px" height="100px" />
                  </div>
                </div>
                <div class="col-md-3 text-center mt-4">
                  <a class="btn btn-success" @click="addPartage">{{ $t('pop_up.form_partage.ajouter') }}</a>
                </div>
                <div class="status"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import User from "@/apis/User";
import http from "@/http-common";

export default {
  name: 'AddPartage',
  data() {
    return {
      form: {
        user_id: '',
        titre: '',
        description: '',
        ville_id: '',
        image: '',
      },
      errors: {},
      villes: [],
      preview: null,
    };
  },
  methods: {
    onFileChange(event) {
      this.form.image = event.target.files[0];
      this.preview = URL.createObjectURL(this.form.image);
    },
    addPartage() {
      const addData = new FormData();
      addData.append('user_id', localStorage.getItem('id'));
      addData.append('titre', this.form.titre);
      addData.append('description', this.form.description);
      addData.append('ville_id', this.form.ville_id);
      addData.append('image', this.form.image);

      User.ajtPartage(addData)
        .then(() => {
          this.$toaster.success('Votre post a été ajouté.');
          this.$refs['modal-add'].hide();
          this.resetForm();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    resetForm() {
      this.form = {
        user_id: '',
        titre: '',
        description: '',
        ville_id: '',
        image: '',
      };
      this.preview = null;
    }
  },
  mounted() {
    http.get("/villes")
      .then(response => {
        this.villes = response.data;
      })
      .catch(e => {
        console.error('Error fetching villes:', e);
        this.errors.push(e);
      });
  },
};
</script>

<style scoped>
.image-upload>input {
  display: none;
}

.margin-auto {
  margin: auto;
}
</style>
