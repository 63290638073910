<template>
    <div class="col-md-12">
        <div class="col-md-12 line mb-5"></div>
        <div class="row espace-voyageur top-forme justify-content-center">
            <div class="col-md-10 bg-white dir-rtl">
                <!-- <form action=""> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="prenom">{{ $t('forms.inscription.mdp_actuel') }}</label>
                            <div class="input-group ">
                                <input v-if="showPassword" type="text" v-model="user.mot_de_passe_actuel"
                                    class="form-control">
                                <input v-else type="password" v-model="user.mot_de_passe_actuel" class="form-control">
                                <span class="input-group-append">
                                    <button class="btn border-left-0 border password-eye" @click="toggleShow"
                                        type="button">
                                        <i class="fas"
                                            :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                    </button>
                                </span>
                            </div>
                            <span class="text-danger" v-if="errors.mot_de_passe_actuel">
                                {{ errors.mot_de_passe_actuel[0] }}
                            </span> <br v-if="messageerrors.length">
                            <span class="text-danger" v-if="messageerrors.length">
                                {{ messageerrors }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="username">{{ $t('forms.inscription.nv_mdp') }}</label>
                            <div class="input-group ">
                                <input v-if="showPassword2" type="text" v-model="user.nouveau_mot_de_passe" id="username"
                                    class="form-control">
                                <input v-else type="password" v-model="user.nouveau_mot_de_passe" class="form-control">
                                <span class="input-group-append">
                                    <button class="btn border-left-0 border password-eye" @click="toggleShow2"
                                        type="button">
                                        <i class="fas"
                                            :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i>
                                    </button>
                                </span>
                            </div>
                            <span class="text-danger" v-if="errors.nouveau_mot_de_passe">
                                {{ errors.nouveau_mot_de_passe[0] }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label" for="email">{{ $t('forms.inscription.confirm_nv_mdp')
                                }}</label>
                            <div class="input-group ">
                                <input v-if="showPassword3" type="text" v-model="user.confirmation_de_mot_de_passe" id="username"
                                    class="form-control">
                                <input v-else type="password" v-model="user.confirmation_de_mot_de_passe" class="form-control">
                                <span class="input-group-append">
                                    <button class="btn border-left-0 border password-eye" @click="toggleShow3"
                                        type="button">
                                        <i class="fas"
                                            :class="{ 'fa-eye-slash': showPassword3, 'fa-eye': !showPassword3 }"></i>
                                    </button>
                                </span>
                            </div>
                            <span class="text-danger" v-if="errors.confirmation_de_mot_de_passe">
                                {{ errors.confirmation_de_mot_de_passe[0] }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-5">
                        <button type="submit" class="btn btn-modifer" @click.prevent="modifierMdp">{{
                                $t('forms.inscription.modifier') }}</button>
                    </div>
                </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
</template>

<script>

import User from "@/apis/User";

export default {
    name: "ChangerMotDePasse",
    components: {

    },
    data() {
        return {
            showPassword: false,
            showPassword2: false,
            showPassword3: false,
            user: {
                mot_de_passe_actuel: "",
                nouveau_mot_de_passe: "",
                confirmation_de_mot_de_passe: "",
            },
            errors: [],
            messageerrors: [],
            token: localStorage.getItem('token'),
            isLoggedIn: false
        }
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShow2() {
            this.showPassword2 = !this.showPassword2;
        },
        toggleShow3() {
            this.showPassword3 = !this.showPassword3;
        },
        getUser() {
            User.auth().then(response => {
                this.user = response.data;
            }).catch((errors) => {
                console.log(errors)
            });
        },
        modifierMdp() {
            User.updatePassword(this.user)
                .then((response) => {
                    //this.$router.push({ name: "CompteInfo" });
                    console.log(response);
                    if (response.status === 200) {
                        this.$toaster.success('Votre mot de passe a été mis à jour.')
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;

                    }
                    if (error.response.status === 401) {
                        this.messageerrors = error.response.data.message;
                    }
                });
        }
    },
    created() {
        this.getUser()
        this.isCreated = true
    },

}
</script>
<style scoped>
.btn-modifer {
    background-color: #1b5046 !important;
    background: #1b5046 !important;
    font-family: GillSansMT !important;
    font-size: 20px !important;
    color: white !important;
    padding: 0px 40px !important;
    border-radius: 25px !important;
}

.line {
    border-bottom: 5px solid #1f5b4e;
}

.espace-voyageur .form-control {
    border: 2px solid #1f5b4e;
}

.control-label {
    color: #1f5b4e;
}

input {
    border-radius: 25px !important;
}

.password-eye {
    position: absolute !important;
    right: 0px;
    border: none !important;
    z-index: 10 !important;
}
</style>