<template>
    <div>
        <h5 class="mb-3 mt-5" style="color: rgb(158 120 50);font-size: 26px">{{ $t('details_service.share_buttons') }}</h5>
        <div class="d-flex  align-items-center">
            <div class="social-share">
                <a @click="shareOnFacebook" class="mx-2 SocialMedia">
                <!-- <i class="fab fa-facebook fa-2x"></i> -->
                 <img src="@/assets/images/rs/icone-fb.png" alt="" srcset="">
            </a>
            <!-- <a @click="shareOnInstagram" class="mx-2">
                <i class="fab fa-instagram fa-2x"></i>
            </a> -->
            <a @click="shareOnTwitter" class="mx-2 SocialMedia">
                <img src="@/assets/images/rs/icone-x.png" alt="" srcset="">
            </a>
            <!-- <a @click="shareOnTikTok" class="mx-2">
                <i class="fab fa-tiktok fa-2x"></i>
            </a> -->
            <a @click="shareOnLinkedIn" class="mx-2 SocialMedia">
                <img src="@/assets/images/rs/icone-in.png" alt="" srcset="">
                <!-- <i class="fab fa-linkedin fa-2x"></i> -->
            </a>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialShare',
    methods: {
        shareOnFacebook() {
            const postUrl = window.location.href;
            const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
            window.open(facebookShareUrl, '_blank');
        },
        shareOnInstagram() {
            const postUrl = window.location.href;
            alert('To share this post on Instagram, copy the link and share it manually: ' + postUrl);
        },
        shareOnTwitter() {
            const postUrl = window.location.href;
            const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(postUrl)}`;
            window.open(twitterShareUrl, '_blank');
        },
        shareOnTikTok() {
            const postUrl = window.location.href;
            alert('To share this post on TikTok, copy the link and share it manually: ' + postUrl);
        },
        shareOnLinkedIn() {
            const postUrl = window.location.href;
            const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(postUrl)}`;
            window.open(linkedInShareUrl, '_blank');
        }
    }
}
</script>

<style scoped>
.social-share i {
    cursor: pointer;
    color: #333;
}

.social-share i:hover {
    color: #007bff;
}
.social-share{
    background-color: #fed5a7;
    align-items: center;
    padding: 5px 17px;
    border-radius: 33px;
}
.social-share img{
    max-height: 48px;
}
.SocialMedia{
    cursor: pointer;
}
</style>