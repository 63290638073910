<template>
  <div class="slider-container" v-if="slides.length > 0">
    <!-- Slides Wrapper -->
    <div class="slides-wrapper"
      :style="{ transform: `translateX(-${currentSlide * 100}%)`, direction: isRtl ? 'rtl' : 'ltr' }">
      <div v-for="(slide, index) in slides" :key="index" class="slide" :class="{ active: currentSlide === index }">
        <!-- Slide content -->
        <div class="row d-flex align-items-center cont-slide"
          :style="{ backgroundImage: `url(${BaseUrl}/storage/images/slides/${slide.background}.jpg)` }">
          <div class="col-md-6 col-6 d-flex" :class="{ 'justify-content-start': isRtl, 'justify-content-end': !isRtl }">
            <img :src="`${BaseUrl}/storage/${slide.img_path}`" class="img-slide" :alt="slide.title_fr" />
          </div>
          <div class="col-md-5 col-6 d-flex flex-column cont-text">
            <p class="p-slide" v-if="$i18n.locale === 'Français'">{{ slide.title_fr }}</p>
            <p class="p-slide" v-else-if="$i18n.locale === 'English'">{{ slide.title_en }}</p>
            <p class="p-slide" v-else>{{ slide.title_ar }}</p>
            <router-link v-if="$i18n.locale === 'Français' || $i18n.locale === 'English'" :to="slide.link"
              class="btn-slide">{{ $t('home.voirplus') }} &#10230;</router-link>
            <router-link v-else :to="slide.link" class="btn-slide">{{ $t('home.voirplus') }} &#8592;</router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Controls -->
    <button @click="prevSlide" class="slider-btn prev-btn">‹</button>
    <button @click="nextSlide" class="slider-btn next-btn">›</button>

    <!-- Dots Navigation -->
    <div class="dots">
      <span v-for="(slide, index) in slides" :key="index" @click="goToSlide(index)"
        :class="{ active: currentSlide === index }"></span>
    </div>
  </div>
</template>

<script>
import http from '@/http-common';

export default {
  name: 'SlideHome',
  data() {
    return {
      slides: [],
      currentSlide: 0,
      slideInterval: null,
      BaseUrl: process.env.VUE_APP_API_URL,
      isRtl: false, // Flag for RTL support
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    startAutoSlide() {
      this.slideInterval = setInterval(this.nextSlide, 3000); // Auto-slide every 3 seconds
    },
    stopAutoSlide() {
      clearInterval(this.slideInterval);
    }
  },
  async mounted() {
    try {
      const response = await http.get('/slides');
      this.slides = response.data; // Ensure this is an array of slide objects
      this.isRtl = document.documentElement.getAttribute('dir') === 'rtl'; // Check for RTL direction
      console.log(this.slides);
      // this.startAutoSlide(); // Start the auto-slideshow after loading the data
    } catch (error) {
      console.error('Error fetching slides:', error);
    }
  },
  beforeDestroy() {
    this.stopAutoSlide(); // Clean up the interval on component destruction
  }
};
</script>

<style scoped>
/* Slider Container */
.slider-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
}

/* Slides Wrapper */
.slides-wrapper {
  display: flex;
  transition: transform 0.5s ease-out;
}

/* Slide */
.slide {
  min-width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}

/* Slide Navigation Controls */
.slider-btn {
  position: absolute;
  top: 50%;
  background-color: unset;
  color: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 35px;
  transform: translateY(-50%);
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

/* Dots Navigation */
.dots {
  text-align: center;
  margin-top: 20px;
}

.dots span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
}

.dots span.active {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Slide Content */
.cont-slide {
  height: 336px;
  /* background-image: url('@/assets/images/img-slide.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.img-slide {
  width: 80%;
  max-height: 300px !important;
  object-fit: cover !important;
  border-radius: 12px;
}

.p-slide {
  font-size: 21px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-slide {
  color: white;
  background-color: #b11918;
  outline: none;
  border: none;
  font-size: 20px;
  

  padding: 7px 22px;
  border-radius: 20px;
}

.rtl .prev-btn {
  left: 10px;
  transform: rotate(180deg);
}

.rtl .next-btn {
  right: 10px;
  transform: rotate(180deg);
}

.cont-text {
  align-items: start;
}

.rtl .cont-slide {
  direction: rtl;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .p-slide {
    width: 80%;
    font-size: 12px;
  }

  .btn-slide {
    font-size: 18px;
    padding: 0 5px;
  }

  .img-slide {
    max-width: 90%;
  }

  .cont-slide {
    height: 165px;
  }

  .img-slide {
    width: 70%;
    max-height: 200px !important;
    object-fit: cover !important;
  }
}
</style>
