<template>
    <div class="container">
      <div id="gallery">
        <div v-for="partage of lists.slice(0,8)" :key="partage.id">
          <router-link
            v-bind:to="
              '/partages/' +
              ($i18n.locale === 'Français' || $i18n.locale === 'English'
                ? partage.slug
                : partage.slug_ar || partage.slug)
            "
          >
            <img
              style="width: 100%"
              v-bind:src="BaseUrl + '/storage/' + partage.image"
            />
          </router-link>
        </div>
    </div>
    <router-link v-if="$i18n.locale === 'Français' || $i18n.locale === 'English'" style="font-size: 20px;float: right;color: #018ec6;" to="/partages">{{ $t('home.voirplus') }} &#10230;</router-link>
    <router-link v-else style="font-size: 20px;float: left;color: #018ec6;" to="/partages">{{ $t('home.voirplus') }} &#8592;</router-link>
    </div>
</template>
<script>
import http from "./../http-common";
export default {
    name:"ListPartage",
    props:{
       
    },
    data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      post : [],
      errors : [],
    }
    },
    methods: {
        partagelist() {
            http.get('/partages')
            .then((response) => {
                this.post = response.data
            })
            .catch(e => {
                this.errors.push(e)
            })
        }
    },
    created() {
        this.partagelist();
    },
    computed: {
        lists() {
            return this.post
        }
    }
}
</script>
<style>


#gallery {
  line-height: 0;
  -webkit-column-count: 3;
  /* split it into 5 columns */
  -webkit-column-gap: 5px;
  /* give it a 5px gap between columns */
  -moz-column-count: 3;
  -moz-column-gap: 5px;
  column-count: 3;
  column-gap: 5px;
  margin-bottom: 20px;
}

#gallery img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 5px;
  /* to match column gap */
  border-radius: 15px;
}

@media (max-width: 1200px) {
  #gallery {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 1000px) {
  #gallery {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  #gallery {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  #gallery {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

#gallery img:hover {
  filter: none;
}
</style>
