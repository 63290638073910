<template>
  <div class="container-fluid">

    <Rubrique></Rubrique>

    <div class="container min-height">
      <div class="row">

        <div class="col-md-12">
          <div class="row mt-5 justify-content-center">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-12">
                  <h1 class="" ><img style="margin-top: -15px;margin-right: 15px;width: 55px;" src="@/assets/images/img-bladi.png" />Temoignages</h1>
                </div>
              </div>

              <div class="row search">
                <div class="col-md-12">
                  <div class="form-group">
                    <input class="form-control form-control-sm " type="text"  style="font-family: 'Lato', cursive;padding-left: 10px" placeholder="Recherche par mots clés...">
                  </div>
                </div>
              </div>


              <div class="row actualites mt-4">

                <div class="col-md-12 py-3" v-for="post of lists" v-bind:key="post.id">
                  <router-link to="#">
                    <div class="card pt-3" style="">
                      <!-- <img src="http://placehold.jp/300x200.png" class="card-img-top" alt="...">-->

                      <div class="card-body ">

                        <img :src="'https://ui-avatars.com/api/?name='+post.user.name" class="mr-3" style="float: left;border-radius: 60px;height: 50px"/>
                        <img :src="'https://i.pravatar.cc/'+(randomInteger(50,100))" class="mr-3" style="display:none;float: left;border-radius: 60px;height: 50px"/> <h5 class="card-title">{{post.user.name}}</h5>
                        <p class="card-description">{{post.description.substring(0,230)+".."}}</p>
                        <span class="float-right lire-plus"> <b-icon-emoji-laughing></b-icon-emoji-laughing> <b-icon-emoji-heart-eyes></b-icon-emoji-heart-eyes> </span>
                      </div>
                    </div>
                  </router-link>
                </div>


                <div class="col-md-12 ">
                  <!-- d-flex justify-content-center -->
                  <div class="overflow-auto d-flex justify-content-center pt-5">
                    <b-pagination
                        pills
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                    ></b-pagination>

                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>


import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";




export default {
  name: "TemoignagesComponent",
  components: { Rubrique },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      posts: [],
      errors: [],
      //items:this.hotels,
      perPage: 6,
      currentPage: 1
    }
  },
  mounted(){
    http.get('/temoignages')
        .then((response)=> {
          //console.warn(response.data.data);
          this.posts = response.data
          /*.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      )*/
        })
        .catch(e => {
          this.errors.push(e)
        })
  },
  methods: {
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  computed: {
    lists () {
      const items = this.posts
      // Return just page of items needed
      return items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      )
    },
    totalRows () {
      return this.posts.length
    }
  }
}

</script>

<style scoped>

</style>