<template>
<!-- like dislike button https://www.positronx.io/how-to-build-laravel-vue-js-like-dislike-system/ -->
  <div class="container min-height ">
    <Rubrique></Rubrique>

    <div class="row justify-content-center mt-5">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <h1 class="title-arabe" style="display: inline-block"><img
                style="margin-top: -15px;margin-right: 15px;width: 55px;" src="@/assets/images/img-bladi.png" />{{
                    $t('rubrique.lieux_visiter')
                }}</h1>
            <router-link to="/lieux-a-visiter/">
              <b-icon-arrow-right v-if="this.$i18n.locale == 'العربية'" class="arrow-left float-left color-primary">
              </b-icon-arrow-right>
              <b-icon-arrow-left v-else class="arrow-left float-right color-primary"></b-icon-arrow-left>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row top-forme justify-content-center ">
      <div class="col-md-10 bg-white p-5">

        <carousel v-if="images.length > 0" :starting-image="2" :images="images" :hotel_id="lieu.id"
          @AddFavorite="ajtFavorite($event)" @DelteFavorite="supFavorite(lieu.id)" :auto-slide-interval="500000000"
          :show-progress-bar="false" :isVisible="true">
        </carousel>

        <!--
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" >
              <img class="bg-img" style="" v-bind:src="BaseUrl + '/storage/' + lieu.image" />
            </div>
             <div class="carousel-item " v-for="image in lieu.images" :key="image.id">
              <img class="bg-img" style="" v-bind:src="BaseUrl + '/storage/' + image.image" />
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </button>
        </div>


        <div class="mb-4 text-center">
          <img class="max-width-100" style="" v-bind:src="BaseUrl + '/storage/' + lieu.image" />
        </div> -->
        <h1 class="mt-4" v-if="$i18n.locale === 'Français' ">{{ lieu.titre ??  lieu.titre_ar }}</h1>
        <h1 v-else-if="$i18n.locale === 'English'">{{ lieu.titre_en ??  lieu.titre }}</h1>
        <h1 class="mt-4" v-else>{{ lieu.titre_ar ??  lieu.titre}}</h1>
        <p  v-if="$i18n.locale === 'Français'">{{lieu.description ?? lieu.description_ar }}</p>
        <p  v-else-if="$i18n.locale === 'English'">{{lieu.description_en ?? lieu.description }}</p>
        <p  v-else>{{lieu.description_ar ?? lieu.description }}</p>
        <!-- <b-button variant="outline-info" @click="myFilter" :class="{ active: isActive }" class="mb-4">
          <b-icon icon="suit-heart"></b-icon> J'aime {{ count }}
        </b-button> -->

        <CommentairePartage :commentaires="commentaires" :errors="errors" :lieuxId="lieu.id"
          @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)" @DeleteComment="supComment($event)" />
          <div v-if="lieu.map != null">
            <div class="title-map"><img src="../../public/icon-map.png" alt=""><span
                    class="title">{{ $t('details_service.localisation') }}</span> </div>
            <div class="cont-map">
              <div class="col-md-12 mt-3 map">
                  <div v-html="lieu.map"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import CommentairePartage from "./EspaceProfessionnel/CommentairePartage.vue";
import Carousel from "@/components/Outils/Carousel.vue"
import seo from "../seo-sevices.js";
import  $i18n  from '../i18n.js';
export default {
  metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.lieu,this.images);
    }
    else{
      return seo.metaInfo_ar(this.lieu,this.images);
    }
  },
  name: "LieuxVisiterView",
  components: { Rubrique, CommentairePartage, Carousel },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      lieu: [],
      items: [],
      images: [],
      errors: [],
      user_id: localStorage.getItem('id'),
      comment: [],
      count: 5,
      commentaires: [],
      isActive: false,
      //items:this.hotels,
      perPage: 2,
      currentPage: 1
    }
  },

  mounted() {
    const id = this.id;
    //console.log(id);
    http.get(`/lieux/${id}`)
      .then((response) => {
        console.warn(response.data.data);
        this.lieu = response.data
        this.commentaires = response.data.commentaires
        let userId = this.user_id;
        this.commentaires = this.commentaires.map(function (field) {
          return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
        })
        this.images = this.lieu.images.map(obj => obj.image);
        if (this.lieu.image && this.lieu.image !== '') {
          this.images.unshift(this.lieu.image);
        }
        // this.lieu.images.push({ image :this.lieu.image});
        console.log(response.data.commentaires)

        /*.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
        )*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    myFilter: function () {
      if (!this.isActive) {
        this.isActive = !this.isActive;
        this.count += 1;
      } else {
        this.isActive = !this.isActive;
        this.count -= 1;
      }
    },
    ajtComment(event) {
      http.post('/commentpartage', event)
        .then(() => {
          this.getCommentaire();
          this.$toaster.success('Votre commentaire a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    mdfComment(event) {
      http.post(`/commentpartage/${event.id}`, event).then(() => {
        this.getCommentaire();
        this.$toaster.error(this.$t('toaster.commentaires.modifier'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http.delete(`/commentpartage/${event.id}`).then(() => {
        this.getCommentaire();
        this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },
    getCommentaire() {
      const id = this.id;
      http.get(`/lieux/${id}`)
        .then((response) => {

          this.commentaires = response.data.commentaires
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    }
  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
/*
export default {
data () {
return {
currentPage: 1,
perPage: 5,
}
},
computed: {
lists () {
const items = this.$store.getters.loadedLists
// Return just page of items needed
return items.slice(
(this.currentPage - 1) * this.perPage,
this.currentPage * this.perPage
)
},
totalRows () {
return this.$store.getters.loadedLists.length
}
}
}
*/
</script>

<style scoped>
.bg-img {
  position: unset !important;
  width: 100% !important;
  border-radius: 10px;
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important;
  object-fit: contain !important;

}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23585858%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27><path d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/></svg>");

}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23585858%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e");

}

.cont-map {
    margin-top: 10px;
    margin-bottom: 60px;
    border: 2px solid #83511a;
    padding: 20px;
    padding-bottom: 26px;
}
.map{
    display: flex;
    justify-content: center;
}
.map div{
    width: 100% !important;
}

.title-map img {
    max-height: 50px;
}

.rtl .title-map {
    direction: rtl;
    text-align: right;
}
</style>