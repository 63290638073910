<template>
  <div class="container-fluid">
    <Rubrique></Rubrique>
    <div class="container min-height dir-rtl">
      <div class="row mt-5">
        <div class="col-md-12 mt-5">
          <h1 class=""><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
              src="@/assets/images/img-bladi.png" /> {{ $t('espaces.espace_voyageur') }} </h1>
        </div>
      </div>
      <div class="row espace-voyageur top-forme justify-content-center">

        <div class="col-md-10 bg-white p-5 shadow">
          <h1 class=""></h1>

          <!-- <form action=""> -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label" for="nom">{{ $t('forms.inscription.nom') }}</label>
                <input type="text" class="form-control" v-model="form.nom">
                <span class="text-danger" v-if="errors.nom">
                  {{ errors.nom[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label" for="prenom">{{ $t('forms.inscription.prenom') }}</label>
                <input type="text" class="form-control" v-model="form.prenom">
                <span class="text-danger" v-if="errors.prenom">
                  {{ errors.prenom[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="pseudo">{{ $t('forms.inscription.pseudo') }}</label>
                <input type="text" class="form-control" v-model="form.pseudo">
                <span class="text-danger" v-if="errors.pseudo">
                  {{ errors.pseudo[0] }}
                </span>
              </div>
            </div>
            <!-- <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="username">{{ $t('forms.inscription.nom_utilisateur') }}</label>
                <input type="text" class="form-control" v-model="form.name">
                <span class="text-danger" v-if="errors.name">
                  {{ errors.name[0] }}
                </span>
              </div>
            </div> -->
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="email">{{ $t('forms.inscription.email') }}</label>
                <input type="email" class="form-control" v-model="form.email">
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="confirmez-email">{{ $t('forms.inscription.confirm_email') }}</label>
                <input type="email" v-model="form.confirmation_de_adresse_email" class="form-control">
                <span class="text-danger" v-if="errors.confirmation_de_adresse_email">
                  {{ errors.confirmation_de_adresse_email[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="password">{{ $t('forms.inscription.mdp') }}</label>
                <div class="input-group ">
                  <input v-if="showPassword" type="text" v-model="form.password" class="form-control">
                  <input v-else type="password" v-model="form.password" class="form-control">
                  <span class="input-group-append">
                    <button class="btn border-left-0 border password-eye" @click="toggleShow" type="button">
                      <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </button>
                  </span>
                </div>
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password[0] }}
                </span>
                <label for="password" class="ml-2 color-gray"> {{ $t('forms.inscription.length') }}</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="confirmez-password">{{ $t('forms.inscription.confirm_mdp') }}</label>
                <div class="input-group ">
                  <input v-if="showPassword2" type="text" class="form-control"
                    v-model="form.confirmation_de_mot_de_passe">
                  <input v-else type="password" class="form-control" v-model="form.confirmation_de_mot_de_passe">
                  <span class="input-group-append">
                    <button class="btn border-left-0 border password-eye" @click="toggleShow2" type="button">
                      <i class="fas" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i>
                    </button>
                  </span>
                </div>
                <span class="text-danger" v-if="errors.confirmation_de_mot_de_passe">
                  {{ errors.confirmation_de_mot_de_passe[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input type="checkbox" v-model="form.regles" id="accepter">
                <label class="control-label ml-3" for="accepter">{{ $t('forms.inscription.regles') }} </label>
                <br>
                <span class="text-danger" v-if="errors.regles">
                  {{ errors.regles[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn-valider" @click.prevent="register">{{ $t('forms.inscription.valider')
              }}</button>
            </div>
            <div class="col-md-12 mt-5 text-center connectez-vous">

              <h6>{{ $t('forms.inscription.deja_compte') }}</h6> <br>
              <img class="image-bladi" src="@/assets/images/img-bladi.png" />
              <router-link to="/login">{{ $t('forms.inscription.connectez_vous') }}</router-link><img
                class="image-bladi-right" src="@/assets/images/img-bladi.png" />
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rubrique from "@/components/Outils/Rubrique";
import User from "@/apis/User";
export default {
  name: "VoyageurDashbord",
  components: { Rubrique },
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      form: {
        nom: "",
        prenom: "",
        name: "",
        pseudo: "",
        email: "",
        password: "",
        confirmation_de_adresse_email: "",
        confirmation_de_mot_de_passe: "",
        regles: "",
      },
      errors: []
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    register() {
      User.register(this.form)
        .then(() => {
          this.$router.push({ name: "Login" });
          this.$toaster.success('Votre compte a bien été créé. Connectez-vous maintenant pour voir votre espace voyageur');
          // this.$router.push({ name: "MonCompte" });
          // this.$toaster.success('Votre espace voyageur a bien été créé.')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    }
  }
}
</script>
<style scoped>
.connectez-vous a {
  font-family: Sans;
  font-size: 15px;
  background: transparent;
  color: rgb(152, 26, 29);
  padding: 0px 40px;
  border-radius: 25px;
}
input {
    border-radius: 25px !important;
}
.image-bladi {
  margin-top: -4px;
  margin-right: -30px;
  width: 30px;
}

.image-bladi-right {
  margin-top: -4px;
  margin-left: -30px;
  width: 30px;
}

.password-eye {
  position: absolute !important;
  right: 0px;
  border: none !important;
  z-index: 10 !important;
}
</style>