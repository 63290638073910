<template>
<div class="row ">
    <div class="col-md-4 py-3" v-for="post of posts" :key="post.id">
        <router-link v-bind:to="'/'+slug+'/' + ($i18n.locale === 'Français' || $i18n.locale === 'English' ? post.slug:(post.slug_ar||post.slug))">
        <div class="card" style="">
            <!-- <img src="http://placehold.jp/300x200.png" class="card-img-top" alt="...">-->
            <img v-bind:src="BaseUrl + '/storage/' + post.image" class="card-img-top" />
            <div class="card-body">
            <h5 class="card-title" v-if="$i18n.locale === 'Français'">{{ post.titre ??  post.titre_ar }}</h5>
            <h5 class="card-title" v-else-if="$i18n.locale === 'English'">{{ post.titre_en ??  post.titre }}</h5>
            <h5 class="card-title" v-else>{{ post.titre_ar ??  post.titre}}</h5>
            <div v-if="post.description_ar != null || post.description != null || post.description_en != null">
                <p class="card-description" v-if="$i18n.locale === 'Français'">{{post.description ? (post.description.substring(0, 230) + ".." ): (post.description_ar.substring(0, 230) + ".." ) }}</p>
                <p class="card-description" v-else-if="$i18n.locale === 'English'">{{post.description_en ? (post.description_en.substring(0, 230) + ".." ): (post.description.substring(0, 230) + ".." ) }}</p>
                <p class="card-description" v-else>{{post.description_ar ? (post.description_ar.substring(0, 230) + ".." ): (post.description.substring(0, 230) + ".." ) }}</p>
            </div>
            <span class="float-right lire-plus">{{ $t('details_service.lire_plus') }} <b-icon-arrow-right>
                </b-icon-arrow-right></span>
            </div>
        </div>
        </router-link>
    </div>
</div>
</template>
<script>
export default {
    name:"ListInfo",
    props:{
        posts:Array,
        slug:String,
    },
    data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
    }
    }
}
</script>
<style>
a:hover,a {
    color: black;
    text-decoration: none;
}
</style>