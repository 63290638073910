<template>
    <div class="col-md-12 hotel-rs mt-5">
        <h3 class="text-center mb-5">Aucun résultat à votre recherche.</h3>
        <div class="container bg-suggestion">
            <h4 class="text-center mt-4 pt-4">Nos suggestions</h4>
            <div class="row">
                <div v-for="hotel of hotels" :key="hotel.id" class="col-md-3 py-4 ">
                    <router-link v-bind:to="hotel.url+ '/' + hotel.slug">
                        <div class="card card-suggestion" style="">
                            <!-- <img src="http://placehold.jp/300x200.png" class="card-img-top" alt="...">-->
                            <img v-bind:src="BaseUrl + '/storage/' + hotel.images[0].image" class="img-suggestion" />
                            <div class="card-body">
                                <h5 class="card-title">{{ hotel.nom }}</h5>
                                <!-- <p class="card-text"><img src="@/assets/images/map-marker.png" class="map-marker" />
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem, omnis?</p> -->
                                <p class="card-description"
                                    v-html="!!hotel.description ? hotel.description.substring(0, 100) + '..' : ''"></p>
                            </div>
                        </div>
                    </router-link>
                </div>

                <!-- <div class="col-md-3 py-4 ">
                    <router-link to="/">
                        <div class="card card-suggestion" style="">
                            <img src="http://placehold.jp/300x200.pn" class="img-suggestion" />
                            <div class="card-body">
                                <h5 class="card-title">Lorem ipsum dolor sit.</h5>
                                <p class="card-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Dolorem, omnis?</p>
                            </div>
                        </div>
                    </router-link>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuggestionCard',
    props: {
        hotels: Array,
    },
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            url: this.$route.path

        }
    }

}
</script>

<style scoped>
h3 {
    color: #008ec6;
}

h4 {
    color: #565a5d;
}

.bg-suggestion {
    background-color: #dbedef;
    border-radius: 20px;
}

.card-suggestion {
    border-radius: 22px;
}

.img-suggestion {
    border-radius: 10px;

    max-height: 250px;
    margin: auto;
    height: 150px !important;
    width: 100% !important;
    max-height: 150px !important;
    min-height: 150px !important;
    object-fit: cover !important;
    object-position: center center !important;
}
</style>