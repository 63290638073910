<template>
  <div class="container-fluid">
    <div class="container min-height mt-5">
      <div class="row espace-prof top-forme justify-content-center">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro :niveau2="1" :niveau3="1" :niveau4="0" :level="isDataAvailable" />
          <div class="row descriptif-body p-3 dir-rtl">
            <div class="col-md-12">
              <div class="form-group">
                <label for="description">{{ $t('details_service.description') }} <sup>*</sup></label>
                <textarea style="width:100%;" v-model="form.description" id="description" name="description"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_en">{{ $t('details_service.description_en') }}</label>
                <textarea style="width:100%;" v-model="form.description_en" id="description_en" name="description_en"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_en.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_en.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_ar">{{ $t('details_service.description_ar') }} </label>
                <textarea style="width:100%;" v-model="form.description_ar" id="description_ar" name="description_ar"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_ar.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_ar.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'Français' ? 'description' : 'الوصف' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <h3 class="p-3 m-3">{{ $t('forms.restaurant_pro.ajt_menu') }}</h3>
              <div class="accordion" role="tablist">
                <div class="text-center">
                  <b-button class="m-auto entree border-0" v-b-toggle.accordion-1> {{ $t('forms.restaurant_pro.entree')
                  }}
                    <span class="when-opened">
                      <b-icon-chevron-down />
                    </span>
                    <span class="when-closed">
                      <b-icon-chevron-right />
                    </span>
                  </b-button>
                </div>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <div class="">
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.nom') }} </label>
                      <input type="text" ref="entreeMenu" v-model="entree.menu" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder="">
                    </div>
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.description') }} </label>
                      <textarea type="text" v-model="entree.descriptionMenu" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder=""></textarea>
                    </div>
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.prix_dh') }} </label>
                      <input type="text" v-model="entree.prix" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder="">
                    </div>
                    <div class="form-group text-center">
                      <button v-if="!isEditEntree" class="btn bg-msafar-primary text-white m-auto"
                        v-on:click="addItem(1)">
                        <b-icon-plus></b-icon-plus>
                      </button>
                      <div class="btn-inline justify-content-center" v-else>
                        <button class="btn btn-primary bg-msafar-primary" @click="editItem(1)">
                          {{ $t('forms.generale.enregistrer') }} </button>
                        <button class="btn btn-secondary ml-3" @click="cancelEdit(1)"> {{ $t('forms.generale.annuler')
                        }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('forms.restaurant_pro.menu') }}</th>
                        <th>{{ $t('forms.restaurant_pro.description') }}</th>
                        <th>{{ $t('forms.restaurant_pro.prix_dh') }}</th>
                        <th style="width:  100px">{{ $t('forms.restaurant_pro.action') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in itemsEntree" :key="index">
                        <td>{{ item.nom }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.prix }}</td>
                        <td>
                          <span>
                            <button class="btn btn-sm btn-danger" @click="deleteItem(item, 1)"><i
                                class="fa fa-times"></i></button>
                            <button class="btn btn-sm btn-warning" @click="selectItem(item, 1)"><i
                                class="fa fa-edit"></i></button>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
                <div class="text-center m-2">
                  <b-button class="m-auto plats border-0" v-b-toggle.accordion-2> {{ $t('forms.restaurant_pro.plats') }}
                    <span class="when-opened">
                      <b-icon-chevron-down />
                    </span>
                    <span class="when-closed">
                      <b-icon-chevron-right />
                    </span>
                  </b-button>
                </div>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <div class="">
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.nom') }} </label>
                      <input type="text" ref="platMenu" v-model="plat.menu" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder="">
                    </div>
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.description') }} </label>
                      <textarea type="text" v-model="plat.descriptionMenu" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder=""></textarea>
                    </div>
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.prix_dh') }} </label>
                      <input type="text" v-model="plat.prix" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder="">
                    </div>
                    <div class="form-group text-center">
                      <button v-if="!isEditPlat" class="btn btn-primary m-auto" v-on:click="addItem(2)">
                        <b-icon-plus></b-icon-plus>
                      </button>
                      <div class="btn-inline" v-else>
                        <button class="form-control btn btn-primary bg-msafar-primary" @click="editItem(2)"> {{
                            $t('forms.generale.enregistrer')
                        }}
                        </button>
                        <button class="form-control btn btn-secondary " @click="cancelEdit(2)"> {{
                            $t('forms.generale.annuler')
                        }} </button>
                      </div>
                    </div>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('forms.restaurant_pro.menu') }}</th>
                        <th>{{ $t('forms.restaurant_pro.description') }}</th>
                        <th>{{ $t('forms.restaurant_pro.prix_dh') }}</th>
                        <th>{{ $t('forms.restaurant_pro.action') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in itemsPlats" :key="index">
                        <td>{{ item.nom }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.prix }}</td>
                        <td><span>
                            <button class="btn btn-sm btn-danger" @click="deleteItem(item, 2)"><i
                                class="fa fa-times"></i></button>
                            <button class="btn btn-sm btn-warning" @click="selectItem(item, 2)"><i
                                class="fa fa-edit"></i></button>
                          </span></td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
                <div class="text-center m-2">
                  <b-button class="m-auto dessert border-0" v-b-toggle.accordion-3> {{
                      $t('forms.restaurant_pro.dessert')
                  }}
                    <span class="when-opened">
                      <b-icon-chevron-down />
                    </span>
                    <span class="when-closed">
                      <b-icon-chevron-right />
                    </span>
                  </b-button>
                </div>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <div class="">
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.nom') }} </label>
                      <input type="text" ref="dessertMenu" v-model="dessert.menu" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder="">
                    </div>
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.description') }} </label>
                      <textarea type="text" v-model="dessert.descriptionMenu" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder=""></textarea>
                    </div>
                    <div class="form-group">
                      <label class="p-2" for="">{{ $t('forms.restaurant_pro.prix_dh') }} </label>
                      <input type="text" v-model="dessert.prix" class="form-control" name="" id=""
                        aria-describedby="helpId" placeholder="">
                    </div>
                    <div class="form-group text-center">
                      <button v-if="!isEditDessert" class="btn btn-primary m-auto" v-on:click="addItem(3)">
                        <b-icon-plus></b-icon-plus>
                      </button>
                      <div class="btn-inline" v-else>
                        <button class="form-control btn btn-primary bg-msafar-primary" @click="editItem(3)"> {{
                            $t('forms.generale.enregistrer')
                        }}
                        </button>
                        <button class="form-control btn btn-secondary " @click="cancelEdit(3)"> {{
                            $t('forms.generale.annuler')
                        }} </button>
                      </div>
                    </div>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('forms.restaurant_pro.menu') }}</th>
                        <th>{{ $t('forms.restaurant_pro.description') }}</th>
                        <th>{{ $t('forms.restaurant_pro.prix_dh') }}</th>
                        <th>{{ $t('forms.restaurant_pro.action') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in itemsDessert" :key="index">
                        <td>{{ item.nom }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.prix }}</td>
                        <td><span>
                            <button class="btn btn-sm btn-danger" @click="deleteItem(item, 3)"><i
                                class="fa fa-times"></i></button>
                            <button class="btn btn-sm btn-warning" @click="selectItem(item, 3)"><i
                                class="fa fa-edit"></i></button>
                          </span></td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
              </div>
            </div>
            <div class="col-md-6 ml-4">
              <h4>{{ $t('forms.restaurant_pro.prix_min_menu') }}</h4>
              <div class="form-group form-row mt-4">
                <label for="prix_min">{{ $t('forms.generale.prix_min') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_min" id="prix_min" name="prix_min" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_min.$error }" />
                <div v-if="submitted && !$v.form.prix_min.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
                      name: $i18n.locale === 'Français' ? 'Prix Min' : 'السعر الأدنى'
                    })
                  }}
                </div>
              </div>
              <div class="form-group form-row">
                <label for="prix_max">{{ $t('forms.generale.prix_max') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_max" id="prix_max" name="prix_max" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_max.$error }" />
                <div v-if="submitted && !$v.form.prix_max.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
                      name: $i18n.locale === 'Français' ? 'Prix Max' : 'السعر الأقصى'
                    })
                  }}
                </div>
              </div>
            </div>
            <!-- <div class="col-md-12 mt-3 pl-3 tabs-message-profil">
              <div>
                <b-tabs content-class="mt-3" justified>
                  <b-tab :title="$t('forms.generale.mes_messages')" active>
                    <div class="row justify-content-center">
                      <div class="col-md-11 message" v-for="message in messages" :key="message.id">
                        <p><strong>{{ message.username }} : </strong> {{ message.subject }}</p>
                        <p>{{ message.message }}</p>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab :title="$t('forms.generale.notification')">
                    <div class="row justify-content-center">
                      <div class="col-md-11 message" v-for="notification in commentaires" :key="notification.id">
                        <router-link :to="`/restaurants/${form.slug}`">
                          <b-img blank blank-color="#01c203" class="mr-2" rounded="circle" width="12" height="12">
                          </b-img>
                          <span v-if="notification.user">{{ `${notification.user.nom} ${notification.user.prenom}`
                          }}</span> a ajouté un avis sur votre restaurant .
                        </router-link>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div> -->
            <div :class="[this.$i18n.locale === 'العربية' ? 'float-right' : '', 'col-md-12 mt-5 p-5']">
              <button type="submit" class="bg-msafar-primary text-white btn rounded-pill pl-4 pr-5 pt-2 pb-2"
                @click="validate">
                <i class="fas fa-check-circle mr-3"></i>
                <span v-if="isDataAvailable">{{ $t('forms.inscription.modifier') }}</span>
                <span v-else>{{ $t('forms.inscription.valider_pro') }}</span>
              </button>
              <div v-if="isDataAvailable" :class="this.$i18n.locale === 'العربية' ? 'float-left' : 'float-right'">
                <button type="button" @click="etapeSuivante()"
                  class="bg-msafar-primary btn text-white rounded-pill  pl-5 pr-5 pt-2 pb-2">
                  {{ $t('forms.inscription.etapes') }}
                  <span class="h1 color-msafar-primary flesh-profil" style="">
                    <b-icon-chevron-left v-if="this.$i18n.locale === 'العربية'"
                      style="margin-right: 40px;margin-bottom: 20px;"></b-icon-chevron-left>
                    <b-icon-chevron-right v-else></b-icon-chevron-right>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import http from "./../../http-common";
import User from "@/apis/User";
import Service from "@/apis/Services";
import { required } from 'vuelidate/lib/validators';


export default {
  name: "RestaurantEspaceProComponent",
  components: { RubriquePro },
  data() {
    return {
      text: '',
      form: {
        description: "",
        description_ar: "",
        description_en: "",
        prix_min: "",
        prix_max: ""
      },
      itemsEntree: [],
      itemsPlats: [],
      itemsDessert: [],
      menu: {
        menu: '',
        descriptionMenu: '',
        prix: 0,
      },
      entree: {
        menu: '',
        descriptionMenu: '',
        prix: 0,
      },
      plat: {
        menu: '',
        descriptionMenu: '',
        prix: 0,
      },
      dessert: {
        menu: '',
        descriptionMenu: '',
        prix: 0,
      },
      id_restaurant: "",
      messages: [],
      commentaires: [],
      currentItem: [],
      isEditEntree: false,
      isEditPlat: false,
      isEditDessert: false,
      submitted: false,
      isDataAvailable: false
    }
  },
  methods: {
    etapeSuivante() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.isDataAvailable) {
        this.$router.push("/profil-pro/photos");
      } else {
        this.$toaster.info('Merci de valider le formulaire pour passer à l\'étape suivante');
      }
    },
    //function to add data to table
    addEntree() {
      this.itemsEntree.push(this.entree);
      //this.vider();
    },
    addPlats() {
      this.itemsPlats.push(this.plat);
      //this.vider();
    },
    addDessert() {
      this.itemsDessert.push(this.dessert);
      //this.vider();
    },
    vider() {
      // this.menu = '';this.descriptionMenu = '';this.prix = '';
    },
    validate() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this.$hotel.value = this.form;
      // this.$router.push("/professionnel/"+this.$typeService.value);
      Service.updateHebergement(this.form.id, this.user.type_service, this.form).then((response) => {
        console.log(response.data);
        this.form = response.data;
        this.isDataAvailable = true;
        this.$toaster.success('Votre espace professionnel a bien été modifié')
        this.$router.push("/profil-pro/photos");

      }).catch(error => {
        console.log(error);
      });
    },
    addMenus() {
      var formData = new FormData();
      formData.append('itemsEntree', JSON.stringify(this.itemsEntree));
      formData.append('itemsPlats', JSON.stringify(this.itemsPlats));
      formData.append('itemsDessert', JSON.stringify(this.itemsDessert));

      formData.append('id_restaurant', this.id_restaurant);
      http.post('/ligne-menus', formData)
        .then((response) => {
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
          // this.$toaster.error(e.data)
        })
    },
    getUser() {
      User.auth().then((response) => {
        this.user = response.data;
        this.getHebergement();
      }).catch(error => {
        console.log(error);
      });
    },
    getHebergement() {
      console.log(this.user.id + ',' + this.user.type_service);
      Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
        console.log(response.data);
        this.form = response.data;
        this.isDataAvailable = response.data.length !== 0 && response.data.description.length !== 0;
        // this.listeMessages(response.data.id);
        // this.getCommentaires(response.data.id);
        const ligne_menus = response.data.ligne_menus;
        this.itemsEntree = ligne_menus.length > 0 ? ligne_menus.filter((item) => item.categoriemenu.id == 1)[0].menus : this.itemsEntree;
        this.itemsPlats = ligne_menus.length > 0 ? ligne_menus.filter((item) => item.categoriemenu.id == 2)[0].menus : this.itemsEntree;
        this.itemsDessert = ligne_menus.length > 0 ? ligne_menus.filter((item) => item.categoriemenu.id == 3)[0].menus : this.itemsEntree;

      }).catch(error => {
        console.log(error);
      });

    },
    addItem(categorie_id) {

      var formData = new FormData();
      if (categorie_id === 1)
        this.menu = this.entree;
      else if (categorie_id === 2)
        this.menu = this.plat;
      else
        this.menu = this.dessert;

      formData.append('nom', this.menu.menu);
      formData.append('description', this.menu.descriptionMenu);
      formData.append('prix', this.menu.prix);
      formData.append('id_restaurant', this.form.id);
      formData.append('id_categorie', categorie_id);
      console.log({ formData });
      http.post(`/menus`, formData)
        .then((response) => {
          console.log(response.data)
          /*    push in javascript    */
          if (categorie_id === 1)
            this.itemsEntree.push(response.data.menu);
          else if (categorie_id === 2)
            this.itemsPlats.push(response.data.menu);
          else
            this.itemsDessert.push(response.data.menu);
          /*    end    */
          this.$toaster.success('Menu a bien été ajouté');
          this.vider();
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    cancelEdit(categorie_id) {
      if (categorie_id === 1) {
        this.entree.menu = '';
        this.entree.descriptionMenu = '';
        this.entree.prix = '';
        this.isEditEntree = false;
      }
      else if (categorie_id === 2) {
        this.plat.menu = '';
        this.plat.descriptionMenu = '';
        this.plat.prix = '';
        this.isEditPlat = false;
      }
      else {
        this.dessert.menu = '';
        this.dessert.descriptionMenu = '';
        this.dessert.prix = '';
        this.isEditDessert = false;
      }
    },

    selectItem(item, categorie_id) {
      console.log({ item, categorie_id });

      if (categorie_id === 1) {
        this.entree.id = item.id;
        this.entree.menu = item.nom;
        this.entree.descriptionMenu = item.description;
        this.entree.prix = item.prix;
        this.currentItem = this.entree;
        this.isEditEntree = true;
        this.$refs.entreeMenu.focus();
      }
      else if (categorie_id === 2) {
        this.plat.id = item.id;
        this.plat.menu = item.nom;
        this.plat.descriptionMenu = item.description;
        this.plat.prix = item.prix;
        this.currentItem = this.plat;
        this.isEditPlat = true;
        this.$refs.platMenu.focus();
      }
      else {
        this.dessert.id = item.id;
        this.dessert.menu = item.nom;
        this.dessert.descriptionMenu = item.description;
        this.dessert.prix = item.prix;
        this.currentItem = this.dessert;
        this.isEditDessert = true;
        this.$refs.dessertMenu.focus();
      }
      //dessertMenu
      console.log(this.entree);

    },
    editItem(categorie_id) {

      if (categorie_id === 1) { this.currentItem = this.entree }
      else if (categorie_id === 2) { this.currentItem = this.plat }
      else { this.currentItem = this.dessert }
      var objEdited = { 'id': this.currentItem.id, 'nom': this.currentItem.menu, 'description': this.currentItem.descriptionMenu, 'prix': this.currentItem.prix }
      http.put(`/menus/${this.currentItem.id}`, objEdited)
        .then(() => {

          if (categorie_id === 1)
            this.itemsEntree = this.itemsEntree.map(obj => { return parseInt(obj.id) === parseInt(this.currentItem.id) ? objEdited : obj });
          else if (categorie_id === 2)
            this.itemsPlats = this.itemsPlats.map(obj => { return parseInt(obj.id) === parseInt(this.currentItem.id) ? objEdited : obj });
          else
            this.itemsDessert = this.itemsDessert.map(obj => { return parseInt(obj.id) === parseInt(this.currentItem.id) ? objEdited : obj });


          this.$toaster.success('Menu a bien été modifié')

        })
        .catch(e => {
          console.log(e.data);
        })

    },
    deleteItem(item, categorie) {
      console.log(item);
      http.delete(`/menus/${item.id}`)
        .then(() => {

          if (categorie === 1)
            this.itemsEntree = this.itemsEntree.filter(obj => obj.id != item.id);
          else if (categorie === 2)
            this.itemsPlats = this.itemsPlats.filter(obj => obj.id != item.id);
          else
            this.itemsDessert = this.itemsDessert.filter(obj => obj.id != item.id);

          // ~removeIndex && this.items.splice(removeIndex,1);
          this.$toaster.success('Menu a bien été supprimé')
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    // getCommentaires(id) {
    //   http.get(`/commentaires/restaurant/${id}`).then(response => {
    //     this.commentaires = response.data;
    //   }).catch((errors) => {
    //     console.log(errors)
    //   });
    // },
    // listeMessages(id) {
    //   http.get(`/reservations/restaurant/${id}`)
    //     .then((response) => {
    //       this.messages = response.data
    //     })
    //     .catch(e => {
    //       console.log(e.data);
    //     })

    // },
  },
  created() {
    this.getUser();

  },
  validations: {
    form: {
      description: { required },
      prix_min: { required },
      prix_max: { required },
    }
  },
}
</script>

<style scoped>
.connectez-vous a {
  font-family: GillSansMT;
  font-size: 15px;
  background: transparent;
  color: rgb(152, 26, 29);
  padding: 0px 40px;
  border-radius: 25px;
}

.image-bladi {
  margin-top: -4px;
  margin-right: -30px;
  width: 30px;
}



.image-bladi-right {
  margin-top: -4px;
  margin-left: -30px;
  width: 30px;
}


.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}

.entree {
  background-color: #ff7a01;
}

.plats {
  background-color: #1fa4cd;
}

.dessert {
  background-color: #215b4f;
}

.entree,
.plats,
.dessert {
  color: white;
}

.border-0,
.btn-primary {
  border: 0px !important;
}

.btn {
  border: 0;
}

.btn-danger {
  padding: 4px 10px 4px 10px !important;
}

.btn-warning {
  padding: 4px 8px 4px 8px !important;
  margin-left: 3px;
}



.message {
  background-color: #c6f0fc;
  border-radius: 25px;
  margin-bottom: 10px;
  padding: 15px;
}

.message p {
  padding: 2px;
  margin: 0px;
}

.message a {
  color: black;
}

th:last-child() {
  width: 100px !important;
}

.btn-inline {
  display: flex;
}
</style>