<template>
  <div class="container-fluid">

    <div class="container min-height mt-5">

      <div class="row espace-prof justify-content-center text-left">
        <div class="col-md-10 bg-white " >
          
          <div class="row  descriptif-body p-3">


            <div class="col-md-12 text-center">
                <div class="shadow p-4 " style=" border-radius: 50px;">
                    <h5 class="text-felicitation" style="">
                        
                        Votre inscription est terminée.  <router-link to="/login"> Accéder maintenant à votre compte.</router-link>
                    </h5>
                <img style="width:100%;;" src="@/assets/images/felicitation.jpg" />
                
              </div>  

            </div>
     

          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>





export default {
  name: "DescriptifComponent",
  components: {  },
}
</script>

<style scoped>

.text-felicitation{
    position:absolute;text-align:center;width:100%;margin-top:240px;margin-left:-20px;
}

</style>