<template>
    <div class="container-fluid">
        <div class="container min-height">
            <div class="row espace-prof top-forme justify-content-center text-left mt-5">
                <div class="col-md-10 bg-white coordonnees-activite-body shadow">
                    <RubriquePro :niveau2="1" :niveau3="1" :niveau4="1" :niveau5="1" :level="isDataAvailable" />
                    <div class="col-md-12 mt-3 mb-5 pl-3 tabs-message-profil">
                        <div>
                            <b-tabs content-class="mt-3" justified>
                                <b-tab :title="$t('forms.generale.mes_messages')" active>
                                    <div class="row justify-content-center">
                                        <div class="col-md-11 message" v-for="message in messages" :key="message.id">
                                            <p><strong>{{ message.username }} : </strong> {{ message.subject }}</p>
                                            <p>{{ message.message }}</p>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab :title="$t('forms.generale.notification')">
                                    <div class="row justify-content-center">
                                        <div class="col-md-11 message" v-for="notification in commentaires"
                                            :key="notification.id">
                                            <router-link :to="`/activites-touristiques/${form.slug}`">
                                                <b-img blank blank-color="#01c203" class="mr-2" rounded="circle"
                                                    width="12" height="12">
                                                </b-img>
                                                <span v-if="notification.user">{{ `${notification.user.nom}
                                                    ${notification.user.prenom}`
                                                    }}</span> a ajouté un avis sur votre activité .
                                            </router-link>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import User from "@/apis/User";
//import Api from "@/apis/Api";
import Service from "@/apis/Services";
import http from "./../../http-common";

export default {
    name: "messagesNotificationsProfil",
    components: { RubriquePro },
    data() {
        return {
            errors: [],
            villes: [],
            user: [],
            messages: [],
            commentaires: [],


            linkToService: '',
            submitted: false,
            isDataAvailable: false
        };
    },
    mounted() {

        this.getUser();

    },
    methods: {
        getCommentaires(id) {
            http.get(`/commentaires/${this.user.type_service}/${id}`).then(response => {
                this.commentaires = response.data;
            }).catch((errors) => {
                console.log(errors)
            });
        },
        listeMessages(id) {
            http.get(`/reservations/${this.user.type_service}/${id}`)
                .then((response) => {
                    this.messages = response.data
                })
                .catch(e => {
                    console.log(e.data);
                })
        },
        getUser() {
            User.auth().then((response) => {
                // console.log(response.data);
                this.user = response.data;
                this.getHebergement();

                var typeService = response.data.type_service;
                if (typeService == 'hotel')
                    this.linkToService = 'HotelProProfil';
                else if (typeService == 'riad')
                    this.linkToService = 'RiadProProfil';
                else if (typeService == 'maison-hote')
                    this.linkToService = 'MaisonHoteProProfil';
                else if (typeService == 'restaurant')
                    this.linkToService = 'RestaurantProProfil';
                else if (typeService == 'activite')
                    this.linkToService = 'ActiviteProProfil';
                else if (typeService == 'guide')
                    this.linkToService = 'GuideProProfil';
                else if (typeService == 'appart-hotels')
                    this.linkToService = 'AppartHotelProfil';
            }).catch(error => {
                console.log(error);
            });
        },

        getHebergement() {
            console.log(this.user.id + ',' + this.user.type_service);
            Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
                this.form = response.data;
                this.isDataAvailable = response.data.length !== 0 && response.data.description.length !== 0;
                this.listeMessages(response.data.id);
                this.getCommentaires(response.data.id);
            }).catch(error => {
                console.log(error);
            });
        },
    },

    created() {
        http.get('/villes')
            .then((response) => {
                this.villes = response.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    }

}
</script>

<style>
.iti--allow-dropdown {
    width: 100% !important;
}
</style>
