<template>
  <div class="container min-height">
    <Rubrique></Rubrique>
    <div class="row justify-content-center mt-5">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <router-link to="/partages/">
              <h1 class="title-arabe" style="display: inline-block; color: black">
                <img style="margin-top: -15px; margin-right: 15px; width: 55px;" src="@/assets/images/img-bladi.png" />
                {{ $t('rubrique.partages') }}
              </h1>
            </router-link>
            <router-link to="/partages/">
              <b-icon-arrow-right v-if="this.$i18n.locale == 'العربية'"
                class="arrow-left float-left color-primary"></b-icon-arrow-right>
              <b-icon-arrow-left v-else class="arrow-left float-right color-primary"></b-icon-arrow-left>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row top-forme justify-content-center">
      <div class="col-md-10 bg-white p-5 shadow">
        <!-- Post content -->
        <div class="body partage-author row m-4">
          <img v-if="partage.user && partage.user.avatar" class="img-avatar"
            v-bind:src="`${BaseUrl}/storage/${partage.user.avatar}`">
          <img v-else
            :src="'https://ui-avatars.com/api/?name=' + (partage.user ? partage.user.prenom + ' ' + partage.user.nom : '')"
            class="img-avatar" />
          <div class="user-partage" v-if="partage.user">
            <span v-if="partage.user.prenom">{{ partage.user.prenom }}</span>
            <span v-if="partage.user.nom">{{ partage.user.nom }}</span>
            <div class="time">
              <span>{{ format_date(partage.created_at) }}</span>
            </div>
          </div>
        </div>

        <div class="mb-4 text-center">
          <img class="max-width-100" style="" v-bind:src="BaseUrl + '/storage/' + partage.image" />
        </div>
        <h1 v-if="$i18n.locale === 'Français'">{{ partage.titre ?? partage.titre_ar }}</h1>
        <h1 v-else-if="$i18n.locale === 'English'">{{ partage.titre_en ?? partage.titre }}</h1>
        <h1 v-else>{{ partage.titre_ar ?? partage.titre }}</h1>
        <p v-if="$i18n.locale === 'Français'">{{ partage.description ?? partage.description_ar }}</p>
        <p v-else-if="$i18n.locale === 'English'">{{ partage.description_en ?? partage.description }}</p>
        <p v-else>{{ partage.description_ar ?? partage.description }}</p>
        <!-- Comment section -->
        <CommentairePartage :commentaires="commentaires" :errors="errors" :partageId="partage.id"
          @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)" @DeleteComment="supComment($event)" />
        <!-- Share Buttons -->
        <SocialShare />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import CommentairePartage from "./EspaceProfessionnel/CommentairePartage.vue";
import SocialShare from '@/components/SocialShare.vue';

import seo from "../seo-sevices.js";
import $i18n from '../i18n.js';
export default {
  metaInfo() {
    if ($i18n.locale == "Français" || $i18n.locale == "English") {
      return seo.metaInfo_fr(this.partage, this.images);
    }
    else {
      return seo.metaInfo_ar(this.partage, this.images);
    }
  },
  name: "PartageView",
  components: { Rubrique, CommentairePartage, SocialShare },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      partage: [],
      items: [],
      images: [],
      user_id: localStorage.getItem('id'),
      errors: [],
      comment: [],
      count: 5,
      commentaires: [],
      perPage: 2,
      currentPage: 1
    }
  },
  mounted() {
    const id = this.id;
    http.get(`/partages/${id}`)
      .then((response) => {
        this.partage = response.data;
        this.commentaires = response.data.commentpartage;
        let userId = this.user_id;
        this.commentaires = this.commentaires.map(function (field) {
          return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
        });
      })
      .catch(e => {
        this.errors.push(e);
      });
  },
  methods: {
    shareOnFacebook() {
      const postUrl = window.location.href;
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
      window.open(facebookShareUrl, '_blank');
    },
    shareOnInstagram() {
      const postUrl = window.location.href;
      alert('To share this post on Instagram, copy the link and share it manually: ' + postUrl);
    },
    shareOnTwitter() {
      const postUrl = window.location.href;
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(postUrl)}`;
      window.open(twitterShareUrl, '_blank');
    },
    shareOnTikTok() {
      // TikTok doesn't provide a direct sharing URL
      const postUrl = window.location.href;
      alert('To share this post on TikTok, copy the link and share it manually: ' + postUrl);
    },
    shareOnLinkedIn() {
      const postUrl = window.location.href;
      const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(postUrl)}`;
      window.open(linkedInShareUrl, '_blank');
    },
    myFilter() {
      if (!this.isActive) {
        this.isActive = !this.isActive;
        this.count += 1;
      } else {
        this.isActive = !this.isActive;
        this.count -= 1;
      }
    },
    ajtComment(event) {
      http.post('/commentpartage', event)
        .then(() => {
          this.getCommentaire();
          this.$toaster.success('Votre commentaire a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })

    },
    mdfComment(event) {
      http.post(`/commentpartage/${event.id}`, event).then(() => {
        this.getCommentaire();
        this.$toaster.error(this.$t('toaster.commentaires.modifier'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http.delete(`/commentpartage/${event.id}`).then(() => {
        this.getCommentaire();
        this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },
    getCommentaire() {
      const id = this.id;
      http.get(`/partages/${id}`)
        .then((response) => {

          this.commentaires = response.data.commentpartage
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
      }
    },
  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
</script>

<style scoped>
.img-avatar {
  float: left;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  -o-object-position: center center !important;
  object-position: center center !important;
}

.partage-author .time {
  float: left;
  margin-top: -10px;
  margin-left: 0px;
  color: #a3a3a3;
  width: 100%;
}

.partage-author .time span {
  font-size: 13px !important;
}

.partage-author .time svg {
  margin-top: -3px;
}
</style>