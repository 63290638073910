<template>
    <div class="container menu">
        <div class="row">
            <div class="col-md-2">
                <router-link to="/" class="nav-link"  v-bind="navAttributes" exact>
                    <img class="logo" src="@/assets/images/msafar-logo.png" />
                </router-link>
            </div>
            <div class="col-md-10">
                <nav class="navbar navbar-expand-lg ">
                    <button class="navbar-toggler ml-auto" type="button" v-bind="navAttributes"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav w-100">
                            <li class="nav-item first ">
                                <router-link to="/" class="nav-link" exact><span class="bg-accueil"></span>
                                </router-link>
                            </li>
                            <li class="nav-item" v-bind="navAttributes">
                                <router-link to="/qui-sommes-nous" class="nav-link" exact>{{
                                    $t('navbar.qui_sommes_nous')
                                }}
                                </router-link>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link to="/voyages" class="nav-link" exact>{{ $t('navbar.mon_voyage') }}
                                </router-link>
                            </li> -->
                            <li class="nav-item"  v-bind="navAttributes">
                                <router-link to="/services" class="nav-link" exact>{{ $t('navbar.services') }}
                                </router-link>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link to="/partages" class="nav-link partage-img" exact>{{ $t('navbar.partages')
                                }}</router-link>
                            </li>-->
                            <li class="nav-item"  v-bind="navAttributes">
                                <router-link to="/contact" class="nav-link partage-img" exact>{{ $t('navbar.contact') }}
                                </router-link>
                            </li>
                            <li class="nav-item menupro buttons"  v-bind="navAttributes"
                                v-if="!isLoggedIn || isLoggedIn && parseInt(role_id) === 1">
                                <router-link :to="isLoggedIn ? { name: 'Profil' } : { name: 'VoyageurHome' }">
                                    <button class=" btn btn-espacevoyageur rounded-pill  m-2 mb-4">
                                        {{ $t('espaces.espace_voyageur') }}
                                    </button>
                                </router-link>
                            </li>


                            <li v-if="isLoggedIn && parseInt(role_id) === 2" class="nav-item menupro buttons"  v-bind="navAttributes">
                                <router-link :to="{ name: 'InformationCompteProfil' }"> <button
                                        class="btn btn-espacepro rounded-pill  m-2 mb-4">{{
                                    $t('espaces.espace_professionnel')
                                }}
                                    </button> </router-link>
                            </li>

                            <li class="nav-item menupro buttons" v-else-if="!isLoggedIn"  v-bind="navAttributes">
                                <router-link :to="{ name: 'ProfessionnelHome' }"> <button
                                        class="btn btn-espacepro rounded-pill  m-2 mb-4">{{
                                    $t('espaces.espace_professionnel')
                                }} </button> </router-link>
                            </li>

                            <li class="nav-item buttons" v-if="isLoggedIn">
                                <a href="#" @click="$emit('showModal')" v-b-modal.modal-deconnecte
                                    class="btn-connexion mb-4">
                                    {{ $t('navbar.se_deconnecter') }}
                                </a>
                            </li>
                            <li :class="isLoggedIn ? 'mrl' : '' + 'nav-item buttons m-2 mb-4'">
                                <SelectLocale></SelectLocale>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>

        <b-modal id="modal-deconnecte" size="md" no-close-on-backdrop no-close-on-esc centered hide-footer hide-header>
            <h5 class="p-4 text-center bg-espace-professionnel">
                <p class="mt-3">{{ $t('pop_up.form_deconnexion.question') }}</p>
            </h5>
            <div class="row p-0 " style="margin-bottom:-17px;">
                <div class="col-md-6 col-6 p-0">
                    <b-button class="btn btn-block btn-oui" @click="logout('modal-deconnecte')">{{
                                    $t('pop_up.form_deconnexion.oui') }}</b-button>
                </div>
                <div class="col-md-6 col-6 p-0">
                    <b-button @click="hideModal('modal-deconnecte')" class="btn btn-danger btn-block btn-non">{{
                        $t('pop_up.form_deconnexion.non') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>

import User from '@/apis/User';
import SelectLocale from '../Languages/SelectLocale.vue';

export default {
    name: "navHeader",
    data() {
        return {
            user: [],
            isLoggedIn: false,
            user_id: localStorage.getItem("id"),
            linkToProfil: "",
            role_id: localStorage.getItem("role_id"),
            isMobile: false
        };
    },
    computed: {
    navAttributes() {
      return this.isMobile
        ? { 'data-toggle': 'collapse', 'data-target': '#navbarNav' }
        : {};
    }
  },
    mounted() {
        this.$root.$on("login", () => {
            this.isLoggedIn = true;
        });
        this.isLoggedIn = !!localStorage.getItem("token");
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
          this.isMobile = window.innerWidth <= 700;
        },
        display(event) {
            this.$emit("showModal", event);
        },
        hideModal(id) {
            this.$root.$emit("bv::hide::modal", id);
            this.$emit("hideBlur");
        },
        logout(id) {
            User.logout().then(() => {
                localStorage.removeItem("token");
                this.isLoggedIn = false;
                localStorage.clear();
                // this.$toaster.success('Vous vous êtes déconnecté avec succès.')
                this.$root.$emit("bv::hide::modal", id);
                this.$router.push({ name: "Home" });
                this.$emit("hideBlur");
            })
                .catch(() => {
                    localStorage.clear();
                    // this.$toaster.success('Vous vous êtes déconnecté avec succès.')
                    this.$root.$emit("bv::hide::modal", id);
                    this.$router.push({ name: "Home" });
                    this.$router.go();
                    this.$emit("hideBlur");
                });
        },
        getUser() {
            if (this.user_id) {
                User.auth().then(response => {
                    this.user = response.data;
                }).catch((errors) => {
                    console.log(errors);
                });
            }
        },
    },
    created() {
        if (this.user_id) {
            this.getUser();
        }
    },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
    components: { SelectLocale }
}
</script>

<style scoped>
.navbar-nav {
    padding: 0 !important;
}

.navbar {
    margin-left: 30px;
}

.bg-espace-professionnel {
    background: transparent url('@/assets/images/deconnecter-modal.png') no-repeat;
    background-size: cover;
    /* width: 450px; */
    padding: 54px;
    margin: auto;
    margin-left: -17px;
    margin-right: -17px;
    margin-top: -17px;
    /* height: 414px; */
}

.router-link-active .bg-accueil {
    background: url("@/assets/images/accueil2.png") no-repeat !important;
    float: left !important;
    background-size: contain !important;
    width: 25px !important;
    height: 25px !important;
}



.btn-oui {
    border-bottom-left-radius: 100px !important;
    background-color: white;
    color: #008ec6;
    border: 1px solid #9ecde9;


}

.btn-oui:hover {
    border-bottom-left-radius: 100px !important;
    background-color: #008ec6;
    border: 1px solid #008ec6;
    color: white;
}

.btn-non {
    border-bottom-right-radius: 100px !important;
    background-color: white;
    color: #008ec6;
    border: 1px solid #9ecde9;


}

.btn-non:hover {
    border-bottom-right-radius: 100px !important;
    background-color: #008ec6;
    border: 1px solid #008ec6;
    color: white;
}

.buttons button {
    width: 150px;
    /* height: 40px; */
    text-align: center;
    line-height: 16px;
    font-size: 14.5px;
    /* font-weight: bold; */

}

.buttons .btn-espacepro {

    background-color: #008ec6;
    border: 1px solid #008ec6;
    color: white;
}

.buttons .btn-espacevoyageur {

    background-color: #de6401;
    border: 1px solid #de6401;
    color: white;
}


.buttons .btn-connexion {
    position: absolute;
    margin-top: 8px;
    /* margin-left: 5px; */
    text-decoration: none;
    color: black;
    font-size: 16px;
}

@media screen and (min-width: 767px) {

    .mrl {
        margin-left: 9rem !important;
        margin-top: 5px;
    }

}

@media screen and (max-width: 990px) {
    .buttons .btn-connexion {
        position: relative;
    }

    .mrl {
        margin-left: 0rem !important;
        margin-top: 1rem;
    }
}
@media screen and (max-width: 700px) {
    .buttons .btn-espacevoyageur, .buttons .btn-espacepro {
        background-color: transparent;
        border: none;
        color: black;
        font-weight: 600;
        text-align: left;
        padding: 0;
    }
    .buttons{
        text-align: left;
    }
    .rtl .buttons,.rtl .buttons .btn-espacevoyageur,.rtl  .buttons .btn-espacepro {
        text-align: right;
    }
}

.buttons .btn-espacepro #navbarNav .router-link-active:before {
    display: none;
    width: 0px;
}

.menupro a:before {
    display: none !important;
}
</style>