<template>
    <div class="container-fluid">

        <div class="container min-height">

            <div class="row espace-prof top-forme justify-content-center text-left mt-5">
                <div class="col-md-10 bg-white coordonnees-activite-body shadow">
                    <RubriquePro />

                    <div class="row p-5 dir-rtl">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="">Nom de l'établissement</label>
                                <b-form-input type="text" v-model="form.nom" aria-label="Text input with checkbox">
                                </b-form-input>
                                <span class="text-danger" v-if="errors.nom">
                                    {{ errors.nom[0] }}
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6" v-if="this.$typeService.value == 'hotel'">
                            <div class="form-group">
                                <label class="control-label" for="">Nombre d'étoiles</label>
                                <b-form-input type="number" v-model="form.nbr_etoiles"
                                    aria-label="Text input with checkbox"></b-form-input>
                                <span class="text-danger" v-if="errors.nbr_etoiles">
                                    {{ errors.nbr_etoiles[0] }}
                                </span>
                            </div>
                        </div>




                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="tel">Numéro de téléphone</label>


                                <b-form-input type="text" maxlength="12" v-model="form.tel" aria-label="Text input with checkbox">
                                </b-form-input>


                                <span class="text-danger" v-if="errors.tel">
                                    {{ errors.tel[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="confirmez-email">Email</label>




                                <b-form-input type="text" v-model="form.email" aria-label="Text input with checkbox">
                                </b-form-input>


                                <span class="text-danger" v-if="errors.email">
                                    {{ errors.email[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="">Whatsapp</label>



                                <b-form-input type="text" aria-label="Text input with checkbox"></b-form-input>


                                <span class="text-danger" v-if="errors.whatsapp">
                                    {{ errors.whatsapp[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="">Ville</label>
                                <select v-model="form.ville_id">
                                    <option value="" selected></option>
                                    <option v-for="ville of villes" :key="ville.id" :value="ville.id">{{ ville.ville }}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="confirmez-password">Site web</label>




                                <b-form-input type="text" v-model="form.website" aria-label="Text input with checkbox">
                                </b-form-input>

                                <span class="text-danger" v-if="errors.website">
                                    {{ errors.website[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="confirmez-password">Adresse</label>
                                <b-form-input type="text" v-model="form.adresse" aria-label="Text input with checkbox">
                                </b-form-input>
                                <span class="text-danger" v-if="errors.adresse">
                                    {{ errors.adresse[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="confirmez-password">Lien instagram</label>



                                <b-form-input type="text" v-model="form.instagram"
                                    aria-label="Text input with checkbox"></b-form-input>

                                <span class="text-danger" v-if="errors.instagram">
                                    {{ errors.instagram[0] }}
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="confirmez-password">Lien facebook</label>


                                <b-form-input type="text" v-model="form.facebook" aria-label="Text input with checkbox">
                                </b-form-input>

                                <span class="text-danger" v-if="errors.facebook">
                                    {{ errors.facebook[0] }}
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label" for="confirmez-password">Lien tiktok</label>

                                <b-form-input type="text" v-model="form.tiktok"></b-form-input>


                                <span class="text-danger" v-if="errors.tiktok">
                                    {{ errors.tiktok[0] }}
                                </span>
                            </div>
                        </div>







                             <div class="col-md-12 mt-5">
                                <button type="submit" class="bg-msafar-primary btn text-white rounded-pill pl-5 pr-5 pt-2 pb-2"
                                    @click="validate">Passer à l étape
                                    suivante</button>
                                <span class="h1 color-msafar-primary flesh" style="">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </span>
                           </div>

                    </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import RubriquePro from "@/components/EspaceProfessionnel/RubriquePro";
//import User from "@/apis/User";
import http from "./../../http-common";


export default {
    name: "InformationProfessionnelle2",
    components: {  RubriquePro },
    data() {
        return {
            form: {
                nom: "",
                email: "",
                nbr_etoiles: "",
                tel: "",
                website: "",
                adresse: "",
                facebook: "",
                tiktok: "",
                instagram: "",
                ville_id: "",
            },
            errors: [],
            villes: [],
        };
    },
    methods: {

        validate() {
            this.$hotel.value = this.form;

            this.$router.push("/professionnel/"+this.$typeService.value);

            /*
           http.post('/hotels',this.form)
              .then((response) => {
                  console.warn(response);
                   this.$toaster.success('Votre espace professionnel a été bien créé.')
              })
              .catch(e => {
                  console.log(e);
                   this.$toaster.error(e.data.message)
              })
              */

        }
    },
    created() {


    },
    mounted() {

        http.get('/villes')
            .then((response) => {
                this.villes = response.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    }
}
</script>

<style scoped>

</style>
