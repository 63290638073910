<template>
  <div class="container-fluid ">
    <Rubrique />
    <div class="container">
      <div class="row mt-5 pt-5">
        <div class="col-md-12 search">
          <h1 class="mb-5"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
              src="@/assets/images/img-bladi.png" />
              {{ $t('navbar.mon_voyage') }}
          </h1>

          <template v-if="isVoyageur()">

              <template v-if="listfavorites && listfavorites.length > 0">

              <div v-for="(favorite, index) in listfavorites" :key="index" class="row hotels">
                <div class="col-md-11 py-3 ">
                  <router-link :to="favorite.url+'/'+ favorite.slug">
                    <div class="card mb-3">
                      <div class="row no-gutters">
                        <div class="col-md-6">
                          <div class="card-body">
                            <h5 class="card-title">{{ favorite.nom }}</h5>
                            <p class="card-text" v-html="favorite.description.substring(0, 130) + '..'"></p>
                          </div>
                        </div>
                        <div class="col-md-6 mt-4 ">
                          <div class="row align-items-end ">
                            <div v-for="(image, index) in  favorite.images.slice(0, 5)" :key="index"
                              class="col-md-2 float-right">
                              <img v-bind:src="BaseUrl + '/storage/' + image.image" class="card-img" alt="...">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="cold-md-1 mt-3">
                  <b-icon class="mt-5" style="cursor: pointer;"  v-b-modal.modal-add icon="trash" font-scale="2" variant="grey"
                    @click="deleteFavorite(favorite.idFavo)">
                  </b-icon>
                </div>
              </div>

              </template>

              <template v-else>
                <div class="mb-5">
                  <h3 class="alert ">{{ $t('voyages.alert1') }}<router-link to="/services">+++</router-link></h3>
                </div>
              </template>


          </template>
          <template v-else>

              <div class="alert ">
             <h3>{{ $t('voyages.alert2') }}</h3>
             </div>

          </template>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Rubrique from './Outils/Rubrique.vue';
import http from "./../http-common";

export default {
  name: "VoyagesComponent",
  components: { Rubrique },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      favorites: [],
      items: Object,
      listfavorites: [],
      images: [],
      role_id:localStorage.getItem('role_id'),
    }
  },
  methods: {
    async getFavorites() {
      const id = localStorage.getItem('id');
      await http.get(`/user_favorite/${id}`)
        .then((response) => {
          this.favorites = response.data;
          this.favorites.forEach((item) => {
            if (item.hotel !== null) {
              this.listfavorites.push({ ...item.hotel,  'idFavo': item.id });
            }
            if (item.riad !== null) {
              this.listfavorites.push({ ...item.riad,  'idFavo': item.id });
            }
            if (item.maison !== null) {
              this.listfavorites.push({ ...item.maison,  'idFavo': item.id });
            }
            if (item.restaurant !== null) {
              this.listfavorites.push({ ...item.restaurant,  'idFavo': item.id });
            }
            if (item.activite !== null) {
              this.listfavorites.push({ ...item.activite,  'idFavo': item.id });
            }
            if (item.guide !== null) {
              this.listfavorites.push({ ...item.guide,  'idFavo': item.id });
            }
            if (item.apparthotel !== null) {
              this.listfavorites.push({ ...item.apparthotel,  'idFavo': item.id });
            }
          })
          // console.log(this.favorites);
          // console.log(this.listfavorites);
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    async deleteFavorite(id) {
      await http.delete(`/favorites/${id}`)
        .then(() => {
          this.$toaster.error('Votre enregistrement a été supprimé.')
         this.listfavorites = this.listfavorites.filter((item) => item.idFavo !== id);
          //this.getFavorites();
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    isVoyageur(){
      if( parseInt(this.role_id) === 1 ){
          return true;
      }
      return false;
    }
  },
  created() {
    this.getFavorites();
  }
}
</script>

<style scoped>
.hotels .card:hover {
  border: 3px solid #008ec6;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card {
  background-color: #d9fcff;
}
</style>