<template>
    <div>
        <div class="">
            <select v-model="$i18n.locale" @change="changeLanguage" class="categorie form-control form-control-sm" id="exampleFormControlSelect1">
                <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang"> {{ lang }} </option>
            </select>
        </div>
    </div>
</template>

<script>
import $i18n from '../../i18n.js';
export default {
    name: 'SelectLocale',
    data() {
        return { langs: ['Français', 'العربية', 'English'] }
    },
    methods: {
        changeLanguage() {
            localStorage.setItem('locale', $i18n.locale);
        }
    }
}
</script>
