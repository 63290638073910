<template>
    <div class="col-md-12 mt-3">
        <h5 style="color:#0172ae;font-size: 26px">{{$t('details_service.autres_services')}}</h5>
        <div class="row img-services">

                <div class="col-md-4 col-md-4 col-sm-3 col-xs-3 py-2"  v-for="service of services" v-bind:key="service.id">
                        <div>
                            <img v-bind:src="BaseUrl + '/storage/' + service.service.avatar">
                            {{ service.service.nom }}
                        </div>
                </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Hotel-service',
    props: {
        services: Array
    },
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
        }
    }
}
</script>