<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <ProfilSidebar />
            </div>
            <div class="col-md-8 overflow-auto">
                <div class="cold-md-8 mt-4">
                    <div class="row top-forme justify-content-center ">
                        <div class="col-md-10 bg-white message-style p-5 parent shadow">
                            <div class="text-center" >
                                <span class="child">13/12/2022</span>
                            </div>
                            <h5>Elghanemy saad</h5>
                            <h5>s.elghanemy@msafar.ma</h5>
                            <h5>Object</h5>
                            <h5>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam perspiciatis eum
                                reprehenderit doloribus sed aliquid pariatur sit cupiditate. Quidem dicta quod a eum
                                maiores nesciunt, tenetur sequi repellendus fugiat sint!10</h5>
                        </div>
                    </div>
                     <div class="row top-forme justify-content-center  mt-4">
                        <div class="col-md-10 bg-white message-style p-5 parent shadow">
                            <div class="text-center" >
                                <span class="child">13/12/2022</span>
                            </div>
                            <h5>Elghanemy saad</h5>
                            <h5>s.elghanemy@msafar.ma</h5>
                            <h5>Object</h5>
                            <h5>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam perspiciatis eum
                                reprehenderit doloribus sed aliquid pariatur sit cupiditate. Quidem dicta quod a eum
                                maiores nesciunt, tenetur sequi repellendus fugiat sint!10</h5>
                        </div>
                    </div>
                     <div class="row top-forme justify-content-center mt-4 ">
                        <div class="col-md-10 bg-white message-style p-5 parent shadow">
                            <div class="text-center" >
                                <span class="child">13/12/2022</span>
                            </div>
                            <h5>Elghanemy saad</h5>
                            <h5>s.elghanemy@msafar.ma</h5>
                            <h5>Object</h5>
                            <h5>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam perspiciatis eum
                                reprehenderit doloribus sed aliquid pariatur sit cupiditate. Quidem dicta quod a eum
                                maiores nesciunt, tenetur sequi repellendus fugiat sint!10</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProfilSidebar from "@/components/MonProfil/ProfilSidebar";
export default {
    name: "MesMessages",
    components: {
        ProfilSidebar,
    },
}
</script>

<style>
.message-style {
    border: 2px solid rgb(152, 26, 29);
}

.parent {
    position: relative;
    width: 200px;

}

.child {
    position: absolute;
    top: 0;
    width: 25%;
    text-align: center;
    color: white;
    height: 27px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: rgb(152, 26, 29);
}
</style>