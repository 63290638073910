export default {
  addStructuredData() {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Msafar.ma",
      url: "https://msafar.ma",
      logo: "https://msafar.ma/backend/storage/images/logo.png",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+212 (0) 522 22 85 27",
        contactType: "Customer Service",
        areaServed: [
          {
            "@type": "Place",
            name: "Morocco",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "159 Bd Yacoub El Mansour - Entrée B - 1er étage Bureau n°3",
              addressLocality: "Casablanca",
              postalCode: "20380",
              addressCountry: {
                "@type": "Country",
                name: "Morocco",
              },
            },
          },
          {
            "@type": "Language",
            name: ["Arabic", "French", "English"],
          },
        ],
        availableLanguage: ["Arabic", "French"],
      },
      sameAs: [
        "https://www.facebook.com/msafar.ma",
        "https://www.linkedin.com/company/msafar-ma",
        "https://www.instagram.com/msafar.ma/",
        "https://www.tiktok.com/@msafar.ma",
        "https://www.youtube.com/channel/UCUjQzHQ9Q3lc7aLPOiVvKLw/featured",
      ],
    });
    document.head.appendChild(script);
  },
};
