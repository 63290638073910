<template>

  <div class="container min-height">
    <Rubrique></Rubrique>
    <div class="row justify-content-center mt-5">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <h1 class="rtl-title" style="display: inline-block"><img
                style="margin-top: -15px;margin-right: 15px;width: 55px;" src="@/assets/images/img-bladi.png" />{{
                    $t('rubrique.gastronomie')
                }}</h1>
            <router-link to="/gastronomies/">
              <b-icon-arrow-right v-if="this.$i18n.locale == 'العربية'" class="arrow-left float-left color-primary">
              </b-icon-arrow-right>
              <b-icon-arrow-left v-else class="arrow-left float-right color-primary"></b-icon-arrow-left>
            </router-link>
          </div>
        </div>
        <!-- <div class="row search">
          <div class="col-md-12">
            <div class="form-group">
              <input class="form-control form-control-sm " type="text"  style="font-family: 'Lato', cursive;padding-left: 10px" placeholder="Recherche par mots clés...">
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row top-forme justify-content-center">
      <div class="col-md-10 bg-white p-5">
        <div class="mb-4 text-center">
          <img class="max-width-100" style="" v-bind:src="BaseUrl + '/storage/' + lieu.image" />
        </div>
        <h1 v-if="$i18n.locale === 'Français'">{{ lieu.titre ??  lieu.titre_ar }}</h1>
        <h1 v-else-if="$i18n.locale === 'English'">{{ lieu.titre_en ??  lieu.titre }}</h1>
        <h1 v-else>{{ lieu.titre_ar ??  lieu.titre}}</h1>
        <p  v-if="$i18n.locale === 'Français'">{{lieu.description ?? lieu.description_ar }}</p>
        <p  v-else-if="$i18n.locale === 'English'">{{lieu.description_en ?? lieu.description }}</p>
        <p  v-else>{{lieu.description_ar ?? lieu.description }}</p>
        <!-- <b-button variant="outline-info" @click="myFilter" :class="{ active: isActive }" class="mb-4">
          <b-icon icon="suit-heart"></b-icon> J'aime {{ count }}
        </b-button> -->
        <CommentairePartage :commentaires="commentaires" :errors="errors" :gastroId="lieu.id"
          @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)" @DeleteComment="supComment($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import CommentairePartage from "./EspaceProfessionnel/CommentairePartage.vue";

import seo from "../seo-sevices.js";
import  $i18n  from '../i18n.js';
export default {
  metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.lieu,this.images);
    }
    else{
      return seo.metaInfo_ar(this.lieu,this.images);
    }
  },
  name: "GastromoniesView",
  components: { Rubrique, CommentairePartage },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      lieu: [],
      items: [],
      images: [],
      errors: [],
      user_id: localStorage.getItem('id'),
      count: 2,
      isActive: false,
      commentaires: [],
      //items:this.hotels,
      perPage: 2,
      currentPage: 1
    }
  },
  mounted() {
    const id = this.id;
    //console.log(id);
    http.get(`/gastronomies/${id}`)
      .then((response) => {
        console.warn(response.data.data);
        this.lieu = response.data
        this.commentaires = response.data.commentaires
        let userId = this.user_id;
        this.commentaires = this.commentaires.map(function (field) {
          return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
        })
        console.log(response.data.commentaires)
        /*.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
        )*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    myFilter: function () {
      if (!this.isActive) {
        this.isActive = !this.isActive;
        this.count += 1;
      } else {
        this.isActive = !this.isActive;
        this.count -= 1;
      }
    },
    ajtComment(event) {
      http.post('/commentpartage', event)
        .then(() => {
          this.getCommentaire();
          this.$toaster.success('Votre commentaire a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    mdfComment(event) {
      http.post(`/commentpartage/${event.id}`, event).then(() => {
        this.getCommentaire();
        this.$toaster.error(this.$t('toaster.commentaires.modifier'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http.delete(`/commentpartage/${event.id}`).then(() => {
        this.getCommentaire();
        this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },
    getCommentaire() {
      const id = this.id;
      http.get(`/gastronomies/${id}`)
        .then((response) => {
          this.commentaires = response.data.commentaires
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    }
  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
/*
export default {
data () {
return {
currentPage: 1,
perPage: 5,
}
},
computed: {
lists () {
const items = this.$store.getters.loadedLists
// Return just page of items needed
return items.slice(
(this.currentPage - 1) * this.perPage,
this.currentPage * this.perPage
)
},
totalRows () {
return this.$store.getters.loadedLists.length
}
}
}
*/
</script>

<style scoped>

</style>