<template>
  <div class="container-fluid">
    <!-- <Rubrique></Rubrique> -->
    <PaginationTitle :article="hotel" class="mt-5"></PaginationTitle>
    <div class="container">
      <div class="row">
        <div class="col-md-9 search">
          <div class="row mt-5">
            <div class="col-md-12 py-3">
              <carousel
                :starting-image="2"
                :images="images"
                :activite_id="hotel.id"
                @AddFavorite="ajtFavorite($event)"
                :auto-slide-interval="5000"
                :show-progress-bar="false"
              >
              </carousel>
              <Information :hotel="hotel" :totalEtoiles="totalEtoiles" />
              <Actvites
                :actvites="actvites"
                :slug="path"
                :BaseUrl="BaseUrl"
                :id_activite="hotel.id"
              ></Actvites>
              <!-- <div class="card-body">
                                <table class="table table-striped" v-if="items.length > 0">
                                    <thead>
                                        <tr style="color: white" class="text-center">
                                            <th scope="col"
                                                style="background-color: #b90000;border-top-left-radius: 25px;">
                                                Activité
                                            </th>
                                            <th scope="col" style="background-color: #274f47;">Détails</th>
                                            <th scope="col" style="background-color: #de6c0b;">
                                                Prix à partir de</th>
                                            <th scope="col"
                                                style="background-color: #00acc9;border-top-right-radius: 25px;">
                                                Programme</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="col-md-6 py-3" v-for="type_activite of items"
                                            v-bind:key="type_activite.id">
                                            <td v-html="type_activite.activite">
                                            </td>
                                            <td>{{ type_activite.duree }}</td>
                                            <td class="text-center">{{ type_activite.prix_actv }} dhs</td>
                                            <td>
                                                <a v-if="type_activite.programme"
                                                    :href="'https://msafar.ma/backend/public/storage/' + type_activite.programme"
                                                    target="_blank">
                                                    Afficher le programme
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
              <!-- <div class="col-md-12 mb-4">
                                <div v-if="hotel.programme">
                                    <a :href="'https://msafar.ma/backend/public/storage/' + hotel.programme"
                                        target="_blank">Afficher le programme </a>
                                </div>
                            </div> -->
              <SocialMedia :hotel="hotel" />
              <div class="col-md-12 mt-3 commentaires">
                <Commentaire
                  :commentaires="commentaires"
                  :map="hotel.map"
                  :errors="errors"
                  :activite_id="hotel.id"
                  @AddComment="ajtComment($event)"
                  @UpdateComment="mdfComment($event)"
                  @DeleteComment="supComment($event)"
                />
              </div>
            </div>
            <ReservtionModal
              title="activités touristiques"
              :activite_id="hotel.id"
              :errors="errors"
              :owner_email="computedOwnerEmail"
              @Reserver="reserverActivite($event)"
            />
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import http from "./../../http-common";
import Carousel from "./../Outils/Carousel.vue";
import Actvites from "./Activites.vue";
// import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ReservtionModal from "./CardComponents/ReservtionModal.vue";
import Information from "./CardComponents/Information.vue";
import SocialMedia from "./CardComponents/SocialMedia.vue";
import Commentaire from "./CardComponents/Commentaire.vue";
import PaginationTitle from "./PaginationTitle.vue";
import seo from "../../seo.js";
import $i18n from "../../i18n.js";
export default {
  metaInfo() {
    if ($i18n.locale == "Français" || $i18n.locale == "English") {
      return seo.metaInfo_fr(this.hotel, this.images);
    } else {
      return seo.metaInfo_ar(this.hotel, this.images);
    }
  },
  //     metaInfo() {
  //     return {
  //       title: this.hotel.nom,
  //       meta: [
  //         {
  //           name: "description",
  //           content: this.hotel.description ? this.hotel.description.substring(0, 155)+'...':'',
  //         },
  //         {
  //         property: "og:title",
  //         content: this.hotel.titre,
  //         },
  //         {
  //           property: "og:description",
  //           content: this.hotel.description ? this.hotel.description.substring(0, 155)+'...':'',
  //         },
  //         {
  //           property: "og:image",
  //           content: this.BaseUrl + '/storage/' + this.images[0],
  //         },
  //       ],
  //     };
  //   },
  name: "HotelViewComponent",
  components: {
    Carousel,
    Actvites,
    /*Rubrique,*/ Sidebar,
    ReservtionModal,
    Information,
    SocialMedia,
    Commentaire,
    PaginationTitle,
  },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      fields: [
        "Type de Chambre",
        "Nombre de personne",
        "Options",
        "Prix à partie de",
      ],
      activite: [],
      user_id: localStorage.getItem("id"),
      items: [],
      commentaires: [],
      images: [],
      errors: [],
      hotel: [],
      //items:this.hotels,
      perPage: 2,
      currentPage: 1,
      path: "activites-touristiques",
      actvites:[],
    };
  },
  mounted() {

    const id = this.id;
    // console.log(id);
    http
      .get(`/activites/${id}`)
      .then((response) => {
        var listT = [];
        this.hotel = response.data;
        // console.log('test1')
        // console.log(this.hotel);
        this.GetActivite(this.hotel.id);
        response.data.images.map(function (value) {
          listT.push(value.image);
        });
        if (this.hotel.image && this.hotel.image !== "") {
          listT.push(this.hotel.image);
        }
        this.images = listT;
        this.items = this.hotel.type_activite;
        this.commentaires = this.hotel.commentaires;
        let userId = this.user_id;
        this.commentaires = this.commentaires.map(function (field) {
          return field.user.id === parseInt(userId)
            ? { ...field, exists: true }
            : { ...field, exists: false };
        });
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    GetActivite(id) {
      http
        .get("/type-activites/activite/"+id)
        .then((response) => {
          this.actvites = response.data;
          console.log(this.actvites);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    ajtFavorite(event) {
      http
        .post("/favorites", event)
        .then(() => {
          this.$toaster.success(
            "Votre activités touristiques a été enregistré."
          );
        })
        .catch((e) => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        });
    },
    ajtComment(event) {
      http
        .post("/commentaires", event)
        .then(() => {
          this.getCommentaires();
          this.$toaster.success("Votre commentaire a été enregistré.");
        })
        .catch((e) => {
          this.$toaster.error(e.response);
        });
    },
    mdfComment(event) {
      http
        .post(`/commentaires/${event.id}`, event)
        .then(() => {
          this.getCommentaires();
          this.$toaster.error(this.$t("toaster.commentaires.modifier"));
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http
        .delete(`/commentaires/${event.id}`)
        .then(() => {
          this.getCommentaires();
          this.$toaster.error(this.$t("toaster.commentaires.supprimer"));
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    getCommentaires() {
      const id = this.id;
      http
        .get(`/activites/${id}`)
        .then((response) => {
          this.commentaires = response.data.commentaires;
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId)
              ? { ...field, exists: true }
              : { ...field, exists: false };
          });
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    async reserverActivite(event) {
      await http
        .post("/reservations", event)
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modal-reservation");
          this.$toaster.error("Votre Bien a été réserver.");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    getResteOfImageNumbre() {
      return this.images.length > 4 ? " + " + (this.images.length - 4) : "  ";
    },
  },
  computed: {
    computedOwnerEmail() {
      if (this.hotel.user && this.hotel.user.email) {
        return this.hotel.user.email;
      } else if (this.hotel.email) {
        return this.hotel.email;
      } else {
        return "contact@msafar.ma";
      }
    },
    lists() {
      //const options = this.items
      // Return just page of items needed options ,nbr_personnes
      let list = [];
      /*this.items.map(function(value) {
              list.push([value['type'],value['nbr_personnes'],value['options'],value['prix']]);
            });*/
      return list;
    },
    totalEtoiles() {
      return this.activite.nbr_etoiles > 5 ? 5 : this.activite.nbr_etoiles;
    },
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id;
    },
  },
};
</script>
<style>
.hotels .card{
  min-height: 350px !important;
}
</style>