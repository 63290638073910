<template>
  <div class="container-fluid ">
    <!-- <Rubrique></Rubrique> -->
    <PaginationTitle :article="hotel" class="mt-5"></PaginationTitle>
    <div class="container">
      <div class="row ">
        <div class="col-md-9 search">
          <div class="row mt-5 ">
            <div class="col-md-12 py-3">
              <carousel :starting-image="2" :images="images" :maison_id="hotel.id" @AddFavorite="ajtFavorite($event)"
                :auto-slide-interval="500000000" :show-progress-bar="false">
              </carousel>
              <div class="card-body">
                <Information :hotel="hotel" :totalEtoiles="totalEtoiles" />
                <Chambre :items="items" />
              </div>
              <SocialMedia :hotel="hotel" />
              <Service v-if="services.length" :services="services" />
              <Commentaire :commentaires="commentaires"  :map="hotel.map" :maison_id="hotel.id" :errors="errors"
                @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)"
                @DeleteComment="supComment($event)" />
            </div>
            <ReservtionModal title="maison d'hôtes" :maison_id="hotel.id" :errors="errors"
              v-if="hotel.user && hotel.user.email" :owner_email="computedOwnerEmail" @Reserver="reserverMaison($event)" />
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>
</template>

<script>


import http from "./../../http-common";
import Carousel from './../Outils/Carousel.vue'
// import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ReservtionModal from "./CardComponents/ReservtionModal.vue";
import Chambre from "./CardComponents/Chambre.vue";
import Information from "./CardComponents/Information.vue";
import SocialMedia from "./CardComponents/SocialMedia.vue";
import Commentaire from "./CardComponents/Commentaire.vue";
import Service from "./CardComponents/Service.vue";
import PaginationTitle from "./PaginationTitle.vue";
import seo from "../../seo.js";
import  $i18n  from '../../i18n.js';
export default {
    metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.hotel,this.images);
    }
    else{
      return seo.metaInfo_ar(this.hotel,this.images);
    }
  },
  name: "MaisonHoteViewComponent",
  components: { Carousel, /*Rubrique,*/ Sidebar, ReservtionModal, Chambre, Information, SocialMedia, Commentaire, Service,PaginationTitle },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      fields: ['Type de Chambre', 'Nombre de personne', 'Options', 'Prix à partie de'],
      hotel: [],
      items: [],
      images: [],
      errors: [],
      services: [],
      commentaires: [],
      //items:this.hotels,
      user_id: localStorage.getItem('id'),
      perPage: 2,
      currentPage: 1
    }
  },
  mounted() {
    const id = this.id;
    //console.log(id);
    http.get(`/maisons/${id}`)
      .then((response) => {
        var listT = [];
        this.hotel = response.data
        response.data.images.map(function (value) {
          listT.push(value.image);
        });
        // listT.push(this.hotel.image);
        console.log(this.hotel.image);
        this.images = listT;
        this.items = this.hotel.chambres;
        this.commentaires = this.hotel.commentaires;
        let userId = this.user_id;
        this.commentaires = this.commentaires.map(function (field) {
          return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
        })
        this.services = this.hotel.hebergement_service;
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    async reserverMaison(event) {
      await http.post('/reservations', event)
        .then(() => {
          this.$root.$emit('bv::hide::modal', 'modal-reservation');
          this.$toaster.error('Votre Bien a été réserver.')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });

    },
    getResteOfImageNumbre() {
      return this.images.length > 4 ? ' + ' + (this.images.length - 4) : '  ';
    },
    ajtFavorite(event) {
      http.post('/favorites', event)
        .then(() => {
          this.$toaster.success('Votre maison D\'hôtes a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    ajtComment(event) {
      http.post('/commentaires', event)
        .then(() => {
          this.getCommentaires();
          this.$toaster.success('Votre commentaire a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    mdfComment(event) {
      http.post(`/commentaires/${event.id}`, event).then(() => {
        this.getCommentaires();
        this.$toaster.error(this.$t('toaster.commentaires.modifier'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http.delete(`/commentaires/${event.id}`).then(() => {
        this.getCommentaires();
        this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },
    getCommentaires() {
      const id = this.id;
      http.get(`/maisons/${id}`)
        .then((response) => {
          this.commentaires = response.data.commentaires
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
  },
  computed: {
    computedOwnerEmail() {
      if (this.hotel.user && this.hotel.user.email) {
        return this.hotel.user.email;
      } else if (this.hotel.email) {
        return this.hotel.email;
      } else {
        return 'contact@msafar.ma';
      }
    },
    lists() {
      //const options = this.items
      // Return just page of items needed options ,nbr_personnes
      let list = [];

      /*this.items.map(function(value) {
        list.push([value['type'],value['nbr_personnes'],value['options'],value['prix']]);
      });*/
      return list
    },
    totalEtoiles() {
      return this.hotel.nbr_etoiles > 5 ? 5 : this.hotel.nbr_etoiles
    },
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id

    }
  }
}
/*
export default {
  data () {
    return {
      currentPage: 1,
      perPage: 5,
    }
  },
  computed: {
    lists () {
      const items = this.$store.getters.loadedLists
      // Return just page of items needed
      return items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      )
    },
    totalRows () {
      return this.$store.getters.loadedLists.length
    }
  }
}
 */
</script>

