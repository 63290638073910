<template>
    <div class="container" v-if="rubrique == null">
        <div class="row">
            <div v-if='$i18n.locale == "Français"  || $i18n.locale == "English"' class="col-md-12 paginate">
                <router-link to="/">Accueil</router-link> > <router-link to="/services">{{ $t('navbar.services') }}</router-link> > <router-link :to="'/'+article.url">{{ $t(`rubrique.${article.url}`) }}</router-link> > <span @click="searchByVille()">{{ article.ville.ville }}</span> > <span  class="active-paginate">{{ article.nom }}</span>
            </div>
            <div v-else class="col-md-12 paginate" style="text-align: right;padding-right: 68px;">
                <router-link to="/">الصفحة الرئيسية</router-link> > <router-link to="/services">{{ $t('navbar.services') }}</router-link> > <router-link :to="'/'+article.url">{{ $t(`rubrique.${article.url}`) }}</router-link> > <span @click="searchByVille()">{{ article.ville.ville_ar }}</span> > <span  class="active-paginate">{{ article.nom_ar }}</span>
            </div>
        </div>
    </div>
    <div class="container" v-else>
        <div class="row">
            <div v-if='$i18n.locale == "Français"  || $i18n.locale == "English"'  class="col-md-12 paginate" >
                <router-link to="/">Accueil</router-link> > <router-link to="/services">{{ $t('navbar.services') }}</router-link> ><span @click="removeville()">{{ $t(`rubrique.${rubrique}`) }}</span>
            </div>
            <div v-else class="col-md-12 paginate" style="text-align: right;padding-right: 68px;">
                <router-link to="/">الصفحة الرئيسية</router-link> > <router-link to="/services">{{ $t('navbar.services') }}</router-link> > <span @click="removeville()">{{ $t(`rubrique.${rubrique}`) }}</span>
            </div>
        </div>
    </div>
</template>

  <script>
  export default {
    name: "PaginationTitle",
    props: {
        article : [],
        rubrique: String,
    },
    methods: {
        searchByVille() {
        this.$router.push({ name: this.article.url, params: { ville: this.article.ville.id } });
        },
        removeville() {
            this.$emit('remove-ville');
        }
    }
  };
  </script>
<style scoped>
    .paginate{
        background-color: #e6d6bc;
        border: 4px solid #d7d5cc;
        color: #235b7d;
        padding-left: 68px;
        border-radius: 32px;
        cursor: pointer;
    }.active-paginate{
        color: #796720;
    }
    a,a:hover{
        color: #235b7d;
        border: none;
    }
    span:hover{
        border-bottom: 2px solid;
    }
</style>