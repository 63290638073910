<template>

  <div class="col-md-3 sidebar">

    <div class="row mt-5 p-2 justify-content-center text-center">

      <div class="col-md-8 col-6">
        <div class="card p-2" style="width: 18rem;">
          <router-link to="/lieux-a-visiter" class="" exact>
            <img class="card-img-top" src="@/assets/images/lieux-a-visiter.png" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-center">{{$t('rubrique.lieux_visiter')}}</h5>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-md-8 col-6">
        <div class="card p-2" style="width: 18rem;">
          <router-link to="/gastronomies" class="" exact>
            <img class="card-img-top" src="@/assets/images/gastronomie.png" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-center">{{$t('rubrique.gastronomie')}}</h5>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-md-8 col-6">
        <div class="card p-2" style="width: 18rem;">
          <router-link to="/evenements" class="" exact>
            <img class="card-img-top" src="@/assets/images/evenement.png" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-center">{{$t('rubrique.evenements')}}</h5>
            </div>
          </router-link>
        </div>
      </div>

      <!-- <div class="col-md-8">
        <div class="card p-2" style="width: 18rem;">
          <img class="card-img-top" src="@/assets/images/tem.png" alt="Card image cap">
          <div class="card-body">
            <router-link to="/temoignages" class=""><h5 class="card-title text-center">Témoignages</h5></router-link>
          </div>
        </div>
      </div> -->

      <div class="col-md-8 col-6">
        <div class="card p-2" style="width: 18rem;">
          <router-link to="/partages" class="">
            <img class="card-img-top" src="@/assets/images/com.png" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-center">{{$t('rubrique.partages')}}</h5>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-md-8 col-6">
        <div class="card p-2" style="width: 18rem;">
          <router-link to="/actualites" class="">
            <img class="card-img-top" src="@/assets/images/evenements.png" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-center">{{$t('rubrique.actualites')}}</h5>
            </div>
          </router-link>
        </div>
      </div>


    </div>


  </div>

</template>

<script>
export default {
  name: "SidebarComponent"
}
</script>

<style scoped>
a{
  text-decoration: none!important;
}
</style>