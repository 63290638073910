<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-5">
                <div class="container rubriques m-50">
                    <div class="row justify-content-center">
                        <div class="col-md-12 mb-4 profil-section">
                            <div class="text-center d-flex justify-content-center mt-4 pb-2">
                                <div class="profilepic text-center">
                                    <img v-if="preview" :src="preview" class="profilepic__image" width="150"
                                        height="150" alt="Profil image" />
                                    <img v-else class="profilepic__image"
                                        v-bind:src="`${BaseUrl}/storage/${user.avatar ? user.avatar : 'images/profil-pic.png'}`"
                                        width="150" height="150" alt="Profibild" />
                                    <label for="file-input">
                                        <div class="profilepic__content">
                                            <b-icon icon="camera" font-scale="1.5"></b-icon>
                                            <span class="profilepic__text">{{ user.avatar ? $t('espace_voyageur.mdf_img') :
                                                    $t('espace_voyageur.ajt_img')
                                            }}</span>
                                            <input style="display:none" accept="image/*" name="avatar" id="file-input"
                                                ref="file" type="file" @change="onFileChange" />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <h4 class="text-center mb-5">{{ user.name }}</h4>
                        </div>
                        <div class="col-md-2 mb-4 col-6">
                            <div class="inform">
                                <router-link class="btn btn-information" :to="{ name: 'CompteInfo' }" exact>
                                    <span v-html="$t('espace_voyageur.infos_compte')"></span>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-2 mb-4 col-6">
                            <div class="notif">
                                <router-link class="btn btn-notif" :to="{ name: 'Notification' }" exact> {{
                                        $t('espace_voyageur.notifications')
                                }}
                                </router-link>
                            </div>
                        </div>
                        <!--
                        <router-link class="btn btn-block" to="/mon-compte/mes-messages" exact>Mes messages
                        </router-link> !-->
                        <div class="col-md-2 mb-4 col-6">
                            <div class="partage">
                                <router-link class="btn btn-partage" :to="{ name: 'MesPartages' }" exact> {{
                                        $t('espace_voyageur.mes_partage')
                                }}
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-2 mb-4 col-6">
                            <div class="mdp">
                                <router-link class="btn btn-mdp" :to="{ name: 'ChangerMotDePasse' }">
                                    <span v-html="$t('espace_voyageur.changer_mon_mdp')"></span>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-2 mb-4 col-6">
                            <div class="favoris">
                                <router-link class="btn  btn-favoris" :to="{ name: 'Mesfavoris' }">
                                    <span v-html="$t('espace_voyageur.mes_favoris')"></span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <router-view />
        </div>
    </div>


</template>

<script>
import User from "@/apis/User";
import Api from "@/apis/Api";
export default {
    name: "SidebarComponent",
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            isLoggedIn: false,
            preview: '',
            avatar: '',
            user: [],
        };
    },
    mounted() {
        // window.scrollTo(0,0);
        console.log(process.env.VUE_APP_API_URL);
        this.$root.$on("login", () => {
            this.isLoggedIn = true;
        });
        this.isLoggedIn = !!localStorage.getItem('token');
    },
    methods: {
        getUser() {
            User.auth().then(response => {
                this.user = response.data;
            }).catch((errors) => {
                console.log(errors)
            });
        },
        async onFileChange(e) {
            const file = e.target.files[0];
            this.preview = URL.createObjectURL(file);
            this.avatar = e.target.files[0];
            let formData = new FormData;
            formData.set('avatar', this.avatar);
            await Api().post(`/users/${this.user.id}`, formData).then(() => {
                this.$toaster.success('Votre photo de profil a été mise à jour.')
                console.log('location is in ' + formData)
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
            });
        },
    },
    created() {
        this.getUser()
    },
}

</script>

<style scoped>
a {
    font-size: 20px;
    line-height: 1.1;
}

.btn-information {
    background: white;
    display: inline-block;
    border: 3px solid #ff8601;
    text-align: center;
    width: 149px;
    height: 149px;
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ff8601;
    text-decoration: none
}


.btn-information:hover {
    background: #ff8601;
    color: white;

}

.rubriques .col-md-2 mb-4 div.inform .router-link-active {
    background: #ff8601;
    color: white !important;
}

.btn-notif {
    background: white;
    display: inline-block;
    border: 3px solid #018ec6;
    text-align: center;
    width: 149px;
    height: 149px;
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #018ec6;
    text-decoration: none
}

.btn-notif:hover {
    background: #018ec6;
    color: white;
}

.rubriques .col-md-2 mb-4 div.notif .router-link-active {
    background: #018ec6;
    color: white !important;
}

.btn-partage {
    background: white;
    display: inline-block;
    border: 3px solid #cd001f;
    text-align: center;
    width: 149px;
    height: 149px;
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #cd001f;
    text-decoration: none
}

.btn-partage:hover {
    background: #cd001f;
    color: white;
}

.rubriques .col-md-2 mb-4 div.partage .router-link-active {
    background: #cd001f;
    color: white !important;
}

.btn-mdp {
    background: white;
    display: inline-block;
    border: 3px solid #1f5b4e;
    text-align: center;
    width: 149px;
    height: 149px;
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1f5b4e;
    text-decoration: none
}

.btn-mdp:hover {
    background: #1f5b4e;
    color: white;
}


.btn-favoris {
    background: white;
    display: inline-block;
    border: 3px solid #003fbc;
    text-align: center;
    width: 149px;
    height: 149px;
    border-radius: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #003fbc;
    text-decoration: none
}

.btn-favoris:hover {
    background: #003fbc;
    color: white;
}
.rubriques .col-md-2 mb-4 div.mdp .router-link-active {
    background: #1f5b4e;
    color: white !important;
}

.rubriques .col-md-2 mb-4 div.favoris .router-link-active {
    background: #003fbc;
    color: white !important;
}

.profil-section {
    background: #daeef7;
    border-radius: 35px;
}

.profil-section h4 {
    color: #3695b1;

}

.profilepic {
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #111;
}

.profilepic:hover .profilepic__content {
    opacity: 1;
}

.profilepic:hover .profilepic__image {
    opacity: .5;
}

.profilepic__image {
    object-fit: cover;
    opacity: 1;
    transition: opacity .2s ease-in-out;
    height: 172px;
}

.rtl .profilepic__image {
    margin-right: -25px;
}

.profilepic__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity .2s ease-in-out;
}

.profilepic__icon {
    color: white;
    padding-bottom: 8px;
}

.fas {
    font-size: 20px;
}

.profilepic__text {
    text-transform: uppercase;
    font-size: 12px;
    width: 50%;
    text-align: center;
}
</style>