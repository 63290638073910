<template>
    <div class="col-md-12">
        <div class="col-md-12 line mb-5"></div>
        <div class="row top-forme justify-content-center">
            
                <template v-if="partages && partages.length > 0">
                    <div class="col-md-10 bg-white ">
                    <template  v-for='partage in partages'>
                        <b-alert show variant="secondary" class="custom-alert" v-for="notification in partage.commentpartage" :key="notification.id">
                            <b-img blank blank-color="#01c203" class="mr-2" rounded="circle" width="12" height="12">
                            </b-img>
                        <span v-if="notification.user">{{ `${notification.user.nom}  ${notification.user.prenom}` }}</span> a ajouté un avis sur votre partage <router-link :to="`/partages/${partage.slug}`" >{{ partage.titre }}.</router-link>
                        </b-alert>
                    </template>
                    </div>
             </template>
             <template v-else>
                 <div class="col-md-12 bg-white ">
                    <div class="alert ">
                        <h3>Vous n'avez aucune notification pour le moment.</h3>
                    </div>
                  </div>
             </template>
            
        </div>
    </div>

</template>

<script>

import Api from "@/apis/Api";


export default {
    name: "mesNotification",
    components: {
            
    },
    data(){
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            commentairesPartages : [],
            partages: [],
        }
    },
    methods:{
         getCommentairePartagesByUser() {
            const id = localStorage.getItem("id");
            Api().get(`/commentpartage/user/${id}`).then(response => {
                this.commentairesPartages = response.data.partages;
                console.log(this.commentairesPartages)

            }).catch((errors) => {
                console.log(errors)
            });
        },
         getUserPartages() {
            const id = localStorage.getItem("id");
            Api().get(`/partages/user/${id}`).then(response => {
                this.partages = response.data.partages;               
                console.log(this.partages)

            }).catch((errors) => {
                console.log(errors)
            });
        },
    },
     created() {
        this.getUserPartages();
    },
}
</script>

<style scoped>
.custom-alert {
    background-color: #c6f0fc;
    padding: 25px;
    border: 1px;
    border-radius: 18px;
}

.line {

    border-bottom: 5px solid #018ec6;

}
</style>