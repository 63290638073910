import { render, staticRenderFns } from "./AddPartage.vue?vue&type=template&id=3b73e2bc&scoped=true&"
import script from "./AddPartage.vue?vue&type=script&lang=js&"
export * from "./AddPartage.vue?vue&type=script&lang=js&"
import style0 from "./AddPartage.vue?vue&type=style&index=0&id=3b73e2bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b73e2bc",
  null
  
)

export default component.exports