<template>
  <div class="container-fluid">
    <div class="container ">
      <div class="row">
        <div class="col-md-12 page-404" style="padding-top: 100px">
            <div class="text-center">
                    <h1 class="number"> 404</h1>
                    <h1 class="title"> Page introuvable</h1>
             </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "404Component",
  components: {  }
}
</script>

<style scoped>

.page-404 title{

    font-size: 80px;
    
}
.page-404 .number{

    font-size: 180px;
    
}

</style>