<template>
    <div class="container-fluid ">
      <PaginationTitle :rubrique="path" class="mt-5" @remove-ville="RemoveVille"></PaginationTitle>
      <!-- <Rubrique /> -->
      <div class="container">
        <div class="row mt-5 pt-5">
          <div class="col-md-9 search" id="scroll">
            <h1 class=" mb-5"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                src="@/assets/images/img-bladi.png" />
              {{ $t('rubrique.apparthot') }}
            </h1>
            <div class="row dir-rtl">
              <div class="col-md-6">
                <div class="form-group">
                  <input class="form-control form-control-sm " v-model="search" type="text"
                    :placeholder="$t('recherche.mot_cles')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control form-control-sm  ville" v-model="ville">
                    <option value="">{{ $t('recherche.villes') }}</option>
                    <option v-for="ville of villes" :key="ville.id" :value="ville.id">
                    {{  $i18n.locale === "Français"  || $i18n.locale == "English" ? ville.ville : ville.ville_ar }}
                  </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row dir-rtl">
              <div class="col-md-6">
                <div class="form-group mt-4">
                  <vue-range-slider v-model="value" :min="min" :max="max" :formatter="formatter"></vue-range-slider>
                </div>
              </div>

              <div class="col-md-6">
                <button class="btn btn-block btn-info rounded-pill" v-on:click="lists()">
                  <b-icon-search></b-icon-search> {{ $t('recherche.rechercher') }}
                </button>
              </div>
            </div>
            <div class="row hotels riads">
              <!-- <div class="col-md-6 py-3" v-for="(hotel, index) of hotels" v-bind:key="index">
                <router-link v-bind:to="'/apparthotels/' + hotel.slug">
                  <div class="card" style="">
                    <img v-if="hotel.images.length && hotel.images[0].image"
                      v-bind:src="BaseUrl + '/storage/' + hotel.images[0].image" class="card-img-top responsive-img" />
                    <div class="card-body">
                      <h5 class="card-title" v-if="hotel.nom">{{ hotel.nom }}</h5>
                      <p class="card-text"><img src="@/assets/images/map-marker.png" class="map-marker" />
                        <span v-if="hotel.adresse">{{ hotel.adresse }}</span>
                      </p>
                      <p class="card-description" v-if="hotel.description"
                        v-html="hotel.description.substring(0, 230) + '..'"></p>
                      <p class="prix" v-if="hotel.prix">{{ $t('details_service.prix', [hotel.prix]) }}</p>
                      <div class="phone">
                        <div class="icon" v-if="hotel.whatsapp"><i class='fab fa-whatsapp fa-xl'></i> <span>{{ hotel.whatsapp
                        }}</span></div>
                        <div class="icon" v-else ><i class='fa fa-phone fa-l'></i> <span>{{ hotel.tel ? hotel.tel : ''
                        }}</span></div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div> -->
              <Services_s :filteredItemsWithImages="lists" :slug="path" :BaseUrl="BaseUrl" />
              <Suggestion v-if="suggestion" :hotels="hotels" />
              <div class="col-md-12 ">
                <!-- d-flex justify-content-center -->
                <div class="overflow-auto d-flex justify-content-center pt-5">
                  <b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill">
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
          <Sidebar></Sidebar>
        </div>
      </div>
    </div>
  </template>

  <script>


  import http from "../../http-common";
  // import Rubrique from "@/components/Outils/Rubrique";
  import Sidebar from "@/components/Outils/Sidebar";
  import Suggestion from "./CardComponents/Suggestion.vue";
  import VueRangeSlider from 'vue-range-component'
  import Services_s from "@/components/Hebergements/services.vue";
  import  $i18n  from '../../i18n.js';
  import PaginationTitle from "./PaginationTitle.vue";

  export default {
    metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return {
        title: 'Appart-Hôtels',
        meta: [
          {
            name: "description",
            content: "Msafar vous propose des adresses d'Appart-Hôtels dans différentes villes : Martil, Agadir, Marrakech et Tanger.",
          },
        ],
      };
    }
    else{
      return {
        title: 'Appart-Hôtels',
        meta: [
          {
            name: "description",
            content: "Msafar vous propose des adresses d'Appart-Hôtels dans différentes villes : Martil, Agadir, Marrakech et Tanger.",
          },
        ],
      };
    }
    },
    name: "RiadsComponent",
    components: {/*Rubrique,*/PaginationTitle, Sidebar, Suggestion, VueRangeSlider,Services_s },
    data() {
      return {
        BaseUrl: process.env.VUE_APP_API_URL,
        hotels: [],
        filteredItems: [],
        villes: [],
        errors: [],
        search: '',
        suggestion: false,
        ville: '',
        prixMin: '',
        prixMax: '',
        //items:this.hotels,
        perPage: 6,
        currentPage: 1,
        value: [0, 3000],
        path: 'apparthotels',
        totalRows : 0,
      }
    },
    created() {
      this.min = 0
      this.max = 3000
      this.formatter = value => `${value} DH`
      this.ville=this.$route.params.ville || '';
    },
    mounted() {
      // get liste des hotels
      http.get('/appart-hotels')
        .then((response) => {

          this.hotels = response.data
          this.filteredItems = response.data
          if (this.$route.query.ville)
            this.filteredItems = this.filteredItems
              .filter(post => { return post.ville.id == this.$route.query.ville })
          if (this.filteredItems.length == 0) {
            this.suggestion = true
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
      // get liste des villes
      http.get('/villes')
        .then((response) => {
          this.villes = response.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    methods: {
      RemoveVille() {
        this.ville = '';
      },
      handlePageChange(value) {
        this.page = value;
      },
      filteredList() {
        this.prixMin = this.value[0];
        this.prixMax = this.value[1];
        console.log(this.prixMin + " - " + this.prixMax);
        const items = this.hotels
          .filter(post => { return post.nom.toLowerCase().includes(this.search.toLowerCase()) })
          .filter(post => { return this.ville != ''  &&  post.ville ? post.ville.id == this.ville : true })
          .filter(post => {
            var price = false
            if (parseInt(this.prixMin) <= parseInt(post.prix_min) && parseInt(this.prixMax) >= parseInt(post.prix_min))
              price = true;
            else if (parseInt(this.prixMin) <= parseInt(post.prix_max) && parseInt(this.prixMax) >= parseInt(post.prix_max))
              price = true;
            return price;
          })
        this.filteredItems = items;
        if (this.filteredItems.length == 0) {
          this.suggestion = true;
        } else {
          this.suggestion = false;
        }

      },
      gettotalRows(list) {
      this.totalRows =  list.length
    }
    },
    computed: {
      filteredItemsWithImages() {
        let item = this.filteredItems.slice().sort(() => Math.random() - 0.5);
        return item.filter(hotel => hotel.firstimage && hotel.firstimage.image);
    },
      lists() {
      const items = this.filteredItemsWithImages
        .filter(post => {
          var reslt1 = post.nom.toLowerCase().includes(this.search.toLowerCase())
          var reslt2 = post.description.toLowerCase().includes(this.search.toLowerCase())
          return reslt1 || reslt2
        })
        .filter(post => { return this.ville != ''  &&  post.ville ? post.ville.id == this.ville : true })
        .filter(post => {
          this.prixMin = this.value[0];
          this.prixMax = this.value[1];
          // console.log(this.prixMin+' '+this.prixMax);
          var price = false;
          if (this.prixMax != "")
            price = parseInt(post.prix_max) >= parseInt(this.prixMin) && parseInt(post.prix_max) <= parseInt(this.prixMax);
          else if (this.prixMin != "")
            price = parseInt(post.prix_max) >= parseInt(this.prixMin)
          else
            price = true;
          return price;
        })
        this.gettotalRows(items)

      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    }
    // ,
    //   totalRows() {
    //     return this.hotels.length
    //   },
    }
  }

  </script>

  <style scoped></style>