<template>
    <div class="row justify-content-center" style="margin-top: 50px;">
        <div class="col-md-10 col-10">
          <div class="row justify-content-center">
            <div class="col-md-6 mb-3">
              <b-form-input
                id="input-mote"
                :state="moteError ? false : null"
                v-on:keyup.enter="search(mote)"
                v-model="mote"
                :placeholder="$t('recherche.trouvez')"
              ></b-form-input>
            </div>
            <div class="col-md-3 mb-3">
              <button class="btn btn-block btn-info rounded-pill" v-on:click="GoSearch(mote)" :disabled="loading">
                <b-icon-search v-if="!loading"></b-icon-search>
                <span v-if="loading"><b-spinner  label="Busy"></b-spinner></span>
                {{ $t('recherche.rechercher') }}
              </button>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  
  export default {
    name: 'SearchBar',
    data() {
      return {
        form: {
            mote: '',
            moteError: null,
            loading: false,
        },
      };
    },
    methods: {
      GoSearch(){
        if ( !this.mote) {
          if (!this.mote) this.moteError = "Mote is required";
          return;
        }
        this.loading = true;
        this.$router.push({
        name: 'ServicesComponent',
        params: { mote: this.mote },
      })
      }
    }
  };
  </script>
  
  <style scoped>
  .image-upload>input {
    display: none;
  }
  
  .margin-auto {
    margin: auto;
  }
  </style>
  