<template>
  <div class="container-fluid ">
    <!-- <Rubrique></Rubrique> -->
    <div class="container" id="scroll">
      <div class="row pt-5">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-12 ">
              <h1 class="mb-4"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                  src="@/assets/images/img-bladi.png" />
                {{ $t('rubrique.partages') }}
                <router-link v-if="isLoggedIn" to="#">
                  <b-icon class="float-right" @click="$emit('showModal')" v-b-modal.modal-add icon="plus-square"
                    font-scale="1" variant="grey"
                    v-b-popover.hover.top="$i18n.locale === 'Français' ? 'Ajouter un partage' : 'إضافة مشاركة'">
                  </b-icon>
                </router-link>
                <router-link v-else :to="{ name: 'Login', query: { redirect: $route.fullPath } }" @click="redirect()">
                  <b-icon class="float-right" icon="plus-square" font-scale="1" variant="grey"
                    v-b-popover.hover.top="$i18n.locale === 'Français' ? 'Ajouter un partage' : 'إضافة مشاركة'">
                  </b-icon>
                </router-link>
              </h1>
              <div class="row search">
                <div class="col-md-12">
                  <div class="form-group">
                    <input class="form-control form-control-sm " type="text" v-model="search"
                      style="font-family: 'Lato', cursive; padding-left: 10px" placeholder="Recherche par mots clés...">
                  </div>
                </div>
              </div>
              <div id="gallery">
                <div v-for="partage of lists" :key="partage.id">
                  <router-link
                    v-bind:to="'/partages/' + ($i18n.locale === 'Français' || $i18n.locale === 'English' ? partage.slug : (partage.slug_ar || partage.slug))">
                    <img style="width: 100%;" v-bind:src="BaseUrl + '/storage/' + partage.image" />
                  </router-link>
                </div>
              </div>
              <div class="col-md-12 ">
                <!-- d-flex justify-content-center -->
                <div class="overflow-auto d-flex justify-content-center pt-5">
                  <b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill">
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
      <b-modal ref="modal-add" id="modal-add" size="lg" centered hide-footer>
        <div>
          <div class="modal-body espace-voyageur dir-rtl" style="margin-top: -33px;">
            <div class="row">
              <div class="col-md-12 mb-md-0 mb-5 ">
                <div class="container m-4">
                  <h3><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                      src="@/assets/images/img-bladi.png" />{{ $t('pop_up.form_partage.titre') }}</h3>
                  <div class="row mt-4">
                    <div class="col-md-11 ">
                      <div class="form-group">
                        <label for="name" class="">{{ $t('pop_up.form_partage.votre_titre') }}</label>
                        <input type="text" id="name" v-model="form.titre" class="form-control ">
                        <span class="text-danger" v-if="errors.titre">
                          {{ errors.titre[0] }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-11 mt-3 ">
                      <div class="form-group">
                        <label for="message">{{ $t('pop_up.form_partage.votre_message') }}</label>
                        <textarea type="text" id="message" name="message" rows="6" cols="10"
                          class="form-control md-textarea" v-model="form.description"></textarea>
                        <span class="text-danger" v-if="errors.description">
                          {{ errors.description[0] }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-11 mt-3 ">
                      <div class="form-group">
                        <label for="message">{{ $t('recherche.villes') }}</label>
                        <select class="form-control" v-model="form.ville_id">
                          <option value="" readonly>
                            {{ $t("recherche.villes") }}
                          </option>
                          <option v-for="ville of villes" :key="ville.id" :value="ville.id">
                            {{
                  $i18n.locale === "Français" || $i18n.locale == "English" ? ville.ville : ville.ville_ar
                }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="errors.description">
                          {{ errors.description[0] }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-2 mt-3">
                      <div class="form-group image-upload">
                        <label for="message">{{ $t('pop_up.form_partage.votre_image') }}</label>
                        <label for="file-input">
                          <img src="@/assets/images/partage-icon.png" height="100" width="100" />
                        </label>
                        <input id="file-input" type="file" @change="onFileChange" class="form-control" />
                        <span class="text-danger" v-if="errors.image">
                          {{ errors.image[0] }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-5">
                      <img v-if="preview" :src="preview" width="100px" height="100px" />
                    </div>
                  </div>
                  <div class="cold-md-3 text-center">
                    <a class="btn btn-success " v-on:click="addPartage()">{{ $t('pop_up.form_partage.ajouter') }}</a>
                  </div>
                  <div class="status"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

import http from "./../http-common";
// import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import User from "@/apis/User";
import $i18n from '../i18n.js';

export default {
  metaInfo() {
    if ($i18n.locale == "Français" || $i18n.locale == "English") {
      return {
        title: 'Partages',
        meta: [
          {
            name: "description",
            content: "Partagez vos moments de voyage avec la communauté : photos, avis et conseils pour une expérience enrichissante.",
          },
        ],
      };
    }
    else {
      return {
        title: 'مشاركة',
        meta: [
          {
            name: "description",
            content: "شاركوا لحظات رحلتكم: صور، آراء، ونصائح للسفر في المغرب.",
          },
        ],
      };
    }
  },
  name: "PartageComponent",
  components: { /*Rubrique,*/ Sidebar },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      isLoggedIn: false,
      lieux: [],
      errors: [],
      preview: null,
      search: '',
      form: {
        user_id: '',
        titre: '',
        description: '',
        ville_id: '',
        image: '',
      },
      //items:this.hotels,
      perPage: 32,
      currentPage: 1,
      villes: [],
      totalRows: 0,

    }
  },
  mounted() {
    console.log(process.env.VUE_APP_API_URL);
    this.isLoggedIn = !!localStorage.getItem('token');
    http
      .get("/villes")
      .then((response) => {
        this.villes = response.data;
        this.$root.$on("login", () => {
          this.isLoggedIn = true;
        });
        this.isLoggedIn = !!localStorage.getItem("token");
        this.role_id = localStorage.getItem("role_id");
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {

    gettotalRows(list) {
      this.totalRows = list.length
    },
    redirect() {
      const redirectPath = this.$route.fullPath; // Saving current route
      this.$router.push({ name: 'Login', query: { redirect: redirectPath } });
      this.$toaster.error('Connectez-vous pour partager un post.');
    },
    onFileChange(event) {
      this.form.image = event.target.files[0];
      this.preview = URL.createObjectURL(this.form.image);
    },
    addPartage() {

      let addData = new FormData();
      addData.append('user_id', localStorage.getItem('id'));
      addData.append('titre', this.form.titre);
      addData.append('description', this.form.description);
      addData.append('ville_id', this.form.ville_id);
      addData.append('image', this.form.image);
      User.ajtPartage(addData)
        .then(() => {
          this.partagelist();
          this.$toaster.success('Votre post a été ajouter.')
          this.$refs['modal-add'].hide();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });

    },
    partagelist() {
      http.get('/partages')
        .then((response) => {
          //console.warn(response.data.data);
          this.lieux = response.data
          /*.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      )*/
        })
        .catch(e => {
          this.errors.push(e)
        })
    }

  },
  created() {
    this.partagelist();
  },
  computed: {
    lists() {
      const items = this.lieux.slice().sort(() => Math.random() - 0.5).filter((item) => {
        return item.titre?.toLowerCase().includes(this.search.toLowerCase())
          || item.seo_keywords?.toLowerCase().includes(this.search.toLowerCase())
          || item.description?.toLowerCase().includes(this.search.toLowerCase())
      })
      this.gettotalRows(items)
      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    }
  }
}

</script>

<style scoped>
.image-upload>input {
  display: none;
}

::v-deep #modal-add .modal-content {
  border-radius: 30px;
}

.btn-success {
  background-color: white !important;
  background: white !important;
  color: #1e594d !important;
  border: 1px solid #1e594d !important;
}

.btn-success:hover {
  background-color: #1e594d !important;
  background: #1e594d !important;
  border: 1px solid #1e594d !important;
  color: white !important;
}

.espace-voyageur .form-control {
  border: 2px solid #1f5b4e;
}


#gallery {
  line-height: 0;
  -webkit-column-count: 3;
  /* split it into 5 columns */
  -webkit-column-gap: 5px;
  /* give it a 5px gap between columns */
  -moz-column-count: 3;
  -moz-column-gap: 5px;
  column-count: 3;
  column-gap: 5px;
}

#gallery img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 5px;
  /* to match column gap */
  border-radius: 15px;
}

@media (max-width: 1200px) {
  #gallery {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 1000px) {
  #gallery {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  #gallery {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  #gallery {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

#gallery img:hover {
  filter: none;
}
</style>