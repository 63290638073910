<template>
  <div class="container-fluid">

    <div class="container min-height mt-5">

      <div class="row espace-prof top-forme justify-content-center text-left">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro />
          <div class="row  descriptif-body p-3">

              <div class="col-md-12">
                <div class="form-group">

                   <label class="control-label" for="">{{$t('details_service.description')}}</label>
                      <textarea class="control-label" v-model="description" style="width:100%;" name="" id="" cols="30" rows="7"></textarea>
              </div>
         </div>
         <div class="col-md-12 m-4 ">
               <Table class="table-responsive pr-3" v-model="items" :fields="fields"></Table>

         </div>

          <div class="col-md-6 ml-4">
                       <h4 >Prix Moyens des chambres</h4>
            <div class="form-group form-row mt-4">
              <label class="col-sm-3" for="">Prix Min</label>
              <input type="text" v-model="prixMin" id="" class="form-control col-sm-7" placeholder="" aria-describedby="helpId">
            </div>

            <div class="form-group form-row">
              <label class="col-sm-3" for="">Prix Max</label>
              <input type="text" v-model="prixMax" id="" class="form-control col-sm-7" placeholder="" aria-describedby="helpId">
            </div>
         </div>

          <div class="col-md-12 pt-3 pb-3 pl-5">
              <label for="" style="font-size:25px;"> Autre Services</label>
                  <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                          id="checkbox-group-1"
                          class="row"
                          :aria-describedby="ariaDescribedby"
                          name="service"
                          v-model="service"
                            >
                          <div class="col-md-3" v-for="(option,index) in options" :key="index">
                          <b-form-checkbox class="float-left" :value="option.id" size="lg">{{ option.nom}}</b-form-checkbox>
                          </div>
                      </b-form-checkbox-group>
                </b-form-group>


          </div>

        <div class="col-md-12 m-5">
                                <button type="submit" class="bg-msafar-primary btn text-white rounded-pill pl-5 pr-5 pt-2 pb-2"
                                    @click="validate">Passer à l étape
                                    suivante</button>
                                <span class="h1 color-msafar-primary flesh" style="">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </span>
                           </div>

          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import http from "./../../http-common";
import RubriquePro from "@/components/EspaceProfessionnel/RubriquePro";
import Table from "@/components/EspaceProfessionnel/Table";
//import Table2 from "@/components/EspaceProfessionnel/Table2";

export default {
  name: "DescriptifComponent",
  components: { RubriquePro,Table },
 data() {
       return {

description:"",
prixMin:"",
prixMax:"",
    columns: [
      "Type de chambre",
      "Nombre de personnes",
      "Options",
      "Prix à partir de",
      "Actions "
    ],
    persons: [

    ],
    id_hotel:"",
     service : [],
     options : [],
     fields: [
         { key: "type", label: "Type Chambre", type: "select", options: ['Chambre individuelle','Chambre double','Suite'] },
        { key: "prix", label: "Prix", type: "number" },
        { key: "options", label: "Options", type: "option" , options: ['Demi-pension','pension complete','Demi-pension /pension complete']},
        { key: "edit", label: "", type: "edit" }
      ],
       items: [
        ]

  }
  },
  methods: {
    //function to add data to table
    add: function() {
      this.persons.push({
       // lname: this.input.lname,
        nbr_personnes: this.input.nbr_personnes,
        prix: this.input.prix,
        options: this.input.options,

      });

      for (var key in this.input) {
        this.input[key] = "";
      }
      this.persons.sort();
      this.$refs.type.focus();
      console.log(this.persons);
    },
    //function to handle data edition
    edit: function(index) {
     // this.editInput.lname = this.persons[index].lname;
      this.editInput.nbr_personnes = this.persons[index].nbr_personnes;
      this.editInput.prix = this.persons[index].prix;
      this.editInput.options = this.persons[index].options;

      this.editIndex = index;
      window.$(".modal").modal();
    },
    //function to update data
    update: function() {
      this.persons.splice(this.editIndex, 1);
      this.persons.push({
       // lname: this.editInput.lname,
        nbr_personnes: this.editInput.nbr_personnes,
        prix: this.editInput.prix,
        options: this.editInput.options
      });
      for (var key in this.editInput) {
        this.editInput[key] = "";
      }
      window.$(".modal").modal('hide');
    },
    //function to defintely delete data
    deletePerson: function(index) {
      this.persons.splice(index, 1);
    },
        validate(){
            this.addHotel();
        },

        addHotel(){
              this.$hotel.value['description'] = this.description;
              this.$hotel.value['prix_min'] = this.prixMin;
              this.$hotel.value['prix_max'] = this.prixMax;
              this.$hotel.value['user_id'] = this.$user.value.user.id;

              /*console.log("Add Hotel");
              console.log(this.$hotel.value);*/

            http.post('/hotels',this.$hotel.value)
                .then((response) => {

                    this.$router.push("/professionnel/photos");
                    this.$newhotel.value = response.data
                    this.id_hotel = response.data.id;
                    this.addChambres();
                    this.addServices();
                })
                .catch(e => {
                    console.log(e);
                    this.$toaster.error(e.data)
                })
        },
        addServices(){
                var formData = new FormData();
                for (var i = 0; i < this.service.length; i++) {
                  formData.append('services[]', this.service[i]);
                }
                formData.append('id_hotel',this.id_hotel);

                http.post('/hotel-liste-services',formData)
                  .then((response) => {
                        console.log(response.data);
                  })
                  .catch(e => {
                      console.log(e);
                      this.$toaster.error(e.data)
                  })
        },

        addChambres(){

                var formData = new FormData();
                formData.append('chambres', JSON.stringify(this.items));
                formData.append('id_hotel',this.id_hotel);

                http.post('/liste-chambres',formData)
                  .then((response) => {
                        console.log(response.data);
                  })
                  .catch(e => {
                      console.log(e);
                      this.$toaster.error(e.data)
                  })
        }

  },
  mounted(){

    http.get('/services')
      .then((response) => {
        this.options = response.data
        console.log(this.options)
      })
      .catch(e => {
        console.log(e.data);
      })
    },
    created(){

      console.log("Created");
      console.log(this.$hotel.value);
    }
}
</script>

<style scoped>


.table thead th {
    border: 1px solid #bc4c58!important;
    border-radius: 83px!important;
    text-align: center;
    color: #bc4c58;
    vertical-align: bottom;
}
.table thead  th:last-child{
    border: 0px!important;
}
table{
  border-collapse: initial;
}



.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #ff9521;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #ff9521!important;
}



</style>