<template>
  <div :class="[{ 'dir-rtl': isRtl }, 'container-fluid']">
    <div class="container-fluid home pb-5">
      <SearchBar class="d-none"></SearchBar>
      <div class="row">
        <div class="container pb-5" style="margin-top: 30px">
          <!-- <div class="col-md-12 bg-home-mobile"></div> -->
          <!-- v-bind:style="[this.$i18n.locale === 'fr' ? 'background-position: left' : ''] -->
          <div class="row main-slide">
            <div class="col-md-4 col-12 d-flex align-items-center">
              <div class="home-filter">
                <h1 class="d-none">
                  Organisez Vos Vacances au Maroc Facilement - Msafar.ma
                </h1>
                <div :class="{ 'dir-rtl': isRtl }">
                  <p style="color: #fb9d48">{{ $t("slide.organisez") }}</p>
                  <p>{{ $t("slide.vos_voyages") }}</p>
                  <p>{{ $t("slide.au_Maroc") }}</p>
                  <p class="mb-4">{{ $t("slide.en_un_clic") }}</p>
                </div>
                <select class="categorie" v-model="categorie">
                  <!-- <option value="" v-model="categorie">Catégorie</option> -->
                  <option value="" hidden>
                    {{ $t("recherche.rch_categorie") }}
                  </option>
                  <option value="hotels">{{ $t("rubrique.hotels") }}</option>
                  <option value="riads">
                    {{ $t("rubrique.riad_maisons") }}
                  </option>
                  <option value="apparthotels">
                    {{ $t("rubrique.apparthot") }}
                  </option>
                  <option value="restaurants">
                    {{ $t("rubrique.restaurants") }}
                  </option>
                  <option value="activites-touristiques">
                    {{ $t("rubrique.activite_touristique") }}
                  </option>
                  <option value="produit_artisanal">
                    {{ $t("rubrique.guides_touristique") }}
                  </option>
                </select>
                <div style="display: flex; align-items: flex-start">
                  <select class="ville" v-model="ville">
                    <option value="" hidden>
                      {{ $t("recherche.rch_villes") }}
                    </option>
                    <option
                      v-for="ville of villes"
                      :key="ville.id"
                      :value="ville.id"
                    >
                      {{
                        $i18n.locale === "العربية"
                          ? ville.ville_ar
                          : ville.ville
                      }}
                    </option>
                  </select>
                  <button class="btn-home" v-on:click="searchByVille">
                    <!-- v-bind:disabled="ville === ''" -->
                    <b-icon-arrow-right
                      :class="[isRtl ? 'arrow-right' : 'arrow-right']"
                    ></b-icon-arrow-right>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-md-8 col-12 cont-slide d-flex align-items-center slide-mobile"
            >
              <SlideHome></SlideHome>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="margin: 25px auto 25px">
        <h1 class="">
          <img
            src="@/assets/images/img-bladi.png"
            style="margin-top: -16px; width: 55px"
          />
          {{
            $i18n.locale === "Français" || $i18n.locale === "English"
              ? "Suggestions"
              : "اقتراحات"
          }}
        </h1>
      </div>
      <div class="container cont-carousel" v-if="filteredItems.length > 0">
        <br />
        <MesCarousel :items="filteredItems" />
      </div>
      <div class="container mt-4 mb-4">
        <div class="row cont-text">
          <div class="col-10">
            <p class="para">
              {{ $t("home.para1")
              }}<router-link to="/qui-sommes-nous"> </router-link>
            </p>
            <!-- <p class="para">{{ $t("home.para2") }}<router-link to="/voyageur/home"> </router-link></p> -->
          </div>
        </div>
      </div>
      <div class="container rubriques">
        <RubriqueImageComponent></RubriqueImageComponent>
      </div>
      <div class="container cadre-info bg-info-contact">
        <div class="row mt-5 p-5">
          <div class="col-md-12">
            <div class="btnm-espace-center">
              <a href="https://wa.me/636303003" class="text-dark">
                <h3 v-html="$t('cadre_info.info')"></h3>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-4 mb-4">
        <div class="row cont-text">
          <div class="col-10">
            <p class="para">{{ $t("home.para3") }}</p>
          </div>
        </div>
      </div>
      <!-- <div id="carouselExampleControls" class="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" style="width: 0% !important;"></div>
            <div v-for="item in filteredItems" :key="item.id" class="carousel-item">
                <div class="card">
                    <div class="img-wrapper"><img src="#" class="d-block w-100" alt="#"> </div>
                    <div class="card-body">
                        <h5 class="card-title text-center">{{ item.nom }}</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of
                            the
                            card's content.</p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div> -->
    </div>
    <div class="container">
      <div style="display: flex; flex-direction: row;align-items: center;">
        <h1 class="mb-4 d-flex w-100 align-items-center">
          
          <img
            style="margin-right: 15px; width: 55px"
            src="@/assets/images/img-bladi.png"
          /><router-link to="/partages" style="text-decoration: none;color: #212529 !important;">{{ $t("rubrique.partages1") }}</router-link>
        </h1>
        <AddPartage></AddPartage>
      </div>
      <ListPartage></ListPartage>
    </div>
    <div class="espaces pb-5 pt-5">
      <div class="row mt-5 p-5 btn-espaces bg-espace">
        <div class="col-md-12">
          <div class="float-right btnm-espace-center">
            <div class="col-md-12" v-if="isLoggedIn && parseInt(role_id) === 1">
              <router-link
                :to="{ name: 'Profil' }"
                class="btn btn-light p-3 mb-3"
                >{{ $t("espaces.espace_voyageur") }}
              </router-link>
            </div>
            <div class="col-md-12" v-else>
              <router-link
                :to="{ name: 'VoyageurHome' }"
                class="btn btn-light p-3 mb-3"
              >
                {{ $t("espaces.espace_voyageur") }}</router-link
              >
            </div>

            <div class="col-md-12" v-if="isLoggedIn && parseInt(role_id) === 2">
              <router-link
                :to="{ name: 'InformationCompteProfil' }"
                class="btn btn-light p-3"
              >
                {{ $t("espaces.espace_professionnel") }}
              </router-link>
            </div>

            <div class="col-md-12" v-else>
              <router-link
                :to="{ name: 'ProfessionnelHome' }"
                class="btn btn-light p-3"
              >
                {{ $t("espaces.espace_professionnel") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-4 mb-4">
      <div class="row cont-text">
        <div class="col-10">
          <p class="para">{{ $t("home.para4") }}</p>
        </div>
      </div>
    </div>
    <div class="container service-section pt-5">
      <div class="row mt-5 p-2 justify-content-center text-center">
        <div class="col-md-3">
          <div class="card p-2">
            <router-link to="/lieux-a-visiter" class="">
              <img
                class="card-img-top"
                src="@/assets/images/lieux-a-visiter.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  {{ $t("rubrique.lieux_visiter") }}
                </h5>
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card p-2">
            <router-link to="/gastronomies" class="">
              <img
                class="card-img-top"
                src="@/assets/images/gastronomie.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  {{ $t("rubrique.gastronomie") }}
                </h5>
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card p-2">
            <router-link to="/evenements" class="">
              <img
                class="card-img-top"
                src="@/assets/images/evenement.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  {{ $t("rubrique.evenements") }}
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row mt-5 p-2 justify-content-center text-center">
        <!-- <div class="col-md-3">
            <div class="card p-2" >
              <router-link to="/temoignages" class="">
                <img class="card-img-top" src="@/assets/images/tem.png" alt="Card image cap">
                <div class="card-body">
                  <h5 class="card-c">Témoignages</h5>
                </div>
              </router-link>
            </div>
          </div> -->

        <div class="col-md-3">
          <div class="card p-2">
            <router-link to="/partages" class="">
              <img
                class="card-img-top"
                src="@/assets/images/com.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  {{ $t("rubrique.partages") }}
                </h5>
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card p-2">
            <router-link to="/actualites" class="">
              <img
                class="card-img-top"
                src="@/assets/images/evenements.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  {{ $t("rubrique.actualites") }}
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http-common";
import MesCarousel from "@/components/Carousel.vue";
import AddPartage from "@/components/Outils/AddPartage.vue";
import SlideHome from "@/components/Outils/Slide.vue";
import SearchBar from "@/components/Outils/SearchBar.vue";
import ListPartage from "@/components/ListPartage.vue";
import RubriqueImageComponent from "@/components/Outils/RubriqueImage.vue";
import $i18n from "../i18n.js";
export default {
  metaInfo() {
    if ($i18n.locale == "Français" || $i18n.locale == "English") {
      return {
        title:
          "Découvrez le Maroc : Hôtels, Riads, Randonnées et Activités en un Clic - Msafar.ma",
        meta: [
          {
            name: "description",
            content:
              "Découvrez le Maroc avec Msafar.ma : Hôtels, riads, gastronomie, activités et randonnées. Explorez l'authenticité et la splendeur du Maroc en un clic.",
          },
          {
            name: "keywords",
            content:
              "Voyage au Maroc - Tourisme au Maroc - Hôtels au Maroc - Riads au Maroc -  Maisons d'hôtes Maroc - Activités touristiques Maroc - Randonnées Maroc - Gastronomie marocaine",
          },
        ],
      };
    } else {
      return {
        title: "نظم عطلتك في المغرب بسهولة - مسافر",
        meta: [
          {
            name: "description",
            content:
              "Msafar.ma، دليلك لاستكشاف المغرب مع مجموعة من الفنادق وبيوت الضيافة والرياض والمطاعم. مقدم خدمات سياحية أو مسافر",
          },
          {
            name: "keywords",
            content:
              "حجز فندق المغرب، المأكولات المغربية، رياض مراكش، نزل آيت بوجاميز، السفر إلى المغرب",
          },
        ],
      };
    }
  },
  name: "HomeComponent",
  props: {
    msg: String,
    isRtl: Boolean,
  },
  components: {
    MesCarousel,
    RubriqueImageComponent,
    AddPartage,
    ListPartage,
    SlideHome,
    SearchBar
  },
  data() {
    return {
      villes: [],
      categorie: "",
      ville: "",
      role_id: [],
      isLoggedIn: false,
      filteredItems: [],
    };
  },
  mounted() {
    http.get("/carousel").then((response) => {
      this.filteredItems = response.data;
    });
    http
      .get("/villes")
      .then((response) => {
        this.villes = response.data;
        this.$root.$on("login", () => {
          this.isLoggedIn = true;
        });
        this.isLoggedIn = !!localStorage.getItem("token");
        this.role_id = localStorage.getItem("role_id");
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    searchByVille() {
      this.$router.push({
        name: this.categorie,
        params: { ville: this.ville },
      });
    },

    moveCarousel() {
      let items = document.querySelectorAll(".carousel .carousel-item");
      items.forEach((el) => {
        const minPerSlide = 4;
        let next = el.nextElementSibling;
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            next = items[0];
          }
          let cloneChild = next.children[0].cloneNode(true); // Clone only the inner content

          // Create a new router-link element and wrap the cloned content
          let routerLink = document.createElement("router-link");
          routerLink.setAttribute(
            "to",
            `/${next.dataset.url}/${next.dataset.slug}`
          );
          routerLink.appendChild(cloneChild);

          el.appendChild(routerLink);
          next = next.nextElementSibling;
        }
      });
    },
  },
};
</script>

<style scoped>
.cont-text {
  display: flex !important;
  justify-content: center !important;
}

.para {
  font-size: large;
  text-align: center;
}

option:hover {
  background-color: #de6401 !important;
}

/** Optionnal **/

select option:hover {
  background: white !important;
  background-color: #ffffff !important;
  color: #0b74a3 !important;
}

select option:checked {
  background: linear-gradient(#ffffff, #ffffff);
  background-color: #ffffff !important;
  color: #0b74a3 !important;
}

/*Scroll line*/
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);*/
  -webkit-border-radius: 10px;
  background-color: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
}
@media screen and (min-width: 767px) {
  .bg-home-mobile {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .slide-mobile {
    /* margin-top: 50px; */
    height: 400px;
  }

  .bg-home {
    background: none;
    /* height: 312px; */
  }

  .home-filter {
    background: none;
    margin-top: 0px;
  }
}

.btn-espaces .btn {
  font-family: "GillSansMT";
  font-size: 25px;
  border-radius: 18px;
  color: #1d4f44;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.btn-espaces .btn:hover {
  font-family: "GillSansMT";
  font-size: 25px;
  border-radius: 18px;
  background-color: #e12a02;
  color: white;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.cadre-info h3 {
  margin: unset;
}

::v-deep .cadre-info i {
  color: #37ba1e !important;
}

.bg-info-contact {
  background: url("@/assets/images/cadre-info.png") no-repeat;
  background-position: cover;
  height: 171px;
  background-size: cover;
}

@media screen and (min-width: 1000px) {
  .bg-espace {
    background: url("@/assets/images/espace-fond.jpg") no-repeat;
    background-position: unset;
    width: 1167px;
    background-size: 100% 100%;
    height: 248px;

    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 999px) {
  .bg-espace {
    background: url("@/assets/images/espace-fond-mobile.jpg") no-repeat;
    background-position: unset;
    height: 300px;
    background-size: 100% 100%;

    margin-left: auto;
    margin-right: auto;
  }

  .bg-info-contact {
    background: url("@/assets/images/cadre-info-mobile.png") no-repeat;
    background-position: center center;
    background-size: cover;
    height: 36vh;
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.btn-home {
  background: #8e1f18;
  /*position: absolute;*/
  color: white !important;
  padding: 11px 13px;
  border: none;
  border-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.arrow-right {
  font-size: 30px;
}

.carousel-inner {
  padding: 1em;
}

.card {
  margin: 0 0.5em;
  border: none;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #e1e1e1;
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .carousel-item {
    margin-right: 0;
    flex: 0 0 33.333333%;
    display: block;
  }

  .carousel-inner {
    display: flex;
  }
}

.card .img-wrapper {
  max-width: 100%;
  height: 13em;
  display: flex;
  align-items: center;
}

.card img {
  max-height: 100%;
}

@media (max-width: 767px) {
  .card .img-wrapper {
    height: 17em;
  }
}

.cont-carousel {
  background-color: #d3d3d35e;
  display: flex;
  align-items: center;
  padding: 30px;
}

@media screen and (max-width: 600px) {
  .cont-carousel {
    padding: 10px;
  }
}
</style>
