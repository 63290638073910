<template>

  <div class="container-fluid ">
    <!-- <Rubrique></Rubrique> -->
    <PaginationTitle :article="restaurant" class="mt-5"></PaginationTitle>
    <div class="container">
      <div class="row ">
        <div class="col-md-9 search">
          <div class="row mt-5 ">
            <div class="col-md-12 py-3">
              <carousel :starting-image="2" :images="images" :restaurant_id="restaurant.id"
                @AddFavorite="ajtFavorite($event)" :auto-slide-interval="5000" :show-progress-bar="false">
              </carousel>
              <Information :hotel="restaurant" :totalEtoiles="totalEtoiles" />
              <SocialMedia :hotel="restaurant" />
              <div class="col-md-12 mt-3 pl-3 restaurant-menu" v-if="restaurant.ligne_menus.length">
                <h5 class="text-center" style="color:#0172ae;font-size: 26px">Menu</h5>
                <div>
                  <b-card no-body>
                    <b-tabs pills card vertical>
                      <b-tab v-for="(ligne, index) of restaurant.ligne_menus" :key="ligne.id"
                        :title="'' + ligne.categoriemenu.nom" title-item-class="justify-content-center text-center">
                        <template #title>
                          <div class="div-padding" :style="index === 0 ? 'padding-top: 100px;' : ''"></div>
                          <img v-bind:src="BaseUrl + '/storage/' + ligne.categoriemenu.avatar"
                            style="width:70px;" /><br>
                          <span class="tabs-title"> {{ ligne.categoriemenu.nom }}</span>
                        </template>
                        <div class="row">
                          <div class="col-md-6 p-4" v-for="menu of ligne.menus" :key="menu.id">
                            <h6>{{ menu.nom }}</h6>
                            <h6 class="float-right">{{ menu.prix }} dh</h6>
                            <p class="">{{ menu.description }}</p>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>

              <Commentaire :commentaires="commentaires"  :map="restaurant.map" :errors="errors" :restaurant_id="restaurant.id"
                @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)"
                @DeleteComment="supComment($event)" />

            </div>
            <div>
              <ReservtionModal title="Restaurant" :restaurant_id="restaurant.id" :errors="errors"
                :owner_email="computedOwnerEmail" @Reserver="reserverRestaurant($event)" />
            </div>
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>

</template>

<script>


import http from "./../../http-common";
import Carousel from './../Outils/Carousel.vue'
// import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ReservtionModal from "./CardComponents/ReservtionModal.vue";
import Information from "./CardComponents/Information.vue";
import SocialMedia from "./CardComponents/SocialMedia.vue";
import Commentaire from "./CardComponents/Commentaire.vue";
//import SocialMedia from "./CardComponents/SocialMedia.vue";
//import Commentaire from "./CardComponents/Commentaire.vue";
import PaginationTitle from "./PaginationTitle.vue";
import seo from "../../seo.js";
import  $i18n  from '../../i18n.js';

export default {
    metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.restaurant,this.images);
    }
    else{
      return seo.metaInfo_ar(this.restaurant,this.images);
    }
  },
  name: "RestaurantViewComponent",
  components: { Carousel, /*Rubrique,*/ Sidebar, ReservtionModal, Information, SocialMedia, Commentaire,PaginationTitle },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      // BaseUrl: 'http://api.msafar.ma',
      fields: ['Type de Chambre', 'Nombre de personne', 'Options', 'Prix à partie de'],
      restaurant: [],
      items: [],
      images: [],
      errors: [],
      commentaires: [],
      user_id: localStorage.getItem('id'),
      //items:this.hotels,
      perPage: 2,
      currentPage: 1
    }
  },
  mounted() {
    const id = this.id;
    //console.log(id);
    http.get(`/restaurants/${id}`)
      .then((response) => {
        var listT = [];
        this.restaurant = response.data
        console.log(this.restaurant)
        response.data.images.map(function (value) {
          listT.push(value.image);
        });
        //listT.push(this.restaurant.image);
        //console.log(this.restaurant.image);
        this.images = listT;
        this.items = this.restaurant.chambres;
        this.commentaires = this.restaurant.commentaires;
        let userId = this.user_id;
        this.commentaires = this.commentaires.map(function (field) {
          return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
        })
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    async reserverRestaurant(event) {
      await http.post('/reservations', event)
        .then(() => {
          this.$root.$emit('bv::hide::modal', 'modal-reservation');
          this.$toaster.error('Votre Bien a été réserver.')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    ajtFavorite(event) {
      http.post('/favorites', event)
        .then(() => {
          this.$toaster.success('Votre restaurant a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    ajtComment(event) {
      http.post('/commentaires', event)
        .then(() => {
          this.getCommentaires();
          this.$toaster.success(this.$t('toaster.commentaires.ajouter'))
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    mdfComment(event) {
      http.post(`/commentaires/${event.id}`, event).then(() => {
        this.getCommentaires();
        this.$toaster.error(this.$t('toaster.commentaires.modifier'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http.delete(`/commentaires/${event.id}`).then(() => {
        this.getCommentaires();
        this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },

    getResteOfImageNumbre() {
      return this.images.length > 4 ? ' + ' + (this.images.length - 4) : '  ';
    },
     getCommentaires() {
      const id = this.id;
      http.get(`/restaurants/${id}`)
        .then((response) => {
          this.commentaires = response.data.commentaires
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
  },
  computed: {
    computedOwnerEmail() {
      if (this.restaurant.user && this.restaurant.user.email) {
        return this.restaurant.user.email;
      } else if (this.restaurant.email) {
        return this.restaurant.email;
      } else {
        return 'contact@msafar.ma';
      }
    },
    lists() {
      //const options = this.items
      // Return just page of items needed options ,nbr_personnes
      let list = [];

      /*this.items.map(function(value) {
        list.push([value['type'],value['nbr_personnes'],value['options'],value['prix']]);
      });*/
      return list
    },
    totalEtoiles() {
      return this.restaurant.nbr_etoiles > 5 ? 5 : this.restaurant.nbr_etoiles
    },
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id

    }
  }
}
</script>

<style >
.rtl .float-right{
  float: left!important;
}
.rtl h6 {
font-weight: 600!important;
}
h6 {
  display: inline-block;
  font-weight: bold !important;
  ;
}

.restaurant-menu .tabs .nav-link span {
  text-transform: capitalize;
  color: #fff;
  margin-top: 10px;
  display: block;
  /* margin: 20px auto; */
  border: 2px solid black;
  border-radius: 25px;
  font-size: 20px;
  padding: 2px 24px 2px 24px;
  display: inline-block;
  color: #000;
  text-align: center;
}

.restaurant-menu .tabs .nav-link.active span {
  background-color: #98191a;
  border: none;
  color: #fff;

}

.restaurant-menu .tabs .nav-link.active {
  background-color: transparent !important;
}

.restaurant-menu .tabs {
  min-height: 650px;
}

.restaurant-menu ul li {
  margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
  .card-header.nav-pills {
    width: 100%;
      display: flex;
      flex-direction: row !important;
      flex-wrap: nowrap;
  }
  .card-header.nav-pills img{
    width: 39px !important;
  }
  .restaurant-menu .tabs .nav-link span {
    font-size: 14px;
    padding: 5px;
}
.div-padding{
  display: none;
}
}
</style>