<template>
  <div class="container-fluid ">
    <!-- <Rubrique></Rubrique> -->
    <PaginationTitle :article="hotel" class="mt-5"></PaginationTitle>
    <div class="container">
      <div class="row ">
        <div class="col-md-9 search ">
          <div class="row mt-5 bg-white">
            <div class="col-md-12 ">
              <carousel :starting-image="2" :images="images" :riad_id="hotel.id" @AddFavorite="ajtFavorite($event)"
                :auto-slide-interval="500000000" :show-progress-bar="false">
              </carousel>
              <!-- <img src="http://placehold.jp/300x200.png" class="card-img-top" alt="...">-->
              <div class="card-body">
                <Information :hotel="hotel" :totalEtoiles="totalEtoiles" />
                <Chambre :items="items" />
              </div>
              <SocialMedia :hotel="hotel" />
              <Service v-if="services.length" :services="services" />
              <Commentaire :commentaires="commentaires"  :map="hotel.map" :errors="errors" :riad_id="hotel.id"
                @AddComment="ajtComment($event)" @UpdateComment="mdfComment($event)"
                @DeleteComment="supComment($event)"  />
            </div>
            <ReservtionModal title="hôtel" :hotel_id="hotel.id" :errors="errors" :owner_email="computedOwnerEmail"
              @Reserver="reserverHotel($event)" />
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>
</template>

<script>


import http from "./../../http-common";
import Carousel from './../Outils/Carousel.vue'
// import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ReservtionModal from "./CardComponents/ReservtionModal.vue";
import Chambre from "./CardComponents/Chambre.vue";
import Service from "./CardComponents/Service.vue";
// import User from "@/apis/User";
import Commentaire from "./CardComponents/Commentaire.vue";
import SocialMedia from "./CardComponents/SocialMedia.vue";
import Information from "./CardComponents/Information.vue";
import PaginationTitle from "./PaginationTitle.vue";
import seo from "../../seo.js";
import  $i18n  from '../../i18n.js';
export default {
    metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.hotel,this.images);
    }
    else{
      return seo.metaInfo_ar(this.hotel,this.images);
    }
  },
  name: "RiadViewComponent",
  components: { Carousel, /*Rubrique,*/ Sidebar, ReservtionModal, Chambre, Service, Commentaire, SocialMedia, Information,PaginationTitle },
  data() {
    return {
      isLoggedIn: false,
      BaseUrl: process.env.VUE_APP_API_URL,
      user_id: localStorage.getItem('id'),
      fields: ['Type de Chambre', 'Nombre de personne', 'Options', 'Prix à partie de'],
      hotel: [],
      items: [],
      commentaires: [],
      services: [],
      comment: [],
      images: [],
      errors: [],
      //items:this.hotels,
      perPage: 2,
      currentPage: 1,
      user: [],
    }
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem('token');
    const id = this.id;
    http.get(`/riads/${id}`)
      .then((response) => {
        this.hotel = response.data;
        var listT = [];
        response.data.images.map(function (value) {
          listT.push(value.image);
        });
        //listT.push(this.hotel.image);
        this.images = listT;
        this.items = this.hotel.chambres;
        this.commentaires = this.hotel.commentaires;
        let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        this.services = this.hotel.hebergement_service;
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    async reserverHotel(event) {
      await http.post('/reservations', event)
        .then(() => {
          this.$root.$emit('bv::hide::modal', 'modal-reservation');
          this.$toaster.error('Votre Bien a été réserver.')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },

    ajtComment(event) {
      http.post('/commentaires', event)
        .then(() => {
          this.getCommentaires();
          this.$toaster.success(this.$t('toaster.commentaires.ajouter'))
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },
    mdfComment(event) {
      http.post(`/commentaires/${event.id}`, event).then(() => {
        this.getCommentaires();
        this.$toaster.error(this.$t('toaster.commentaires.modifier'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    supComment(event) {
      http.delete(`/commentaires/${event.id}`).then(() => {
        this.getCommentaires();
        this.$toaster.error(this.$t('toaster.commentaires.supprimer'))
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },
    getCommentaires() {
      const id = this.id;
      http.get(`/riads/${id}`)
        .then((response) => {
          //console.warn(response.data.data);
          this.commentaires = response.data.commentaires
          let userId = this.user_id;
          this.commentaires = this.commentaires.map(function (field) {
            return field.user.id === parseInt(userId) ? { ...field, exists: true } : { ...field, exists: false };
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    ajtFavorite(event) {
      http.post('/favorites', event)
        .then(() => {
          this.$toaster.success('Votre riad a été enregistré.')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
            // this.$toaster.error(e.response)
          }
        })
    },

    // getUser() {
    //   if (this.user_id) {
    //     User.auth().then(response => {
    //       this.user = response.data;
    //     }).catch((errors) => {
    //       console.log(errors)
    //     });
    //   }
    // },

    // getCommentaires() {
    //   http.get(`/commentaires/get-hotel-commentaires/${this.hotel.id}`)
    //     .then((response) => {
    //       //console.warn(response.data.data);
    //       this.commentaires = response.data
    //     })
    //     .catch(e => {
    //       this.errors.push(e)
    //     })
    // }
  },
  computed: {
    computedOwnerEmail() {
      if (this.hotel.user && this.hotel.user.email) {
        return this.hotel.user.email;
      } else if (this.hotel.email) {
        return this.hotel.email;
      } else {
        return 'contact@msafar.ma';
      }
    },
    lists() {
      let list = [];
      return list
    },
    totalEtoiles() {
      return this.hotel.nbr_etoiles > 5 ? 5 : this.hotel.nbr_etoiles
    },
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
  // created() {
  //   if (this.user_id) {
  //     this.getUser()
  //   }
  // },
}

</script>
