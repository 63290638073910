<template>
    <div class="container-fluid">
        <div class="container min-height">
            <div class="row justify-content-center mt-5">
                <div class="col-md-5 col-12 bg-espace-voyageur">
                    <div class=" dir-rtl" style="" v-if="!isLoggedIn">
                        <p v-html="$t('login_voyageur.login_text')"></p>
                        <div class="buttons m-5 text-center">
                            <router-link to="/login"> <button class="btn btn-success rounded-pill  m-2">{{
                        $t('login_voyageur.login') }}</button>
                            </router-link>
                            <router-link to="/voyageur/dashbord"><button class="btn btn-success rounded-pill  m-2">{{
                        $t('login_voyageur.register') }}</button>
                            </router-link>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-5" v-if="isLoggedIn && parseInt(role_id) === 2">
                        <div class="col-md-5 ">
                            <div class="bg-info-logout-espace" style="">
                                <p>Déconnectez-vous de l'espace Professionnel pour accéder à l'espace Voyageur. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    metaInfo() {
        const descriptionFr = "Msafar.ma vous aidera à sélectionner les randonnées Aït Bougemez, Akchour ou Merzouga et à profiter des conseils pour vos voyages au Maroc.";
        const descriptionAr = "سيساعدك مسافر.ما على اختيار الرحلات في أيت بوجماز، أكشور أو مرزوكة والاستفادة من النصائح لرحلاتك في المغرب.";
        const titleFr = "Msafar.ma - Espace voyageur";
        const titleAr = "مسافر.ما - فضاء المسافر";
        const keywordsFr = "Conseils voyages au Maroc, Randonnées Aït Bougemez, Randonnées Akchour, Randonnées Merzouga";
        const keywordsAr = "نصائح السفر في المغرب, رحلات أيت بوجماز, رحلات أكشور, رحلات مرزوكة";
        const image = "@/assets/images/logo.png"; // Update with your actual image URL


        return {
            title: this.$i18n.locale === "Français" ? titleFr : titleAr,
            meta: [
                {
                    name: "description",
                    content: this.$i18n.locale === "Français" ? descriptionFr : descriptionAr,
                },
                {
                    name: "keywords",
                    content: this.$i18n.locale === "Français" ? keywordsFr : keywordsAr,
                },
                {
                    property: "og:title",
                    content: this.$i18n.locale === "Français" ? titleFr : titleAr,
                },
                {
                    property: "og:description",
                    content: this.$i18n.locale === "Français" ? descriptionFr : descriptionAr,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:url",
                    content: window.location.href,
                },
                {
                    property: "og:image",
                    content: image,
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: this.$i18n.locale === "Français" ? titleFr : titleAr,
                },
                {
                    name: "twitter:description",
                    content: this.$i18n.locale === "Français" ? descriptionFr : descriptionAr,
                },
                {
                    name: "twitter:image",
                    content: image,
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1",
                },
            ],
        };
    },
    name: "formLogin",
    data() {
        return {
            role_id: [],
            isLoggedIn: false,

        };
    },
    mounted() {
        this.$root.$on("login", () => {
            this.isLoggedIn = true;
        });
        this.isLoggedIn = !!localStorage.getItem('token');
        this.role_id = localStorage.getItem('role_id');
    },
}
</script>
<style scoped>
/*.bg-espace-voyageur {
    background: transparent url('@/assets/images/bg-espace-voyageur.png') no-repeat;
    background-size: contain;
    width: 450px;
    padding: 54px;
    margin: auto;
}*/ 
.bg-espace-voyageur {
    background: transparent url('@/assets/images/bg-espace-voyageur2.png') no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 9px;
    border-radius: 20px;
    min-height: 400px;
}
.bg-espace-voyageur .buttons button {
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 20px;

}

.bg-espace-voyageur .btn-success:hover {
    background-color: #008ec6;
    border: 1px solid #008ec6;
    color: white;
}

.btn-success {

    background-color: white;
    color: #1e594d;
    border: 1px solid #1e594d;
}

.bg-espace-voyageur p {
    width: 80%;
    margin: auto;
    margin-top: 42px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
</style>