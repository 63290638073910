<template>
  <div id="app" :class="{ 'blur-content': showModal, 'rtl': addRtl() }">
    <!-- 'blur-content': showModal , -->

    <Header @showModal="displayBlur" @hideBlur="hideBlur" />

    <router-view :isRtl="checkRtl()" />
    <!-- <div class="container">
      <div class="row justify-content-center p-5">
        <div class="col-md-3">
          <div class="download-apps">
            <img class="card-img-top" src="@/assets/images/app-store.png" alt="Card image cap" style="margin-bottom: 25px;"><br>
            <img class="card-img-top" src="@/assets/images/play-store.png" alt="Card image cap">
          </div>
        </div>
      </div>
    </div> -->
    <Footer />


  </div>
</template>

<script>

import Header from "@/components/Layouts/Header";
import Footer from "@/components/Layouts/Footer";

//import HomeComponent from './components/Home.vue'
//import BackToTop from 'vue-backtotop'


export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {

  },
  data() {
    return {
      showModal: false,
      isRtl: false,
      showRtl: false,
    }
  },
  methods: {
    // checkLocal() {
    //   if (this.showRtl === true) {
    //     document.querySelector('body').classList.toggle('rtl');
    //   }
    // },
    addRtl() {
      this.showRtl = this.$i18n.locale === 'العربية' ? true : false;
      return this.showRtl;
    },
    hideBlur() {
      this.showModal = false
    },
    displayBlur() {
      this.showModal = true
    },

    checkRtl() {
      this.isRtl = this.$i18n.locale === 'العربية' ? true : false;
      return this.isRtl;
    }
  },

}


</script>

<style>
@import'~bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/style.css';
@import './assets/css/rtl.css';
</style>

<style scoped>
.blur-content {
  filter: blur(3px);
}
</style>

<!-- <style lang="scss" >
.dir-rtl {
  direction: rtl !important;
}
</style> -->
