<template>
    <div class="row mt-5">
        <div class="col-md-12">
            <div class="card-carousel" @mouseover="stopTimer" @mouseleave="restartTimer">
                <div class="progressbar" v-if="autoSlideInterval && showProgressBar">
                    <div :style="{ width: progressBar + '%' }"></div>
                </div>
                <div class="card-img">
                    <img v-if="currentImage" :src="BaseUrl + `/storage/` + currentImage" alt="" class="carousel-img">
                    <div class="actions">
                        <span @click="prevImage" class="prev">
                            &#8249;
                        </span>
                        <span @click="nextImage" class="next">
                            &#8250;
                        </span>
                    </div>
                </div>
                <div class="thumbnails">
                    <div class="col-md-12">
                        <div class="row pt-4 pl-3 pr-3">

                            <div v-for="(image, index) in  listImages" :key="index"
                                :class="['thumbnail-image col-4 col-md-2', (activeImage == index) ? 'active' : '']"
                                @click="activateImage(index)">
                                <img class="responsive-img" :src="BaseUrl + `/storage/` + image">
                            </div>
                        </div>

                        <b-collapse id="collapse-1" class="ml-3" v-if="!resterImages.lenght">

                            <div class="row">
                                <div v-for="(image, index) in  resterImages" :key="index"
                                    :class="['thumbnail-image col-4 col-md-2', (activeImage == index + 6) ? 'active' : '']"
                                    @click="activateImage(index + 6)">
                                    <b-img-lazy :src="BaseUrl + `/storage/` + image" />
                                </div>
                            </div>
                        </b-collapse>


                    </div>

                </div>

                <b-btn block href="#" v-b-toggle.collapse-1 style="border:0px;color:black;" class="bg-white mt-2"
                    v-if="resterImages.length">

                    <span class="when-opened">
                        Réduire
                        <b-icon-chevron-up></b-icon-chevron-up>
                    </span>
                    <span class="when-closed">
                        Charger plus
                        <b-icon-chevron-right></b-icon-chevron-right>
                    </span>
                </b-btn>

                <b-modal id="showImageModal" size="lg" class="">

                    <div class="modal-body text-center">
                        <b-img-lazy :src="BaseUrl + `/storage/` + modal_image_path"
                            style="max-width:100%;max-height:500px;" />
                    </div>



                    <div slot="modal-footer">

                    </div>
                </b-modal>

            </div>
        </div>
        <!-- isfav : {{isfav}} <br>
        fav id :{{ favid }} -->

        <div class="col-md-12 h1 mb-0 mt-4" v-if="!isVisible">
            <b-icon v-if="!isLoggedIn" @click="redirect()" class="float-right mr-4 btn-fav" icon="bookmark"
                variant="warning"
                v-b-popover.hover.top="$i18n.locale === 'Français' ? 'Ajouter aux favoris' : 'إضافة إلى المفضلة'">
            </b-icon>
            <b-icon v-else @click="changeIcon" class="float-right mr-4 btn-fav" :icon="iconBook" variant="warning"
                >
            </b-icon>
            <!-- v-b-popover.hover.top="$i18n.locale === 'Français' ? 'Supprimer des favoris' : 'حذف من المفضلة'" -->
            <!-- Display the list-stars button only when iconBook is clicked or hotel is in favorites -->
            <router-link :to="{ name: 'Mesfavoris' }">
                <b-icon v-if="isLoggedIn" icon="list-stars" class="float-right mr-4 btn-list"
                    font-scale="1.2"
                    v-b-popover.hover.top="$i18n.locale === 'Français' ? 'Mes favoris' : 'الاختيارات المحفوظة'"></b-icon>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarouselComponent',
    props: ['startingImage', 'images', 'autoSlideInterval', 'showProgressBar', 'guide_id', 'activite_id', 'riad_id', 'maison_id',
        'restaurant_id', 'hotel_id', 'isVisible', 'isfav', 'favid',
    ],
    data() {
        return {
            isLoggedIn: false,
            iconBook: 'bookmark',
            BaseUrl: process.env.VUE_APP_API_URL,
            //Index of the active image
            activeImage: 0,
            //Hold the timeout, so we can clear it when it is needed
            autoSlideTimeout: null,
            //If the timer is stopped e.g. when hovering over the carousel
            stopSlider: false,
            //Hold the time left until changing to the next image
            timeLeft: 0,
            //Hold the interval so we can clear it when needed
            timerInterval: null,
            //Every 10ms decrease the timeLeft
            countdownInterval: 10,
            // path image for modal
            modal_image_path: [],
            // mutableIsFav: JSON.parse(this.isfav),
            bookValue: false,
            iconBookClicked: false,
            //isVisible:true,
        }
    },
    mounted() {
        this.isLoggedIn = !!localStorage.getItem('token');
        this.$props.isfav === 1 ? this.iconBook = 'bookmark-star-fill' : this.iconBook = 'bookmark';
        this.$props.isfav === 1 ? this.bookValue = true : this.bookValue = false;
        console.log('book vale ' + this.bookValue)
        console.log('iconbook is ' + this.iconBook)
    },
    computed: {

        // currentImage gets called whenever activeImage changes
        // and is the reason why we don't have to worry about the
        // big image getting updated
        currentImage() {
            this.changes(this.autoSlideInterval);
            return this.images[this.activeImage];
        },
        progressBar() {
            //Calculate the width of the progressbar
            return 100 - (this.timeLeft / this.autoSlideInterval) * 100;
        },
        listImages() {
            const items = this.images;
            return items.slice(0, 6)
        },
        resterImages() {
            const items = this.images;
            return items.slice(6, this.images.length)
        }
    },
    methods: {
        redirect() {
            const redirectPath = this.$route.fullPath; // Saving current route
            // this.$router.push({ name: 'Login' })
            this.$router.push({ name: 'Login', query: { redirect: redirectPath } });
            this.$toaster.error('Connectez – vous pour accéder à votre espace.');
        },
        changeIcon() {
            this.bookValue = !this.bookValue;
            this.iconBook = this.bookValue ? 'bookmark-star-fill' : 'bookmark';
            if (this.bookValue) {
                const fav = {
                    user_id: localStorage.getItem('id'),
                    id_hotel: this.$props.hotel_id,
                    id_riad: this.$props.riad_id,
                    id_maison_hote: this.$props.maison_id,
                    id_restaurant: this.$props.restaurant_id,
                    id_activite: this.$props.activite_id,
                    id_guide: this.$props.guide_id,
                }
                this.$emit('AddFavorite', fav)
                this.iconBookClicked = true;
            } else {
                const id = this.$props.favid;
                this.$emit('DelteFavorite', id)
                // this.$toaster.error('Votre commentaire a été enregistré.')
                this.iconBookClicked = false;

            }
        },
        changes(autoSlideInterval) {
            this.timeLeft = autoSlideInterval;
        },
        // Go forward on the images array
        // or go at the first image if you can't go forward
        nextImage() {
            var active = this.activeImage + 1;
            if (active >= this.images.length) {
                active = 0;
            }
            this.activateImage(active);
        },
        // Go backwards on the images array
        // or go at the last image
        prevImage() {
            var active = this.activeImage - 1;
            if (active < 0) {
                active = this.images.length - 1;
            }
            this.activateImage(active);
        },
        activateImage(imageIndex) {
            this.activeImage = imageIndex;
        },
        //Wait until 'interval' and go to the next image;
        startTimer(interval) {
            if (interval && interval > 0 && !this.stopSlider) {
                var self = this;
                clearTimeout(this.autoSlideTimeout);
                this.autoSlideTimeout = setTimeout(function () {
                    self.nextImage();
                    self.startTimer(self.autoSlideInterval);
                }, interval);
            }
        },
        //Stop the timer when hovering over the carousel
        stopTimer() {
            clearTimeout(this.autoSlideTimeout);
            this.stopSlider = true;
            clearInterval(this.timerInterval);
        },
        //Restart the timer(with 'timeLeft') when leaving from the carousel
        restartTimer() {
            this.stopSlider = false;
            clearInterval(this.timerInterval);
            this.startCountdown();
            this.startTimer(this.timeLeft);
        },
        //Start countdown from 'autoSlideInterval' to 0
        startCountdown() {
            if (!this.showProgressBar) return;
            var self = this;
            this.timerInterval = setInterval(function () {
                self.timeLeft -= self.countdownInterval;
                if (self.timeLeft <= 0) {
                    self.timeLeft = self.autoSlideInterval;
                }
            }, this.countdownInterval);
        },
        open_modal(image_path) {
            this.modal_image_path = image_path
            this.$bvModal.show('showImageModal')
        }

    },
    created() {
        //Check if startingImage prop was given and if the index is inside the images array bounds
        if (this.startingImage
            && this.startingImage >= 0
            && this.startingImage < this.images.length) {
            this.activeImage = this.startingImage;
        }

        //Check if autoSlideInterval prop was given and if it is a positive number
        if (this.autoSlideInterval
            && this.autoSlideInterval > this.countdownInterval) {
            //Start the timer to go to the next image
            this.startTimer(this.autoSlideInterval);
            this.timeLeft = this.autoSlideInterval;
            //Start countdown to show the progressbar
            this.startCountdown();
        }
    },



}
</script>


<style scoped>
.btn-fav {
    cursor: pointer;
}

.btn-list {
    cursor: pointer;
    color: #bc0420;
}

.responsive-img {
    height: 100px !important;
    width: 100% !important;
    max-height: 100 !important;
    min-height: 100px !important;
    object-fit: cover !important;
    object-position: center cencter !important;
}

.card-carousel {
    user-select: none;
    position: relative;
}

.progressbar {
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    background-color: rgba(221, 221, 221, 0.25);
    z-index: 1;
}

.progressbar>div {
    background-color: rgba(255, 255, 255, 0.52);
    height: 100%;
}

.thumbnails {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
}

.thumbnail-image>img {
    width: 100%;
    height: auto;
    transition: all 250ms;
}

.thumbnail-image:hover>img,
.thumbnail-image.active>img {
    opacity: 0.6;
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
}

.card-img {
    position: relative;
    margin-bottom: 15px;
}

.card-img>img {
    display: block;
    margin: 0 auto;
}

.actions {
    font-size: 1.5em;
    height: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #585858;
}

.actions>span {
    cursor: pointer;
    transition: all 250ms;
    font-size: 45px;
}

.actions>span.prev {
    margin-left: 5px;
}

.actions>span.next {
    margin-right: 5px;
}

.actions>span:hover {
    color: #eee;
}


.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

@media screen and (max-width: 768px) {
    .card-img>img {

        max-width: 320px !important;
        max-height: 300px !important;
    }
}
</style>
