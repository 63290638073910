<template>
  <div class="container-fluid">
    <div class="container ">
      <div class="row">
        <div class="col-md-12 page-404" style="padding-top: 100px">
            <div class="text-center">
                  <h2 class="title">      
                      <i class='fa fa-check-circle' style='color:#de6401;font-size:100px;margin-bottom:20px;'></i><br>
                     Vous avez bien vérifié votre adresse e-mail</h2>

                     <router-link to="/login"> Accéder maintenant à votre compte.</router-link>
             </div>
        </div>
 
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "VerificationEmailComponent",
  components: {  }
}
</script>

<style scoped>

.page-404 title{

    font-size: 80px;
    
}
.page-404 .number{

    font-size: 180px;
    
}

</style>