<template>
  <div class="container min-height">

    <!-- <Rubrique></Rubrique>


    <div class="row justify-content-center mt-5">
      <div class="col-md-10">


        <div class="row">
          <div class="col-md-12">
            <h1 class="" style="display: inline-block"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                src="@/assets/images/img-bladi.png" />Espace voyageur</h1>
            <router-link to="/">
              <b-icon-arrow-left class="arrow-left float-right color-primary"></b-icon-arrow-left>
            </router-link>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row top-forme justify-content-center mt-4">
      <div class="col-md-10 bg-white p-5">

        <div class="mt-4 ">
          <h4><strong>Félicitation </strong>: <br> <br>
            Lorsque vous vous inscrivez, un e-mail d'activation vous est envoyé à l'adresse e-mail que vous avez
            indiquée.
            Merci de vérifier votre boîte de réception pour activer votre compte .</h4>
          <router-link class="btn btn-acceder-compte rounded-pill p-3 mt-4" to="/verification-email"> Vérifier
            maintenant votre compte</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import User from "@/apis/User";
// import Rubrique from "@/components/Outils/Rubrique";
export default {
  name: "MonCompteComponent",
  // components: { Rubrique },
  data() {
    return {
      user: {},
      currentUser: {},
    }
  },
  methods: {


  },

}
</script>

<style scoped>
.btn-acceder-compte {
  color: white;
  background-color: #de6401;
}
</style>