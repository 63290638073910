<template>
  <div class="container-fluid ">


    <Rubrique></Rubrique>


    <div class="container">
      <div class="row pt-5">
        <div class="col-md-9 ">
          <div class="row">
            <div class="col-md-12 ">
              <h1 class="" ><img style="margin-top: -15px;margin-right: 15px;width: 55px;" src="@/assets/images/img-bladi.png" />Partages</h1>

              <div class="row search">
                <div class="col-md-12">
                  <div class="form-group">
                    <input class="form-control form-control-sm " type="text"  style="font-family: 'Lato', cursive;padding-left: 10px" placeholder="Recherche par mots clés...">
                  </div>
                </div>
              </div>

              <div id="gallery">
                <div v-for="i in 20" :key="i">
                  <router-link :to="'/partage/1?img=https://placeimg.com/'+(i+300)+'/'+(i+300)+'/nature'" >
                    <img :src="'https://placeimg.com/'+(i+300)+'/'+(i+300)+'/nature'">
                  </router-link>
                </div>

              </div>

            </div>
          </div>


        </div>
        <div class="col-md-3 sidebar">

          <div class="row mt-5 p-2 justify-content-center text-center">

            <div class="col-md-8 ">
              <div class="card p-2" style="width: 18rem;">
                <img class="card-img-top" src="@/assets/images/lieux-a-visiter.png" alt="Card image cap">
                <div class="card-body">
                  <router-link to="/voyageur/dashbord" class=""><h5 class="card-title">Lieux à visiter</h5></router-link>
                </div>
              </div>
            </div>

            <div class="col-md-8">
              <div class="card p-2" style="width: 18rem;">
                <img class="card-img-top" src="@/assets/images/gastronomie.png" alt="Card image cap">
                <div class="card-body">
                  <router-link to="/voyageur/dashbord" class=""><h5 class="card-title">Gastronomie</h5></router-link>
                </div>
              </div>
            </div>

            <div class="col-md-8 ">
              <div class="card p-2" style="width: 18rem;">
                <img class="card-img-top" src="@/assets/images/evenement.png" alt="Card image cap">
                <div class="card-body">
                  <router-link to="/voyageur/dashbord" class=""><h5 class="card-title">Evènements</h5></router-link>
                </div>
              </div>
            </div>

            <div class="col-md-8">
              <div class="card p-2" style="width: 18rem;">
                <img class="card-img-top" src="@/assets/images/tem.png" alt="Card image cap">
                <div class="card-body">
                  <router-link to="/voyageur/dashbord" class=""><h5 class="card-title">Témoignages</h5></router-link>
                </div>
              </div>
            </div>

            <div class="col-md-8 ">
              <div class="card p-2" style="width: 18rem;">
                <img class="card-img-top" src="@/assets/images/com.png" alt="Card image cap">
                <div class="card-body">
                  <router-link to="/voyageur/dashbord" class=""><h5 class="card-title">Partages</h5></router-link>
                </div>
              </div>
            </div>


          </div>


        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Rubrique from "@/components/Outils/Rubrique";


export default {
  name: "PartageComponent",
  components:{Rubrique},
  methods:{
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }
}
</script>

<style scoped>

#gallery {
  line-height:0;
  -webkit-column-count:3; /* split it into 5 columns */
  -webkit-column-gap:5px; /* give it a 5px gap between columns */
  -moz-column-count:3;
  -moz-column-gap:5px;
  column-count:3;
  column-gap:5px;
}


#gallery img {
  width: 100% !important;
  height: auto !important;
  margin-bottom:5px; /* to match column gap */
  border-radius:15px;
}


@media (max-width: 1200px) {
  #gallery {
    -moz-column-count:    4;
    -webkit-column-count: 4;
    column-count:         4;
  }
}

@media (max-width: 1000px) {
  #gallery {
    -moz-column-count:    3;
    -webkit-column-count: 3;
    column-count:         3;
  }
}

@media (max-width: 800px) {
  #gallery {
    -moz-column-count:    2;
    -webkit-column-count: 2;
    column-count:         2;
  }
}


@media (max-width: 400px) {
  #gallery {
    -moz-column-count:    1;
    -webkit-column-count: 1;
    column-count:         1;
  }
}



#gallery img:hover {
  filter:none;
}

</style>