<template>
  <div class="container-fluid" v-if="token">
    <div class="container min-height">
      <div class="row espace-voyageur top-forme justify-content-center mt-5">
        <div class="col-md-8 col-12 bg-white p-5 bg-login">
          <div class="row">
            <div class="container">
              <h1 class=""><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                  src="@/assets/images/img-bladi.png" />Réinitialiser le mot de passe</h1>
              <form @submit.prevent="submit">
                <div class="col-md-11 mt-3">
                  <input v-model="email" type="email" class="form-control" placeholder="Entrez votre email">
                </div>
                <div class="col-md-11 mt-3">
                  <input v-model="password" type="password" class="form-control"
                    placeholder="Entrez le nouveau mot de passe">
                </div>
                <div class="col-md-11 mt-3">
                  <input v-model="password_confirmation" type="password" class="form-control"
                    placeholder="Confirmez le nouveau mot de passe">
                </div>
                <div class="col-md-11 mt-3">
                  <button type="submit" class="btn btn-success mr-2">Réinitialiser le mot de passe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http-common";

export default {
  data() {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      token: this.$route.query.token,
    };
  },
  methods: {
    async submit() {
      try {
        await http.post('/reset-password', {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token,
        });
        this.$toaster.success("Le mot de passe a été réinitialisé avec succès.");
        this.$router.push('/login');
      } catch (error) {
        console.error(error);
        alert('An error occurred.');
      }
    },
  },
};
</script>
<style scoped>
.btn-success {
  background-color: white !important;
  background: white !important;
  color: #1e594d !important;
  border: 1px solid #1e594d !important;
  padding: 1px 40px;
  border-radius: 25px;
  font-family: GillSansMT;
  font-size: 20px;
}

.btn-success:hover {
  background-color: #008ec6 !important;
  background: #008ec6 !important;
  border: 1px solid #008ec6 !important;
  color: white !important;
}

.espace-voyageur a {
  font-family: GillSansMT;
  font-size: 14px !important;
  background: none !important;
  background: none !important;
  color: #008ec6 !important;
  padding: none !important;
  border-radius: none !important;
}

.bg-login {
  background: transparent url('@/assets/images/bg-connexion.png') no-repeat;
  background-size: cover;
  width: 450px;
  padding: 54px;
  margin: auto;
  height: 490px;
  /* margin-left: -17px;
      margin-right: -17px;
      margin-top: -17px;
      height: 414px; */
}

.form-control:focus,
.password-eye:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.password-eye {
  background-color: #eeeeee;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid #ced4da;
}

.password-eye i {
  color: #bbbbbb;
}

@media screen and (max-width: 700px) {
  .bg-login {
    background: transparent url('@/assets/images/fond-mobile-login.png') no-repeat;
    /* background-size: cover;
      width: 450px;
      padding: 54px;
      margin: auto;
      height: 490px;
       margin-left: -17px;
      margin-right: -17px;
      margin-top: -17px;
      height: 414px; */
    /*  background-size: cover;
      padding-top: 180px!important;
      padding-bottom: 140px!important;
      height: auto;*/
    background-size: contain;
  }
}
</style>