<template>
  <div class="carousel" id="cont-carousel">
    <div class="carousel-inner">
      <div v-for="(chunk, chunkIndex) in chunkedItems" :key="chunkIndex"
        :class="['carousel-item', { active: chunkIndex === currentIndex }]">
        <div class="row">
          <div v-for="(item, itemIndex) in chunk" :key="itemIndex" class="col-md-4 ">
            <router-link :to="`/${item.url}/` + ($i18n.locale === 'Français' ? item.slug : item.slug_ar)">
              <div class="cont">
                <div :style="{ backgroundImage: `url('${BaseUrl}/storage/${item.images[0].image}')` }" class="card-img">
                  <input type="button"
                    :value="$i18n.locale === 'Français' ? 'A Voir' : ($i18n.locale === 'العربية' ? 'اطلع الآن' : 'View')"
                    style="" class="btn btn-voir">

                </div>
                <!-- <div class="cont-image">
                    <img v-if="item.images && item.images.length > 0" style="" class="item-img" :src="BaseUrl + '/storage/' + item.images[0].image" />
                  </div> -->
                <!-- <div style="height: 25px;display: flex;width: 33%;align-items: center;font-size: 14px;justify-content: center;" class="btn btn-success">A Voir</div> -->
                <div class="card-body" style="padding: 0px !important;padding-top: 10px;">
                  <!-- <h5 class="card-title">{{ item.nom }}</h5> -->
                  <h5 class="card-title" v-if="$i18n.locale === 'Français'">{{ item.nom ?? item.nom_ar }}</h5>
                  <h5 class="card-title" v-else-if="$i18n.locale === 'English'">{{ item.nom_en ?? item.nom }}</h5>
                  <h5 class="card-title" v-else>{{ item.nom_ar ?? item.nom }}</h5>

                  <p style="font-size: medium; color: gray;" class="adress">
                    <img src="@/assets/images/map-marker.png" class="map-marker" />
                    {{ item.adresse }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <p class="carousel-control-prev" style="left: -4%;top: 50%;" role="button" @click="prevSlide">
      <img src="../../public/right-chevron.png"
        style="height: 40px;width:40px;border-radius: 50%;transform: rotate(180deg);padding: 5px;transform: rotate(180deg);"
        alt="" srcset="">
    </p>
    <p class="carousel-control-next" style="right: -4%;top: 50%;" role="button" @click="nextSlide">
      <img src="../../public/right-chevron.png" style="height: 40px;width:40px;border-radius:50%;padding: 5px;" alt=""
        srcset="">
    </p>
  </div>
</template>
<script>
export default {
  name: 'MesCarousel',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIndex: 0,
      BaseUrl: process.env.VUE_APP_API_URL,
      itemsPerSlide: 3
    };
  },
  computed: {
    chunkedItems() {
      // Divide items into chunks based on itemsPerSlide value
      const chunks = [];
      let i, j;
      for (i = 0, j = this.items.length; i < j; i += this.itemsPerSlide) {
        chunks.push(this.items.slice(i, i + this.itemsPerSlide));
      }
      return chunks;
    }
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.chunkedItems.length) % this.chunkedItems.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.chunkedItems.length;
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(() => {
        this.nextSlide();
      }, 5000); // Autoplay interval (8 seconds)
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
    }
  },
  mounted() {
    //this.startAutoplay(); // Start autoplay when component is mounted
  },
  beforeDestroy() {
    this.stopAutoplay(); // Stop autoplay when component is destroyed
  }
};
</script>
<style scoped>
#cont-carousel {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  min-height: 450px;
  display: flex;
  width: 90%;
  margin: auto;
}

.carousel {
  position: relative;
}

.card-title {
  text-transform: capitalize;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.cont {
  padding: 5px;
}

.cont:hover {
  border: 3px solid #dc6a0a;
}

.carousel-item.active {
  display: block;
  opacity: 1;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  text-decoration: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  /* background-color: #fff; */
  margin-top: -1px;
}

a,
a:hover {
  color: black !important;
  text-decoration: none;
}

.btn-voir,
.btn-voir:hover {
  height: 25px;
  display: flex;
  width: 33%;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  padding: 1px;
  background-color: #6b9d06;
  color: white;
  border-radius: 5px;
  /* box-shadow: 1px 1px 1px black; */
}

.item-img {
  max-width: 100%;
  /* margin-bottom: 10px; */
  border-radius: 10px;
  max-height: 100%;
}

.cont-image {
  display: flex;
  justify-content: center;
  /* background-color: rgb(185 179 179 / 30%); */
  max-height: 250px;
  /* padding: 10px; */
  border-radius: 10px;
  margin-bottom: 20px;
}

.card-img {
  width: 100%;
  height: 230px;
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  padding: 0px 10px 10px 10px;
}

/* Fade-in transition */
.carousel-item {
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  #cont-carousel {
    width: 100%;
  }
}
</style>