<template>
    <div>
        <div class="col-md-12 pb-5 text-center rubrique-pro">
            <div class="row dir-rtl">
                <div class="col-md-3 col-6 info-compte mb-2">
                    <div class="m-auto w-150px">
                        <router-link to="/professionnel/informations-compte" class="active">{{$t('espace_professionnel.infos_compte')}}
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3 col-6 coordonnees-activite mb-2">
                    <div class="m-auto w-150px">
                        <a>{{$t('espace_professionnel.crdns_activite')}}
                        </a>
                    </div>
                </div>
                <div class="col-md-3 col-6 descriptif mb-2">
                    <div class="m-auto w-150px">
                        <a>{{$t('espace_professionnel.descriptif')}}</a>
                    </div>
                </div>
                <div class="col-md-3 col-6 photos mb-2">
                    <div class="m-auto w-150px">
                        <a>{{$t('espace_professionnel.photos')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.rubrique-pro .info-compte a, .rubrique-pro .info-compte a:hover {
    color: #8c2c16;
    border-color: #8c2c16;
    padding: 20px;
}

.rubrique-pro .info-compte .active {
    color:  white !important;
    border-color: #8c2c16!important;
    background-color: #8c2c16!important;
    padding: 20px;
}

.rubrique-pro .coordonnees-activite a, .rubrique-pro .coordonnees-activite a:hover {
    color: #be671a;
    border-color: #be671a;
}

.rubrique-pro .coordonnees-activite .active {
    color:  white !important;
    background-color: #be671a;
}

.rubrique-pro .descriptif a, .rubrique-pro .descriptif a:hover {
    color: #996c45;
    border-color: #996c45;
}

.rubrique-pro .descriptif .active{
    color:  white !important;
    background-color: #996c45;
}


.rubrique-pro .photos a, .rubrique-pro .photos a:hover {
    color: #baa06e;
    border-color: #baa06e;
}

.rubrique-pro .photos .active {
    color:  white !important;
    background-color: #baa06e;
}


.rubrique-pro a {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid;
    float: left;
    border-radius: 98px;
    height: 150px;
    width: 150px;
    padding: 20px;
    /* color: #3ca2c6 !important; */
}

a {
    font-size: 20px;
    line-height: 1.1;
    text-decoration: none !important;
}
</style>