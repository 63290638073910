<template>
  <div class="container-fluid">
    <div class="container min-height mt-5">
      <div class="row espace-prof top-forme justify-content-center text-left">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro :niveau2="1" :niveau3="1" :niveau4="0" :level="isDataAvailable" />
          <div class="row  descriptif-body p-3 dir-rtl">
            <div class="col-md-12">
              <div class="form-group">
                <label for="description">{{ $t('details_service.description') }} <sup>*</sup></label>
                <textarea style="width:100%;" v-model="form.description" id="description" name="description"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_en">{{ $t('details_service.description_en') }}</label>
                <textarea style="width:100%;" v-model="form.description_en" id="description_en" name="description_en"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_en.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_en.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_ar">{{ $t('details_service.description_ar') }}</label>
                <textarea style="width:100%;" v-model="form.description_ar" id="description_ar" name="description_ar"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_ar.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_ar.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12 m-4 ">
              <b-table class="b-table mb-5" :items="items" :fields="fields" fixed>
                <template v-slot:cell(actions)="{ item }">
                  <span><button class="btn btn-sm btn-danger" @click="deleteItem(item)"><i
                        class="fa fa-times"></i></button></span>
                  <span><button class="btn btn-sm btn-warning" @click="selectItem(item)"><i
                        class="fa fa-edit"></i></button></span>
                </template>
              </b-table>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">{{ $t('details_service.type_de_chambre') }}</label>
                    <vue-bootstrap-typeahead v-model="formTable.type" showOnFocus :data="optionsType" ref="type"
                      :minMatchingChars="0" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">{{ $t('details_service.prix_apartir') }}</label>
                    <input type="number" name="" id="" v-model="formTable.prix" class="form-control" ref="prix"
                      placeholder="" aria-describedby="helpId" required>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">{{ $t('details_service.options') }}</label>
                    <vue-bootstrap-typeahead v-model="formTable.options" showOnFocus :data="optionsListe" ref="options"
                      :minMatchingChars="0" />
                  </div>
                </div>
                <div class="col-md-4 pl-0">
                  <div class="form-group">
                    <label for="">{{ $t('details_service.autre_chambre') }}</label>
                    <button v-if="!isEdit" class="form-control btn btn-primary bg-msafar-primary btn-add"
                      @click="addItem">{{
            $t('forms.generale.ajouter') }} </button>
                    <div class="btn-inline rtl-right" v-else>
                      <button class="form-control btn btn-primary bg-msafar-primary" @click="editItem"> {{
            $t('forms.generale.enregistrer')
          }}
                      </button>
                      <button class="form-control btn btn-secondary " @click="cancelEdit"> {{
            $t('forms.generale.annuler')
          }} </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 ml-4">
              <h4>{{ $t('forms.hotel_pro.prix_min_chambre') }}</h4>
              <div class="form-group form-row mt-4">
                <label for="prix_min">{{ $t('forms.generale.prix_min') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_min" id="prix_min" name="prix_min" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_min.$error }" />
                <div v-if="submitted && !$v.form.prix_min.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
            name: $i18n.locale === 'Français' ? 'Prix Min' : 'السعر الأدنى'
          })
                  }}
                </div>
              </div>
              <div class="form-group form-row">
                <label for="prix_max">{{ $t('forms.generale.prix_max') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_max" id="prix_max" name="prix_max" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_max.$error }" />
                <div v-if="submitted && !$v.form.prix_max.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
            name: $i18n.locale === 'Français' ? 'Prix Max' : 'السعر الأقصى'
          })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pb-3 pl-5">
              <label for="" style="font-size:25px;"> {{ $t('forms.generale.autre_services') }}</label><br> <br>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group id="checkbox-group-1" class="row" :aria-describedby="ariaDescribedby"
                  name="service" v-model="checked" :options="options" value-field="id"
                  :text-field="[$i18n.locale === 'Français' ? 'nom' : $i18n.locale === 'English' ? 'nom_en' : 'nom_ar']">
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div :class="[this.$i18n.locale === 'العربية' ? 'float-right' : '', 'col-md-12 mt-5 p-5']">
              <button type="submit"
                :class="[isDataAvailable ? 'btn-outline-msafar' : 'bg-msafar-primary text-white', 'btn rounded-pill pl-4 pr-5 pt-2 pb-2']"
                @click="validate">
                <i class="fas fa-check-circle mr-3"></i>
                <span v-if="isDataAvailable">{{ $t('forms.inscription.modifier') }}</span>
                <span v-else>{{ $t('forms.inscription.valider_pro') }}</span>
              </button>
              <div v-if="isDataAvailable" :class="this.$i18n.locale === 'العربية' ? 'float-left' : 'float-right'">
                <button type="button" @click="etapeSuivante()"
                  class="bg-msafar-primary btn text-white rounded-pill  pl-5 pr-5 pt-2 pb-2">
                  {{ $t('forms.inscription.etapes') }}
                  <span class="h1 color-msafar-primary flesh-profil" style="">
                    <b-icon-chevron-left v-if="this.$i18n.locale === 'العربية'"
                      style="margin-right: 40px;margin-bottom: 20px;"></b-icon-chevron-left>
                    <b-icon-chevron-right v-else></b-icon-chevron-right>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import User from "@/apis/User";
import Service from "@/apis/Services";
import http from "./../../http-common";
import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap'
import { required } from 'vuelidate/lib/validators';

//import Table from "@/components/ProfilProfessionnel/Table";
//import Table2 from "@/components/EspaceProfessionnel/Table2";
//import Api from "@/apis/Api";


export default {
  name: "DescriptifComponent",
  components: { RubriquePro, VueBootstrapTypeahead },
  computed: {
    fields() {
      //https://github.com/bootstrap-vue/bootstrap-vue/issues/3409
      const lang = this.$i18n.locale
      if (lang === 'Français') {
        return [
          { key: "type", label: "Type Chambre", type: "option", options: ['Chambre individuelle', 'Chambre double', 'Suite'] },
          { key: "prix", label: "Prix", type: "number" },
          { key: "options", label: "Options", type: "option", options: ['Demi-pension', 'pension complete', 'Demi-pension /pension complete'] },
          { key: "actions", label: "Actions" }
        ];
      } else if (lang === 'English') {
        return [
          { key: "type", label: "Room Type", type: "option", options: ["Single Room", "Double Room", "Suite"] },
          { key: "prix", label: "Price", type: "number" },
          { key: "options", label: "Options", type: "option", options: ["Half Board", "Full Board", "Half Board / Full Board"] },
          { key: "actions", label: "Actions" }
        ];
      } else {
        return [
          { key: "type", label: "نوع الغرفة", type: "option", options: ["غرفة مفردة", "غرفة مزدوجة", "جناح"] },
          { key: "prix", label: "الثمن", type: "number" },
          { key: "options", label: "خيارات", type: "option", options: ["نصف إقامة", "إقامة كاملة", "نصف إقامة / إقامة كاملة"] },
          { key: "actions", label: "إجراءات" }
        ];
      }
    },
    optionsType() {
      const lang = this.$i18n.locale
      if (lang === 'Français') {
        return [
          'Chambre individuelle', 'Chambre double', 'Suite'
        ];
      } else if (lang === 'English') {
        return [
          'Single Room', 'Double Room', 'Suite'
        ];
      } else {
        return [
          'غرفة مفردة', 'غرفة مزدوجة', 'جناح'
        ];
      }

    },
    optionsListe() {
      const lang = this.$i18n.locale
      if (lang === 'Français') {
        return ['Demi-pension (déjeuner)', 'Demi-pension (dîner)', 'Pension complète', 'Tout Inclus'];
      } else if (lang === 'English') {
        return ['Half Board (Lunch)', 'Half Board (Dinner)', 'Full Board', 'All Inclusive'];
      } else {
        return ["نصف إقامة", "إقامة كاملة", "نصف إقامة / إقامة كاملة"];
      }
    }

  },
  data() {
    return {
      form: {
        description: "",
        description_ar: "",
        description_en: "",
        prix_min: "",
        prix_max: ""
      },
      columns: [
        "Type de chambre",
        "Nombre de personnes",
        "Options",
        "Prix à partir de",
        "Actions "
      ],
      persons: [

      ],
      id_hotel: "",
      service: [],
      options: [],
      //fields: ["Type Chambre","Prix","Options","Actions"],
      // fields: [
      //   { key: "type", label: "Type Chambre", type: "option", options: ['Chambre individuelle', 'Chambre double', 'Suite'] },
      //   { key: "prix", label: "Prix", type: "number" },
      //   { key: "options", label: "Options", type: "option", options: ['Demi-pension', 'pension complete', 'Demi-pension /pension complete'] },
      //   "Actions"
      // ],
      items: [
      ],
      hebergementServices: [],
      checked: [],
      tableItems: [],
      formTable: {
        type: "",
        prix: "",
        options: "",
      },
      // optionsType: ['Chambre individuelle', 'Chambre double', 'Suite'],
      // optionsListe: ['Demi-pension (déjeuner)', 'Demi-pension (dîner)', 'Pension complète', 'Tout Inclus'],
      isEdit: false,
      currentItem: [],
      messages: [],
      commentaires: [],
      submitted: false,
      isDataAvailable: false
    }
  },
  validations: {
    form: {
      description: { required },
      // description_ar: { required },
      // description_en: { required },
      prix_min: { required },
      prix_max: { required },
    }
  },
  methods: {
    etapeSuivante() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.isDataAvailable) {
        this.$router.push("/profil-pro/photos");
      } else {
        this.$toaster.info('Merci de valider le formulaire pour passer à l\'étape suivante');
      }
    },
    validate() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      Service.updateHebergement(this.form.id, this.user.type_service, this.form).then((response) => {
        this.form = response.data;
        this.isDataAvailable = true;
        this.addServices();
        this.$toaster.success('Votre espace professionnel a bien été modifié')
        this.$router.push("/profil-pro/photos");

      }).catch(error => {
        console.log(error);
      });
    },
    addItem() {
      this.formTable.id_riad = this.form.id;
      http.post(`/chambres`, this.formTable)
        .then((response) => {
          let obj = { 'id': response.data.id, 'type': response.data.type, 'prix': response.data.prix, 'options': response.data.options }
          this.items.push(obj);
          this.$toaster.success('Chambre a bien été ajouté');
          this.cancelEdit();
        })
        .catch(e => {
          console.log(e.data);
        })
    },
    cancelEdit() {
      this.$refs.type.inputValue = '';
      this.$refs.options.inputValue = '';
      this.$refs.prix.inputValue = '';
      this.isEdit = false;
      this.formTable.prix = '';
    },

    selectItem(item) {
      this.currentItem = item;
      this.formTable.prix = item.prix;
      this.formTable.type = item.type;
      this.formTable.options = item.options;
      this.$refs.type.inputValue = item.type;
      this.$refs.options.inputValue = item.options;
      this.isEdit = true;
    },
    editItem() {
      this.formTable.id = this.currentItem.id;

      http.put(`/chambres/${this.currentItem.id}`, this.formTable)
        .then((response) => {
          let objEdited = { 'id': response.data.id, 'type': response.data.type, 'prix': response.data.prix, 'options': response.data.options }
          this.items = this.items.map(obj => {
            return parseInt(obj.id) === parseInt(this.currentItem.id) ? objEdited : obj
          });
          this.$toaster.success('Chambre a bien été modifié')
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    deleteItem(item) {

      http.delete(`/chambres/${item.id}`)
        .then(() => {

          this.items = this.items.filter(obj => obj.id != item.id);
          console.log('Remove Index');
          console.log(this.items)
          // ~removeIndex && this.items.splice(removeIndex,1);
          this.$toaster.success('Chambre a bien été supprimé')
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    addServices() {
      var formData = new FormData();
      console.log(this.checked);
      for (var i = 0; i < this.checked.length; i++) {
        formData.append('services[]', this.checked[i]);
      }
      formData.append('id_riad', this.form.id);

      http.post('/hotel-liste-services', formData)
        .then(() => {
          //console.log(response.data);
        })
        .catch(e => {
          console.log(e);
          //this.$toaster.error(e.data)
        })
    },
    getUser() {
      User.auth().then((response) => {
        //console.log(response.data);
        this.user = response.data;
        this.getHebergement();
      }).catch(error => {
        console.log(error);
      });
    },
    getHebergement() {
      console.log(this.user.id + ',' + this.user.type_service);
      Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
        this.form = response.data;
        this.isDataAvailable = response.data.length !== 0 && response.data.description.length !== 0;
        // this.listeMessages(response.data.id);
        // this.getCommentaires(response.data.id);
        this.hebergementServices = response.data.hebergement_service;
        this.checked = this.hebergementServices.map(({ id_service }) => id_service);
        this.items = response.data.chambres.map(function (chambre) {
          return { 'id': chambre.id, 'type': chambre.type, 'prix': chambre.prix, 'options': chambre.options };
        });

      }).catch(error => {
        console.log(error);
      });

    },
    getItems() {
      return this.items;
    },
    listeServices() {
      http.get('/services')
        .then((response) => {
          this.options = response.data
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    // getCommentaires(id) {
    //   http.get(`/commentaires/riad/${id}`).then(response => {
    //     this.commentaires = response.data;
    //   }).catch((errors) => {
    //     console.log(errors)
    //   });
    // },
    // listeMessages(id) {
    //   http.get(`/reservations/riad/${id}`)
    //     .then((response) => {
    //       this.messages = response.data
    //     })
    //     .catch(e => {
    //       console.log(e.data);
    //     })

    // },


  },
  mounted() {




  },
  created() {
    this.getUser();
    this.listeServices();
    // console.log("Created");
    // console.log(this.$hotel.value);
    // console.log(this.form.hotel_service);
  }
}
</script>

<style scoped>
.table thead th {
  border: 1px solid #bc4c58 !important;
  border-radius: 83px !important;
  text-align: center;
  color: #bc4c58;
  vertical-align: bottom;
}

.table thead th:last-child {
  border: 0px !important;
}

table {
  border-collapse: initial;
}



.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #ff9521;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #ff9521 !important;
}


.btn-warning,
.btn-danger,
.btn-primary {
  border: 0;
  padding: 4px 10px 4px 10px !important;
  margin: 0px 5px;
  max-width: 90%;
}

.btn-add {
  /* margin-top: 30px; */
}

.btn-inline {
  display: flex;
}

.message {
  background-color: #c6f0fc;
  border-radius: 25px;
  margin-bottom: 10px;
  padding: 15px;
}

.message p {
  padding: 2px;
  margin: 0px;
}

.message a {
  color: black;
}
</style>