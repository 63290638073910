<template>
  <div class="container-fluid">
    <div class="container min-height mt-5">
      <div class="row espace-prof top-forme justify-content-center text-left">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro :niveau2="1" :niveau3="1" :niveau4="0" />
          <div class="row  descriptif-body p-3 dir-rtl">
            <div class="col-md-12">
              <div class="form-group">
                <label for="description">{{ $t('details_service.description') }} <sup>*</sup></label>
                <textarea style="width:100%;" v-model="form.description" id="description" name="description"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_en">{{ $t('details_service.description_en') }}</label>
                <textarea style="width:100%;" v-model="form.description_en" id="description_en" name="description_en"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_en.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_en.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_ar">{{ $t('details_service.description_ar') }}</label>
                <textarea style="width:100%;" v-model="form.description_ar" id="description_ar" name="description_ar"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_ar.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_ar.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'Français' ? 'description' : 'الوصف' })
                  }}
                </div>
              </div>
            </div>
            <!-- <div class="col-md-12 m-4 ">
              <b-table class="b-table mb-5" :items="items" :fields="fields" fixed>
                <template v-slot:cell(actions)="{ item }">
                  <span><button class="btn btn-sm btn-danger" @click="deleteItem(item)"><i
                        class="fa fa-times"></i></button></span>
                  <span><button class="btn btn-sm btn-warning" @click="selectItem(item)"><i
                        class="fa fa-edit"></i></button></span>
                </template>
              </b-table>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.activite') }}</label>
                    <input type="text" name="" id="" v-model="formTable.activite" class="form-control" placeholder=""
                      aria-describedby="helpId" required>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.duree') }}</label>
                    <input type="text" name="" id="" v-model="formTable.duree" class="form-control" placeholder=""
                      aria-describedby="helpId" required>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.prix_dh') }}</label>
                    <input type="text" name="" id="" v-model="formTable.prix_actv" class="form-control" placeholder=""
                      aria-describedby="helpId" required>
                  </div>
                </div>
                <div class="col-md-4 pl-0">
                  <div class="form-group">
                    <label style="float: revert;" for="">&nbsp;</label>
                    <button v-if="!isEdit" class="form-control btn btn-primary bg-msafar-primary btn-add"
                      @click="addItem">{{
            $t('forms.generale.ajouter') }} </button>
                    <div class="btn-inline rtl-right" v-else>
                      <button class="form-control btn btn-primary bg-msafar-primary" @click="editItem"> {{
            $t('forms.generale.enregistrer')
          }}
                      </button>
                      <button class="form-control btn btn-secondary " @click="cancelEdit"> {{
            $t('forms.generale.annuler')
          }} </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-6 ml-4">
              <h4>{{ $t('forms.activite_pro.prix_min_activite') }}</h4>
              <div class="form-group form-row mt-4">
                <label for="prix_min">{{ $t('forms.generale.prix_min') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_min" id="prix_min" name="prix_min" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_min.$error }" />
                <div v-if="submitted && !$v.form.prix_min.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
            name: $i18n.locale === 'Français' ? 'Prix Min' : 'السعر الأدنى'
          })
                  }}
                </div>
              </div>
              <div class="form-group form-row">
                <label for="prix_max">{{ $t('forms.generale.prix_max') }} Max <sup>*</sup></label>
                <input type="text" v-model="form.prix_max" id="prix_max" name="prix_max" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_max.$error }" />
                <div v-if="submitted && !$v.form.prix_max.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
            name: $i18n.locale === 'Français' ? 'Prix Max' : 'السعر الأقصى'
          })
                  }}
                </div>
              </div>
            </div>
              <!-- <div class="col-md-12 pt-3 pb-3 pl-5">
                <label for="" style="font-size:25px;"> {{ $t('forms.guide_pro.langues') }}</label> <br> <br>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group id="checkbox-group-1" class="row" :aria-describedby="ariaDescribedby"
                    name="service" v-model="checked" :options="options" value-field="id"
                    :text-field="[$i18n.locale === 'Français' ? 'langue' : 'langue_ar']">

                  </b-form-checkbox-group>
                </b-form-group>
              </div> -->
                              <!--  <div class="col-md-3" v-for="(option,index) in options" :key="index">
                          <b-form-checkbox class="float-left" :value="option.id" size="lg" >{{ option.nom }}</b-form-checkbox>
                          </div>-->

            <!-- <div class="col-md-12 mt-3 pl-3 tabs-message-profil">
              <div>
                <b-tabs content-class="mt-3" justified>
                  <b-tab :title="$t('forms.generale.mes_messages')" active>
                    <div class="row justify-content-center">
                      <div class="col-md-11 message" v-for="message in messages" :key="message.id">
                        <p><strong>{{ message.username }} : </strong> {{ message.subject }}</p>
                        <p>{{ message.message }}</p>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab :title="$t('forms.generale.notification')">
                    <div class="row justify-content-center">
                      <div class="col-md-11 message" v-for="notification in commentaires" :key="notification.id">
                        <router-link :to="`/guides-touristiques/${form.slug}`">
                          <b-img blank blank-color="#01c203" class="mr-2" rounded="circle" width="12" height="12">
                          </b-img>
                          <span v-if="notification.user">{{ `${notification.user.nom} ${notification.user.prenom}`
                          }}</span> a ajouté un avis sur votre guide .
                        </router-link>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div> -->
            <div :class="[this.$i18n.locale === 'Français' ? '' : 'float-right', 'col-md-12 mt-5 p-5']">
              <button type="submit"
                :class="[isDataAvailable ? 'btn-outline-msafar' : 'bg-msafar-primary text-white', 'btn rounded-pill pl-4 pr-5 pt-2 pb-2']"
                @click="validate">
                <i class="fas fa-check-circle mr-3"></i>
                <span v-if="isDataAvailable">{{ $t('forms.inscription.modifier') }}</span>
                <span v-else>{{ $t('forms.inscription.valider_pro') }}</span>
              </button>
              <div v-if="isDataAvailable" :class="this.$i18n.locale === 'Français' ? 'float-right' : 'float-left'">
                <button type="button" @click="etapeSuivante()"
                  class="bg-msafar-primary btn text-white rounded-pill  pl-5 pr-5 pt-2 pb-2">
                  {{ $t('forms.inscription.etapes') }}
                  <span class="h1 color-msafar-primary flesh-profil" style="">
                    <b-icon-chevron-right></b-icon-chevron-right>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import User from "@/apis/User";
import Service from "@/apis/Services";
import http from "./../../http-common";
import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import { required } from 'vuelidate/lib/validators';

//import Table from "@/components/ProfilProfessionnel/Table";
//import Table2 from "@/components/EspaceProfessionnel/Table2";
//import Api from "@/apis/Api";


export default {
  name: "DescriptifComponent",
  components: { RubriquePro },
  computed: {
    fields() {
      //https://github.com/bootstrap-vue/bootstrap-vue/issues/3409
      const lang = this.$i18n.locale
      if (lang === 'Français') {
        return [
          { key: "activite", label: "Activité" },
          { key: "duree", label: "Durée" },
          { key: "prix_actv", label: "Prix", },
          { key: "actions", label: "Actions" }
        ]
      } else {
        return [
          { key: "activite", label: "النشاط" },
          { key: "duree", label: "المدة" },
          { key: "prix_actv", label: "الثمن (DH)", },
          { key: "actions", label: "إجراءات" }
        ]
      }
    },
  },
  data() {
    return {
      form: {
        description: "",
        description_ar: "",
        prix_min: "",
        prix_max: ""
      },
      columns: [
        "Activité",
        "Durée",
        "Prix à partir de",
        "Actions "
      ],
      persons: [],
      id_hotel: "",
      service: [],
      options: [],
      //fields: ["Type Chambre","Prix","Options","Actions"],
      // fields: [
      //   { key: "activite", label: "Activité" },
      //   { key: "duree", label: "Durée" },
      //   { key: "prix_actv", label: "Prix", },
      //   "Actions"
      // ],
      items: [
      ],
      langue_guides: [],
      checked: [],
      tableItems: [],
      formTable: {
        activite: "",
        prix_actv: "",
        duree: "",
        isEdit: false,

      },
      currentItem: [],
      messages: [],
      commentaires: [],
      submitted: false,
      isDataAvailable: false
    }
  },
  validations: {
    form: {
      description: { required },
      prix_min: { required },
      prix_max: { required },
    }
  },
  methods: {
    etapeSuivante() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.isDataAvailable) {
        this.$router.push("/profil-pro/photos");
      } else {
        this.$toaster.info('Merci de valider le formulaire pour passer à l\'étape suivante');
      }
    },
    validate() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this.$hotel.value = this.form;
      // this.$router.push("/professionnel/"+this.$typeService.value);
      Service.updateHebergement(this.form.id, this.user.type_service, this.form).then((response) => {
        this.form = response.data;
        this.isDataAvailable = true;
        this.addLangues();
        this.$toaster.success('Votre espace professionnel a bien été modifié')
        this.$router.push("/profil-pro/photos");

      }).catch(error => {
        console.log(error);
      });
    },
    addItem() {
      this.formTable.id_guide = this.form.id;
      http.post(`/type-activites`, this.formTable)
        .then((response) => {
          console.log(response)
          this.items.push(this.formTable);
          this.$toaster.success('Activité a bien été ajouté')
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    cancelEdit() {
      this.currentItem = [];
      this.formTable.prix_actv = '';
      this.formTable.duree = '';
      this.formTable.activite = '';
      this.isEdit = false;
    },
    selectItem(item) {
      this.currentItem = item;
      this.formTable.prix_actv = item.prix_actv;
      this.formTable.duree = item.duree;
      this.formTable.activite = item.activite;
      this.isEdit = true;
    },
    editItem() {
      this.formTable.id = this.currentItem.id;

      http.put(`/type-activites/${this.currentItem.id}`, this.formTable)
        .then((response) => {
          let objEdited = { 'id': response.data.id, 'activite': response.data.activite, 'prix_actv': response.data.prix_actv, 'duree': response.data.duree }
          this.items = this.items.map(obj => {
            return parseInt(obj.id) === parseInt(this.currentItem.id) ? objEdited : obj
          });
          this.$toaster.success('Activité a bien été modifié')
        })
        .catch(e => {
          console.log(e.data);
        })
    },
    deleteItem(item) {
      http.delete(`/type-activites/${item.id}`)
        .then(() => {
          //console.log(this.options)
          this.items = this.items.filter(obj => obj.id != item.id);
          this.$toaster.success('Activité a bien été supprimé')
        })
        .catch(e => {
          console.log(e.data);
        })

    },
    getUser() {
      User.auth().then((response) => {
        //console.log(response.data);
        this.user = response.data;
        this.getHebergement();
      }).catch(error => {
        console.log(error);
      });
    },
    getHebergement() {
      console.log(this.user.id + ',' + this.user.type_service);
      Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
        this.form = response.data;
        this.isDataAvailable = response.data.length !== 0 && response.data.description.length !== 0;
        // this.listeMessages(response.data.id);
        // this.getCommentaires(response.data.id);
        this.langue_guides = response.data.langue_guides;
        this.checked = this.langue_guides.map(({ id_langue }) => id_langue);
        this.items = response.data.type_activite.map(function (activite) {
          return { 'id': activite.id, 'activite': activite.activite, 'prix_actv': activite.prix_actv, 'duree': activite.duree };
        });
        //console.log(this.items);
        //console.log(this.checked);
      }).catch(error => {
        console.log(error);
      });

    },
    getItems() {
      return this.items;
    },
    addLangues() {
      var formData = new FormData();
      console.log(this.checked);
      for (var i = 0; i < this.checked.length; i++) {
        formData.append('languesguide[]', this.checked[i]);
      }
      formData.append('id_guide', this.form.id);

      http.post('/langue-guide-liste', formData)
        .then(() => {
          //console.log(response.data);
        })
        .catch(e => {
          //console.log(e);
          this.$toaster.error(e.data)
        })
    },
    listelangues() {
      http.get('/langues')
        .then((response) => {
          this.options = response.data
          //console.log(this.options)
        })
        .catch(e => {
          console.log(e.data);
        })
    },
    // getCommentaires(id) {
    //   http.get(`/commentaires/guide/${id}`).then(response => {
    //     this.commentaires = response.data;
    //   }).catch((errors) => {
    //     console.log(errors)
    //   });
    // },
    // listeMessages(id) {
    //   http.get(`/reservations/guide/${id}`)
    //     .then((response) => {
    //       this.messages = response.data
    //     })
    //     .catch(e => {
    //       console.log(e.data);
    //     })

    // },


  },
  mounted() {

  },
  created() {
    this.getUser();
    this.listelangues();
  }
}
</script>

<style scoped>
.table thead th {
  border: 1px solid #bc4c58 !important;
  border-radius: 83px !important;
  text-align: center;
  color: #bc4c58;
  vertical-align: bottom;
}

.table thead th:last-child {
  border: 0px !important;
}

table {
  border-collapse: initial;
}



.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #ff9521;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #ff9521 !important;
}

.btn-danger {
  border-color: #dc3545 !important;
  padding: 4px 10px 4px 10px !important;
}

.btn-warning,
.btn-danger,
.btn-primary {
  border: 0;
  padding: 4px 10px 4px 10px !important;
  margin: 0px 5px;
  max-width: 90%;
}

.btn-add {
  margin-top: 30px;
}

.btn-inline {
  display: flex;
}



.message {
  background-color: #c6f0fc;
  border-radius: 25px;
  margin-bottom: 10px;
  padding: 15px;
}

.message p {
  padding: 2px;
  margin: 0px;
}

.message a {
  color: black;
}
</style>