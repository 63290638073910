<template>
    <div class="col-md-12 commentaires">
        <p style="color: rgb(1, 114, 174); font-size: 26px;" class="mt-3 dir-rtl">{{ $t('details_service.avis') }}</p>
        <div class="mb-4">
            <div class="form-group dir-rtl">
                <textarea class="input-commentaire" rows="4" v-model="comment"
                    v-bind:placeholder="$t('details_service.votre_commentaire')"></textarea>
                <button class="btn-emoji" @click="toogleDialogEmoji">
                    <!--😃-->
                    <b-icon-emoji-smile></b-icon-emoji-smile>
                </button>
                <span class="text-danger" v-if="errors.commentaire">
                    {{ errors.commentaire[0] }}
                </span>
            </div>
            <VEmojiPicker v-show="showDialog" :style="{ width: '440px', height: '200' }" labelSearch="Search"
                lang="pt-BR" @select="onSelectEmoji" />
            <b-button v-if="isLoggedIn" variant="outline-info float-right" class="mt-2" @click="addComment()">
                {{ $t('details_service.envoyer') }}</b-button>
            <b-button v-else variant="outline-info float-right" class="mt-2" @click="redirect()">
                {{ $t('details_service.envoyer') }}</b-button>
        </div>
        <div v-for="(comment, index)  of commentaires" :key="index" class="mb-3">
            <div class="body float-left">
                <img v-if="comment.user && comment.user.avatar" class="img-avatar"
                    v-bind:src="BaseUrl + '/storage/' + comment.user.avatar" alt="First slide">
                <img v-else :src="'https://ui-avatars.com/api/?name=' + comment.user.prenom + ' ' + comment.user.nom"
                    class="img-avatar" />
                <div class="user-comment" v-if="comment.user">
                    <span v-if="comment.user.pseudo">
                        {{ comment.user.pseudo }} </span>
                    <span v-else>
                        {{ comment.user.prenom + ' ' + comment.user.nom }} </span>
                </div>
            </div>
            <div class="col-md-12 float-left custom-alert mb-4">
                <p class="float-left"> {{ comment.commentaire }}</p>
            </div>
            <div class="col-md-12">
                <div v-if="comment.exists">
                    <div class="h2 mb-0 float-right">
                        <a href="javascript:void(0)" v-b-modal.modal-update @click="edit(index)" class="mr-2">
                            <b-icon icon="pen" class="rounded-circle bg-warning p-2" variant="light">
                            </b-icon>
                        </a>
                        <a href="javascript:void(0)" v-b-modal.modal-delete @click="deletec(comment.id)">
                            <b-icon icon="trash" class="rounded-circle bg-danger p-2" variant="light">
                            </b-icon>
                        </a>
                    </div>
                </div>
                <!-- <div v-else>Not Exist</div> -->
            </div>
        </div>
        <b-modal ref="modal-update" id="modal-update" title="Modfier votre Commentaire" size="md" no-close-on-esc
            centered hide-footer hide-header>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 mb-md-0">
                        <form id="contact-form" name="contact-form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="commentaire">Votre commentaire</label>
                                        <textarea type="text" id="commentaire" name="commentaire" rows="4"
                                            class="form-control md-textarea" v-model="editInput.commentaire"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="text-center  espace-voyageur ">
                            <a class="btn text-white mt-2 mr-2" v-on:click="updateComment(editInput.id)">Modifier</a>
                            <a class="btn text-white mt-2" v-on:click="hideModal('modal-update')">Anuller</a>
                        </div>
                        <!-- <div class="status"></div> -->
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal ref="modal-delete" id="modal-delete" size="md" no-close-on-esc centered hide-footer hide-header>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 mb-md-0">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="h4 mb-2 mt-3 text-center">
                                    <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> Êtes-vous sûr de
                                    vouloir supprimer le commentaire?
                                </p>
                            </div>
                        </div>
                        <div class="text-center  espace-voyageur ">
                            <a class="btn text-white mt-2 mr-2" @click="deleteComment(id_comment)">{{
                                    $t('pop_up.form_deconnexion.oui')
                            }}</a>
                            <a class="btn text-white mt-2 " @click="hideModal('modal-delete')">{{
                                    $t('pop_up.form_deconnexion.non')
                            }}</a>
                        </div>
                        <!-- <div class="status"></div> -->
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { VEmojiPicker /*, emojisDefault, categoriesDefault */ } from "v-emoji-picker";

export default {
    name: 'CommentairePartage',
    components: { VEmojiPicker },
    props: {
        commentaires: Array,
        lieuxId: Number,
        gastroId: Number,
        partageId: Number,
        errors: Array,
    },
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            showDialog: false,
            comment: '',
            isLoggedIn: false,
            editInput: {
                id: '',
                user_id: '',
                commentaire: '',
            },
            id_comment: Number,
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",

                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            },
            listIcon: []
        }
    },
    mounted() {
        this.isLoggedIn = !!localStorage.getItem('token');
    },
    methods: {
        redirect() {
            const redirectPath = this.$route.fullPath; // Saving current route
            this.$router.push({ name: 'Login', query: { redirect: redirectPath } });
            this.$toaster.error('Connectez-vous pour ajouter un commentaire.');
        },
        edit(index) {
            this.editInput.commentaire = this.$props.commentaires[index].commentaire;
            this.editInput.id = this.$props.commentaires[index].id;
        },
        deletec(index) {
            this.id_comment = index;
        },
        addComment() {
            const cmnt = {
                commentaire: this.comment,
                id_lieux: this.$props.lieuxId,
                id_gastronomie: this.$props.gastroId,
                id_partage: this.$props.partageId,
                user_id: localStorage.getItem('id'),

            }
            this.$emit('AddComment', cmnt)
            this.comment = '';
        },
        updateComment() {
            const cmnt = {
                commentaire: this.editInput.commentaire,
                id: this.editInput.id,
                id_lieux: this.$props.lieuxId,
                id_gastronomie: this.$props.gastroId,
                id_partage: this.$props.partageId,
                user_id: localStorage.getItem('id'),
            }
            this.$emit('UpdateComment', cmnt)
            this.$refs['modal-update'].hide();
            this.comment = '';
        },
        deleteComment(id) {
            const cmnt = {
                id: id,
            }
            this.$emit('DeleteComment', cmnt)
            this.$refs['modal-delete'].hide();
            this.comment = '';
        },
        hideModal(id) {
            this.$root.$emit("bv::hide::modal", id);
            this.$emit("hideBlur");
        },
        toogleDialogEmoji() {
            console.log("Toogle!");
            this.showDialog = !this.showDialog;
        },
        onSelectEmoji(emoji) {
            this.comment += emoji.data;
            // Optional
            // this.toogleDialogEmoji();
        }
    }
}
</script>

<style scoped>
.custom-alert {
    background-color: #c6f0fc;
    padding: 18px 25px;
    border: 1px;
    border-radius: 40px;
    position: relative;
}

.body .user {
    margin-top: 16px;
    text-transform: capitalize;
}

.custom-alert p {
    margin-bottom: 0px;
}

.img-avatar {
    float: left;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -o-object-fit: cover !important;
    object-fit: cover !important;
    -o-object-position: center center !important;
    object-position: center center !important;
}
</style>