<template>
    <table class="table table-striped" v-if="items.length > 0">
        <thead>
            <tr style="color: white" class="text-center">
                <th scope="col" style="background-color: #b90000;border-top-left-radius: 25px;">
                    {{$t('details_service.type_de_chambre')}}
                </th>
                <!-- <th scope="col" style="background-color: #897f42;">Nombre de personnes</th> -->
                <th scope="col" style="background-color: #274f47;">{{$t('details_service.options')}}</th>
                <th scope="col" style="background-color: #de6c0b;border-top-right-radius: 25px;">
                    {{$t('details_service.prix_apartir')}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="col-md-6 py-3" v-for="chambre of items" v-bind:key="chambre.id">
                <td>{{ !!chambre.type ? chambre.type : '' }} </td>
                <!-- <td class="text-center">{{ chambre.nbr_personnes }}</td> -->
                <td>{{ chambre.options }}</td>
                <td class="text-center">{{ chambre.prix }} dhs</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'Type-chmabre',
    props: {
        items: Array
    }

}
</script>