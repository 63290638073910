<template>

  <div class="container min-height">
    <Rubrique></Rubrique>
    <div class="row justify-content-center mt-5">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <h1 class="rtl-title" style="display: inline-block"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                src="@/assets/images/img-bladi.png" />{{$t('rubrique.evenements')}}</h1>
            <router-link to="/evenements/">
              <b-icon-arrow-right v-if="this.$i18n.locale== 'العربية'"  class="arrow-left float-left color-primary"></b-icon-arrow-right>
              <b-icon-arrow-left v-else  class="arrow-left float-right color-primary"></b-icon-arrow-left>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row top-forme justify-content-center">
      <div class="col-md-10 bg-white p-5 text-center">
        <div class="mb-4 text-center">
          <img class="max-width-100" style="width: 100%;" v-bind:src="BaseUrl + '/storage/' + post.image" />
        </div>
        <h1 v-if="$i18n.locale === 'Français'">{{ post.titre ??  post.titre_ar }}</h1>
        <h1 v-else-if="$i18n.locale === 'English'">{{ post.titre_en ??  post.titre }}</h1>
        <h1 v-else>{{ post.titre_ar ??  post.titre}}</h1>
        <p  v-if="$i18n.locale === 'Français'">{{post.description ?? post.description_ar }}</p>
        <p  v-else-if="$i18n.locale === 'English'">{{post.description_en ?? post.description }}</p>
        <p  v-else>{{post.description_ar ?? post.description }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";

import seo from "../seo-sevices.js";
import  $i18n  from '../i18n.js';
export default {
  metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return seo.metaInfo_fr(this.post,this.images);
    }
    else{
      return seo.metaInfo_ar(this.post,this.images);
    }
  },
  name: "EvenementsViewComponent",
  components: { Rubrique },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      post: [],
      items: [],
      images: [],
      errors: [],
      //items:this.hotels,
      perPage: 2,
      currentPage: 1
    }
  },
  mounted() {
    const id = this.id;
    //console.log(id);
    http.get(`/evenements/${id}`)
      .then((response) => {
        console.warn(response.data.data);
        this.post = response.data
        /*.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
        )*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {

  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
/*
export default {
data () {
return {
currentPage: 1,
perPage: 5,
}
},
computed: {
lists () {
const items = this.$store.getters.loadedLists
// Return just page of items needed
return items.slice(
(this.currentPage - 1) * this.perPage,
this.currentPage * this.perPage
)
},
totalRows () {
return this.$store.getters.loadedLists.length
}
}
}
*/
</script>

<style scoped>
</style>