<template>
  <div class="container rubrique">
    <div class="row mb-4 justify-content-center">
      <div class="col-md-10">
        <div class="row justify-content-center">
          <div class="col-md-4 mb-4 col-xs-6" :class="['bg-image', 'bg-hotel']">
            <router-link class="full-link" to="/hotels">
              <p class="hotel">{{ $t("rubrique.hotels") }}</p>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 col-xs-6" :class="['bg-image', 'bg-riad-maison']">
            <router-link class="full-link" to="/riads">
              <p class="riad">{{ $t("rubrique.riad_maisons") }}</p>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 col-xs-6" :class="['bg-image', 'bg-appart-hotel']">
            <router-link class="full-link" to="/apparthotels">
              <p class="appart-hotel">{{ $t("rubrique.apparthot") }}</p>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 col-xs-6" :class="['bg-image', 'bg-restaurants']">
            <router-link class="full-link" to="/restaurants">
              <p class="restaurant">{{ $t("rubrique.restaurants") }}</p>
            </router-link>
          </div>
          <div class="col-md-4  mb-4 col-xs-6" :class="['bg-image', 'bg-activites-touristiques']">
            <router-link class="full-link" to="/excursions-randonnees">
              <p class="activite-touristique">{{ $t("rubrique.activite_touristique") }}</p>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 col-xs-6" :class="['bg-image', 'bg-produit-locaux']">
            <router-link class="full-link" to="/produit_artisanal">
              <p class="guide-touristique">{{ $t("rubrique.guides_touristique") }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RubriqueImageComponent"
}
</script>

<style>
.full-link {
  display: flex;
  height: 100%;
  width: 100%;
  text-decoration: none !important;
  justify-content: center;
}

.bg-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 360px;
  /* background: url("@/assets/images/hotels.jpg") no-repeat;
  background-position: center;
  background-size: contain; */
}

.bg-hotel {
  background-image: url("@/assets/images/hotels.jpg");
}

.bg-riad-maison {
  background-image: url("@/assets/images/riads-maisons-dhotes.jpg");
}

.bg-appart-hotel {
  background-image: url("@/assets/images/appart-hotel.jpg");
}

.bg-restaurants {
  background-image: url("@/assets/images/restaurants.jpg");
}

.bg-activites-touristiques {
  background-image: url("@/assets/images/activites-touristiques.jpg");
}

.bg-produit-locaux {
  background-image: url("@/assets/images/produit-locaux.jpg");
}

.rubrique p {
  margin-top: 25px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .rubrique p {
    font-size: 30px;
  }
}
</style>