<template>
  <div class="container-fluid">
    <Rubrique></Rubrique>
    <div class="container min-height mt-5">

      <div class="row espace-prof top-forme justify-content-center">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro />
          <div class="row descriptif-body p-5">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="username">Nom d'utilisateur:</label>
                <input type="text" class="form-control" v-model="form.name">
                <span class="text-danger" v-if="errors.name">
                  {{ errors.name[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="email">Adresse e-mail:</label>
                <input type="email" class="form-control" v-model="form.email">
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="confirmez-email">Confirmez l'adresse e-mail:</label>
                <input type="email" v-model="form.confirmation_de_adresse_email" class="form-control">
                <span class="text-danger" v-if="errors.confirmation_de_adresse_email">
                  {{ errors.confirmation_de_adresse_email[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="password">Mot de passe:</label>
                <input type="password" v-model="form.password" class="form-control">
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" for="confirmez-password">Confirmez Mot de passe:</label>
                <input type="password" class="form-control" v-model="form.confirmation_de_mot_de_passe">
                <span class="text-danger" v-if="errors.confirmation_de_mot_de_passe">
                  {{ errors.confirmation_de_mot_de_passe[0] }}
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input type="checkbox" id="accepter">
                <label class="control-label ml-3" for="accepter">j'accepte les régles conditions générales
                  d'utilisation du site msafar.ma </label>
              </div>
            </div>

            <div class="col-md-12 mt-5">
              <router-link to="/professionnel/photos">
                <button type="submit" class="btn text-white rounded-pill pl-5 pr-5 pt-2 pb-2"
                  style="background-color:rgb(152, 26, 29);">Passer à l étape
                  suivante</button>
                <span class="h1" style="margin-top: -4px;position: absolute;color:rgb(152, 26, 29);">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </span>
              </router-link>

            </div>

          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rubrique from "@/components/Outils/Rubrique";
import RubriquePro from "@/components/EspaceProfessionnel/RubriquePro";
import User from "@/apis/User";
export default {
  name: "DescriptifComponent",
  components: { Rubrique, RubriquePro },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        confirmation_de_adresse_email: "",
        confirmation_de_mot_de_passe: ""
      },
      errors: []
    };
  },
  methods: {
    register() {
      User.registerPro(this.form)
        .then(() => {
          this.$router.push({ name: "MonCompte" });
          this.$toaster.success('Votre espace professionnel a été bien créé.')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    }
  }
  ,
  created() {
    window.scrollTo(0, 350);
  }
}
</script>
<style scoped>
label {
  color: rgb(152, 26, 29);
}

.connectez-vous a {
  font-family: GillSansMT;
  font-size: 15px;
  background: transparent;
  color: rgb(152, 26, 29);
  padding: 0px 40px;
  border-radius: 25px;
}

.image-bladi {
  margin-top: -4px;
  margin-right: -30px;
  width: 30px;
}



.image-bladi-right {
  margin-top: -4px;
  margin-left: -30px;
  width: 30px;
}
</style>