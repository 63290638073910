<template>


    <div class="container mt-5">
        <div class="row ">
            <div class="col-md-10 mx-auto mt-5">
                <h1><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                        src="@/assets/images/img-bladi.png" />{{ $t('rubrique.conditions_generales') }}</h1> <br>
                <h5><strong>Informations générales</strong></h5> <br>
                <p>
                    Le site Internet <a href="http://www.msafar.ma">www.msafar.ma</a> vous permet d'organiser au mieux
                    vos
                    voyages
                    en vous proposant une sélection d'adresses les plus incontournables pour une découverte d'un Maroc
                    authentique. <br><br>

                    L'accès au site, sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve
                    des
                    présentes
                    Conditions Générales d'Utilisation. <br><br>

                    Le site est édité par Direct Invest, SARL au capital de 200.000 MAD, immatriculée au registre du
                    commerce de
                    Casablanca sous le numéro 188371. Siège social : 159 Bd Yacoub El Mansour - Entrée B -
                    1<sup>er</sup> étage
                    Bureau n°3, 20360 Maarif Casablanca <br><br>

                    Le Directeur de Publication de msafar.ma est Laïla Triki.<br><br>

                    Numéro de ICE : 000211297000036<br><br>

                    Pour toute question sur l'entreprise, vous pouvez nous envoyer vos questions par courriel.<br><br>
                </p>
                <h4>CONDITIONS GENERALES D'UTILISATION DU SERVICE DIRECT BUSINESS</h4>

                <strong>Article 1 : définitions</strong> <br>
                <p>
                    Chacun des termes mentionnés ci-dessous aura dans les présentes Conditions Générales d'Utilisation
                    du
                    Service <a href="http://www.msafar.ma">www.msafar.ma</a> la signification suivante:<br><br>

                    Le terme «Services»: désigne, de manière plus générale, le site Web et l'application mobile de
                    Msafar.ma

                    Les Services sont fournis exclusivement aux fins suivantes :<br><br>
                <ul>
                    <li>Aider les voyageurs à rassembler des informations de voyage, à publier du Contenu, à rechercher
                        et à
                        réserver des services de voyage.</li>
                    <li>Aider les professionnels des secteurs du voyage, du tourisme et de l'hôtellerie à établir des
                        relations
                        avec leurs clients, y compris potentiels, par le biais du partage des offres sur les plateformes
                        msafar.ma</li>
                </ul>
                <br>
                Le terme « Contenu » désigne toute information, toute donnée, tout texte, lien, graphique, contenu
                photo, audio
                ou vidéo, tout code ou autre document ou ensemble de documents que vous pouvez consulter sur les
                Services,
                auxquels vous pouvez accéder par le biais des Services ou avec lesquels vous pouvez interagir par le
                biais de
                ces derniers. <br><br>

                Le terme « Voyageur» désigne toute personne physique ayant un compte sur Msafar.ma ou utilisant les
                Services de
                Msafar.ma ou contribuant à leur Contenu. <br><br>

                Le terme «Professionnel» désigne toute personne physique ou morale déposant, à des fins
                professionnelles, du
                contenu sur le site Internet et l'applications Msafar.ma <br><br>

                Tout Professionnel doit impérativement être titulaire d'un «espace Professionnel» pour déposer et gérer
                son
                contenu. <br><br>

                Le terme «Compte» désigne l'espace accessible depuis le Site Internet ou l'application mobile que tout
                utilisateur doit se créer, et à partir duquel il peut diffuser, gérer, visualiser son contenu. <br><br>

                Messagerie: désigne le système de messagerie mis à la disposition des voyageurs et des professionnels
                dans le
                cadre du Service Msafar.ma et permettant leur communication par échange de messages privés que seuls les
                voyageurs et les professionnels concernés peuvent consulter. La Messagerie est uniquement accessible aux
                utilisateurs disposant d'un Compte. <br><br>
                </p>
                <strong>Article 2 : Objet</strong>
                <p>
                    Les CGU ont pour objet de déterminer les conditions d'utilisation du Service Msafar.ma mis à
                    disposition des
                    utilisateurs via le Site Internet et l'application mobile. <br><br>

                    Les conditions de souscription au compte professionnel sont fixées dans les conditions générales de
                    vente
                    réservées aux professionnels <br><br>
                </p>
                <strong>Article 3 : Acceptation </strong> <br>
                <p>
                    Tout utilisateur déclare en accédant et utilisant les service Msafar.ma, depuis le Site Internet
                    et/ou
                    l'application mobile, avoir pris connaissance des présentes Conditions Générales d'Utilisation et
                    les
                    accepter
                    expressément sans réserve et/ou modification de quelque nature que ce soit.
                </p><br>
                <strong>Article 4 : Description du Service Msafar.ma</strong><br><br>
                <p>
                    <span> 4.1. Règles générales</span> <br><br>


                    Tout utilisateur déclare être informé qu'il devra, pour accéder au Msafar.ma, disposer d'un accès à
                    l'Internet
                    souscrit auprès du fournisseur de son choix, dont le coût est à sa charge, et reconnaît que:
                    <br><br>

                    La fiabilité des transmissions est aléatoire en raison, notamment, du caractère hétérogène des
                    infrastructures
                    et réseaux sur lesquelles elles circulent et que, en particulier, des pannes ou saturations peuvent
                    intervenir ; <br><br>

                    Il appartient à l'utilisateur de prendre toute mesure qu'il jugera appropriée pour assurer la
                    sécurité de
                    son
                    équipement et de ses propres données, logiciels ou autres, notamment contre la contamination par
                    tout virus
                    et/ou de tentative d'intrusion dont il pourrait être victime ; <br><br>

                    &nbsp;

                    Tout équipement connecté au Site Internet est et reste sous l'entière responsabilité de
                    l'utilisateur, la
                    responsabilité de msafar.ma ne pourra pas être recherchée pour tout dommage direct ou indirect qui
                    pourrait
                    subvenir du fait de leur connexion au Site Internet msafar.ma <br><br>

                    L'utilisateur s'engage, le cas échéant, à respecter et à maintenir la confidentialité des
                    Identifiants de
                    connexion à son Compte et reconnaît expressément que toute connexion à son Compte, ainsi que toute
                    transmission
                    de données depuis son Compte sera réputée avoir été effectuée par l'utilisateur. <br><br>

                    Toute perte, détournement ou utilisation des Identifiants de connexion et leurs éventuelles
                    conséquences
                    relèvent de la seule et entière responsabilité de l'utilisateur. <br><br>

                    L'utilisateur est informé et accepte que pour des raisons d'ordre technique, son contenu ne sera pas
                    diffusé
                    instantanément après son dépôt sur le Site Internet. Avant sa publication, son contenu sera validé
                    par
                    l'administrateur du site. <br><br>

                    L'utilisateur est informé qu'en publiant son contenu sur le site Internet, celui-ci est susceptible
                    d'être
                    partagé par tout utilisateur sur le réseau social Facebook, Instagram, YouTube, Tiktok ou WhatsApp
                    ou autre
                    réseau social via le bouton de partage concerné intégré dans les pages. <br><br>
                </p>
                <span> 4.2 Description du service </span> <br><br>
                <div>
                    <span> 4.2.1. Fonctionnalités accessibles aux voyageurs </span> <br><br>
                    <ul>
                        <li>La consultation de tous les services présentés par les professionnels de voyage</li>
                        <li>La création d'un compte personnel</li>
                        <li>La modification de l'identifiant et du mot de passe</li>
                        <li>La mise en contact avec les professionnels</li>
                        <li>La possibilité de donner un avis par rapport à un lieur ou un service</li>
                        <li>La possibilité de répondre à un avis donné par un autre voyageur</li>
                        <li>Le partage de contenu concernant une expérience de voyage</li>
                        <li>La possibilité de supprimer un avis ou un contenu partagé</li>
                    </ul>
                    &nbsp;
                    <span>
                        4.2.2. Fonctionnalités accessibles aux Professionnels</span><br>
                    &nbsp;
                    <ul>
                        <li>La création d'un compte professionnel</li>
                        <li>La présentation de leur activité après validation des Admins</li>
                        <li>La gestion des informations concernant leurs activités via l'onglet "Mon établissement" du
                            Compte :
                        </li>
                        <li>La possibilité de répondre aux avis des voyageurs</li>
                        <li>La possibilité de supprimer les réponses aux avis des voyageurs</li>
                        <li>La possibilité de changer le mot de passe du compte</li>
                    </ul>
                    <span>
                        4.2.3. L'accès et la gestion du Compte</span><br><br>
                </div>
                Pour les voyageurs, le nom, le prénom, l'adresse mail et le nom d'utilisateur ne peuvent pas être
                modifiés.
                <br><br>
                Pour les Professionnels, les informations du compte (le nom, le prénom, l'adresse mail et le nom
                d'utilisateur)
                ainsi que les informations de l'activité (nom de l'établissement, type de service, ville, adresse,
                nombre
                d'étoiles) ne peuvent pas être modifiés.
                <br><br>
                <strong>Article 6 : Responsabilité et garanties </strong><br><br>
                <p>
                    <span>
                        6.1 Engagements de l'utilisateur</span> <br><br>

                    L'utilisateur garantit détenir tous les droits (notamment des droits de propriété intellectuelle) ou
                    avoir
                    obtenu toutes les autorisations nécessaires à la publication de son contenu.<br><br>

                    L'utilisateur garantit que le contenu ne contrevient à aucune réglementation en vigueur (notamment
                    relatives
                    à la publicité, à la concurrence, à la promotion des ventes, à l'utilisation de données
                    personnelles,) ni
                    aucun
                    droit de tiers (notamment aux droits de propriété intellectuelle et aux droits de la personnalité)
                    et qu'il
                    ne comporte aucun message diffamatoire ou dommageable à l'égard de tiers. <br><br>

                    Ainsi, l'utilisateur s'engage notamment à ce que le contenu ne contienne : <br><br>
                <ul>
                    <li>aucun lien hypertexte redirigeant les Utilisateurs notamment vers des sites internet exploités
                        par tout
                        tiers à la société Direct Invest</li>
                    <li>aucune information fausse, mensongère ou de nature à induire en erreur d'autres Utilisateurs
                    </li>
                    <li>aucune mention diffamatoire ou de nature à nuire aux intérêts et/ou à l'image de Direct Invest
                        ou de
                        tout tiers</li>
                    <li>aucun contenu portant atteinte aux droits de propriété intellectuelle de tiers</li>
                    <li>aucun contenu à caractère promotionnel ou publicitaire</li>
                </ul>

                L'utilisateur déclare connaître l'étendue de diffusion du Site Internet, avoir pris toutes précautions
                pour
                respecter la législation en vigueur des lieux de réception et décharger Direct Invest de toutes
                responsabilités
                à cet égard. <br><br>

                Dans ce cadre, l'utilisateur déclare et reconnaît qu'il est seul responsable du contenu qu'il
                publie.<br><br>

                L'utilisateur assume l'entière responsabilité éditoriale du contenu qu'il publie.<br><br>

                En conséquence, l'utilisateur relève Direct Invest, ses sous-traitants et fournisseurs, de toutes
                responsabilités, les garantit contre tout recours ou action en relation avec le contenu qui pourrait
                être
                intenté contre ces derniers par tout tiers, et prendra à sa charge tous les dommages-intérêts ainsi que
                les
                frais et dépens auxquels ils pourraient être condamnés ou qui seraient prévus à leur encontre par un
                accord
                transactionnel signé par ces derniers avec ce tiers , nonobstant tant tout dommages-intérêts dont Direct
                Invest, ses sous-traitants et fournisseurs pourraient réclamer à raison des faits dommageables de
                L'utilisateur.<br><br>

                En déposant tout contenu, chaque Utilisateur reconnaît et accepte que Direct Invest n'est pas
                responsable de
                toute relation entre les voyageurs et les professionnels.<br><br>

                <span> 6.2 L'engagement du Professionnel</span>

                Le Professionnel s'engage à ce que son Compte Pro ne contienne:<br><br>
                <ul>
                    <li>aucune information fausse et/ou mensongère</li>
                    <li>aucune information portant atteinte aux droits d'un tiers</li>
                </ul>
                Dans ce cadre, le titulaire déclare et reconnaît qu'il est seul responsable des informations renseignées
                lors de
                la création de son Compte Pro. <br><br>

                <span> 6.2 Responsabilité et obligations de Direct Invest</span>

                Direct Invest ne saurait donc en aucun cas être tenue responsable du contenu publié par les Utilisateurs
                ainsi
                que des messages et contenus échangés via la Messagerie et ne donne aucune garantie, expresse ou
                implicite, à
                cet égard. <br><br>

                Direct Invest est un tiers aux correspondances et relations entre les voyageurs et les professionnels,
                et exclut
                de ce fait toute responsabilité à cet égard. <br><br>

                <span>6.3 Limitation de responsabilité</span>

                Direct Invest s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux la
                fourniture du
                Service msafar.ma aux Voyageurs et aux Professionnels.<br><br>

                Toutefois, Direct Invest décline toute responsabilité en cas de :<br><br>
                <ul>
                    <li>interruptions, pannes, modifications et dysfonctionnement du Service msafar.ma quel que soit le
                        support
                        de communication utilisé et ce quelles qu'en soient l'origine et la provenance,</li>
                    <li>la perte de données ou d'informations stockées par Direct Invest. Il incombe aux Utilisateurs de
                        prendre
                        toutes précautions nécessaires pour conserver les contenus qu'ils publient via le Service
                        Msafar.ma;
                    </li>
                    <li>impossibilité momentanée d'accès au Site Internet et/ou aux Applications en raison de problèmes
                        techniques et ce quelles qu'en soient l'origine et la provenance,</li>
                    <li>dommages directs ou indirects causés à l'Utilisateur, quelle qu'en soit la nature, résultant du
                        contenu
                        et/ou de l'accès, de la gestion, de l'Utilisation, de l'exploitation, du dysfonctionnement et/ou
                        de
                        l'interruption du Service msafar.ma,</li>
                    <li>utilisation anormale ou d'une exploitation illicite du Service msafar.ma par tout Utilisateur,
                    </li>
                    <li>attaque ou piratage informatique, privation, suppression ou interdiction, temporaire ou
                        définitive, et
                        pour quelque cause que ce soit, de l'accès au réseau internet.</li>
                </ul>
                L'Utilisateur renonce donc à demander réparation à Direct Invest à quelque titre que ce soit, de
                dommages
                indirects tels que le manque à gagner, la perte de chance, le préjudice commercial ou financier,
                l'augmentation
                de frais généraux ou les pertes trouvant leur origine ou étant la conséquence de son inscription sur le
                site
                Masfar.ma.<br><br>

                Tout Utilisateur est alors seul responsable des dommages causés aux tiers et des conséquences des
                réclamations
                ou actions qui pourraient en découler. L'Utilisateur renonce également à exercer tout recours contre
                Direct
                Invest dans le cas de poursuites diligentées par un tiers à son encontre du fait de l'Utilisation et/ou
                de
                l'exploitation illicite du Service Msafar.ma, en cas de perte par un Utilisateur de son mot de passe ou
                en cas
                d'usurpation de son identité.<br><br>
                </p>
                <strong>Article 7 : Propriété intellectuelle</strong><br><br>
                <p>

                    <span>7.1</span> Tous les droits de propriété intellectuelle (tels que notamment droits d'auteur,
                    droits des
                    marques,
                    droits
                    des producteurs de bases de données) portant tant sur la structure que sur les contenus du Site
                    Internet et
                    notamment les images, sons, vidéos, photographies, logos, marques, éléments graphiques, textuels,
                    visuels,
                    outils, logiciels, documents, données, etc. (ci-après désignés dans leur ensemble "Éléments") sont
                    réservés.
                    Ces
                    Éléments sont la propriété de Direct Invest. Ces Éléments sont mis à disposition des Utilisateurs
                    pour la
                    seule
                    utilisation du Service Msafar.ma et dans le cadre d'une utilisation normale de ses fonctionnalités.
                    Les
                    Utilisateurs s'engagent à ne modifier en aucune manière les Éléments. <br><br>

                    Toute utilisation non expressément autorisée des Éléments du Site Internet entraîne une violation
                    des droits
                    d'auteur et constitue une contrefaçon. Elle peut aussi entraîner une violation des droits à l'image,
                    droits
                    des
                    personnes ou de tous autres droits et réglementations en vigueur. Elle peut donc engager la
                    responsabilité
                    civile et/ou pénale de son auteur. <br><br>

                    <span>7.2</span> Il est interdit à tout Utilisateur de copier, modifier, créer une œuvre dérivée,
                    inverser
                    la conception
                    ou
                    l'assemblage ou de toute autre manière tenter de trouver le code source, vendre, attribuer, sous
                    licencier
                    ou
                    transférer de quelque manière que ce soit tout droit afférent aux Éléments.<br><br>

                    Tout Utilisateur du Service Msafar.ma s'engagent notamment à ne pas:<br><br>
                <ul>
                    <li>utiliser ou interroger le Service Msafar.ma pour le compte ou au profit d'autrui ;</li>
                    <li>extraire, à des fins commerciales ou non, tout ou partie des informations ou du contenus
                        présents sur le
                        Service Msafar.ma, sur le Site Internet et l'Application;</li>
                    <li>reproduire sur tout autre support, à des fins commerciales ou non, tout ou partie des
                        informations ou
                        des contenus présents sur le Service msafar.ma et sur le Site Internet et l'Application
                        permettant de
                        reconstituer tout ou partie des fichiers d'origine ;</li>
                    <li>utiliser un robot, notamment d'exploration, une application de recherche ou récupération de
                        sites
                        Internet ou tout autre moyen permettant de récupérer ou d'indexer tout ou partie du contenu du
                        Site
                        Internet et de l'Applications, excepté en cas d'autorisation expresse et préalable de Direct
                        Invest;
                    </li>
                    <li>copier les informations sur des supports de toute nature permettant de reconstituer tout ou
                        partie des
                        fichiers d'origine.</li>
                </ul>
                Toute reproduction, représentation, publication, transmission, utilisation, modification ou extraction
                de tout
                ou partie des Éléments et ce de quelque manière que ce soit, faite sans l'autorisation préalable et
                écrite de
                Direct Invest est illicite. Ces actes illicites engagent la responsabilité de ses auteurs et sont
                susceptibles
                d'entraîner des poursuites judiciaires à leur encontre et notamment pour contrefaçon.<br><br>

                <span>7.3</span> Les marques et logos Msafar.ma, ainsi que les marques et logos des partenaires de
                Direct Invest
                sont des
                marques déposées. Toute reproduction totale ou partielle de ces marques et/ou logos sans l'autorisation
                préalable et écrite de Direct Invest est interdite.<br><br>

                <span>7.4</span> Direct Invest est producteur des bases de données du Service Msafar.ma. En conséquence,
                toute
                extraction
                et/ou réutilisation de la ou des bases de données est interdite.<br><br>

                <span>7.5.</span> Direct Invest se réserve la possibilité de saisir toutes voies de droit à l'encontre
                des
                personnes qui
                n'auraient pas respecté les interdictions contenues dans le présent article.<br><br>

                <span>7.6.</span>Liens hypertextes<br><br>

                <span>7.6.1.</span> Liens à partir du Service Msafar.ma: Le Service Msafar.ma peut contenir des liens
                hypertextes redirigeant
                vers des sites exploités par des tiers. Ces liens sont fournis à simple titre d'information.<br><br>

                Direct Invest n'exerce aucun contrôle sur ces sites et décline toute responsabilité quant à l'accès, au
                contenu
                ou à l'utilisation de ces sites, ainsi qu'aux dommages pouvant résulter de la consultation des
                informations
                présentes sur ces sites.<br><br>

                La décision d'activer ces liens relève de la pleine et entière responsabilité de l'Utilisateur.<br><br>

                <span>7.6.2.</span> Liens vers le Service msafar.ma: Aucun lien hypertexte ne peut être créé vers le
                Service
                msafar.ma sans
                l'accord préalable et exprès de Direct Invest.<br><br>

                Si un internaute ou une personne morale désire créer, à partir de son site, un lien hypertexte vers le
                Service
                msafar.ma et ce quel que soit le support, il doit préalablement prendre contact avec Direct Invest en
                lui
                adressant un email à l'adresse suivante contact@directinvest.ma.<br><br>

                Tout silence de Direct Invest devra être interprété comme un refus.<br><br>

                <span>7.7.</span> En déposant du contenu sur le Site Internet, l'Utilisateur concède:<br><br>

                - à Direct Invest le droit d'exploitation non exclusif, transférable, sous licenciable, à titre
                gracieux,
                pour le monde entier sur (i) l'ensemble du contenu des Utilisateurs et notamment sur les photographies,
                textes,
                vidéos, illustrations, marques, logos, titres (ci-après le « Contenu »), au fur et à mesure de leur
                publication
                sur le Site ainsi (ii) qu'une licence sur l'ensemble des droits de propriété intellectuelle afférant au
                Contenu
                et notamment sur les droits d'auteurs sur les éléments utilisés dans son Annonce, tels que les
                photographies,
                textes, vidéos, dessins, illustrations, éléments sonores, et ce pour toute la durée légale de ses droits
                de
                propriété intellectuelle et pour le monde entier.<br><br>

                Les droits ainsi concédés incluent le droit de reproduire, représenter, diffuser, adapter, modifier,
                réaliser
                une œuvre dérivée, traduire tout ou partie du Contenu par tous procédés, sous quelque forme que ce soit
                et sur
                tous supports (numérique, imprimé…) connus ou inconnus à ce jour, dans le cadre du service Msafar.ma ou
                en
                relation avec l'activité de Direct Invest, et ce à des fins commerciales ou non et notamment
                publicitaires,
                ainsi que dans le cadre d'une diffusion sur tous les réseaux sociaux sur lesquels Msafar.ma est
                présent.<br><br>

                En particulier, les photographies diffusées pourront être reproduites et intégrées dans des formats
                publicitaires diffusés sur le Site Internet, uniquement en lien avec le contenu déposé.
                <br><br>
                L'Utilisateur accorde son consentement à la reprise de son Contenu sur les réseaux sociaux, notamment
                Facebook,
                Instagram et Twitter. Par conséquent, le Professionnel atteste avoir pris connaissance des conditions
                générales
                d'utilisation des sites Facebook, Instagram, et Twitter et en accepter les termes, particulièrement en
                matière
                de réutilisation du Contenu et des données personnelles.<br><br>

                Au titre de cette licence, Direct Invest, sans que cela ne crée à sa charge une obligation d'agir, est
                en droit
                de s'opposer à la reproduction et l'exploitation par des tiers non autorisés du contenu diffusé sur le
                Site
                Interne.<br><br>

                - aux Utilisateurs, le droit non exclusif d'accéder au Contenu via le Service Msafar.ma et d'utiliser et
                de
                représenter le Contenu dans la mesure autorisée par les fonctionnalités du Service Msafar.ma , et ce
                pour le
                monde entier.<br><br>
                </p>
                <strong>Article 8 : Modification du service Msafar.ma et des CGU</strong> <br><br>

                <span>7.7.</span> Direct Invest se réserve le droit, à tout moment, de modifier ou interrompre
                l'accessibilité
                de tout ou
                partie du Service Msafar.ma et/ou du Site Internet ou des Applications.<br><br>

                <span>8.2.</span> Direct Invest se réserve la possibilité de modifier, à tout moment, en tout ou partie
                des CGU.
                Les
                Utilisateurs sont invités à consulter régulièrement les présentes CGU afin de prendre connaissance de
                changements éventuels effectués. L'Utilisation du Site par les Utilisateurs constitue l'acceptation par
                ces
                derniers des modifications apportées aux CGU.<br><br>

                <h4>Dispositions diverses</h4>

                Si une partie des CGU devait s'avérer illégale, invalide ou inapplicable, pour quelle que raison que ce
                soit,
                les dispositions en question seraient réputées non écrites, sans remettre en cause la validité des
                autres
                dispositions qui continueront de s'appliquer entre les Utilisateurs et/ou les Professionnels et Direct
                Invest.
                <br><br>
                Les présentes CGU sont soumises au droit marocain.<br><br>

                &nbsp;

                <h4>REGLES GENERALES DE DIFFUSION</h4>

                <h4>INTERDICTIONS GENERALES</h4>
                <p>
                    Tout contenu contenant des éléments de texte (mots, expressions, phrases… etc.) qui sembleraient
                    contraires aux
                    dispositions légales ou réglementaires, aux bonnes mœurs, aux règles de diffusion de notre site ou
                    susceptible
                    de troubler ou choquer les lecteurs sera refusée par Direct Invest sans que cela ne fasse naître au
                    profit de
                    l'utilisateur un quelconque droit à indemnité.<br><br>

                    Contenus non autorisés<br><br>

                    Il est interdit de déposer du contenu :<br><br>

                    Exclusivement rédigée en termes étrangers. Les annonces doivent être rédigées en français, en arabe
                    ou
                    en
                    anglais. <br><br>

                    Du contenu comportant des termes ou des descriptions sans lien avec le contenu proposé. <br><br>

                    Présentant une utilisation abusive de mots clés. <br><br>

                    Dirigeant directement ou indirectement vers un site internet. <br><br>

                    Comportant un numéro de téléphone surtaxé. <br><br>

                    A caractère politique, sectaire, discriminatoire, sexiste etc. <br><br>

                    Produits et services non autorisés

                    En tant qu'utilisateur du service Msafar.ma, vous devez vous assurer que le bien que vous cherchez à
                    vendre ou à
                    acheter est un produit autorisé sur le site et dont la vente est légale. <br><br>

                    Nous vous invitons à prendre connaissance des produits/biens/services interdits sur notre site :
                    <br><br>
                <ul>
                    <li>Alcool, Tabac, drogue et objets associés, substances dangereuses et illicites</li>
                    <li>Cosmétique, médicament et parapharmacie</li>
                    <li>Armes blanches de combat ou d'autodéfense, armes à feu, explosifs, pièges de chasse</li>
                    <li>Contenu réservé aux adultes</li>
                    <li>Certaines espèces végétales et animales protégées, menacées ou prélevées dans leur milieu
                        naturel</li>
                    <li>Objets consignés</li>
                    <li>Appel aux dons financiers privés ou à but humanitaire</li>
                    <li>Offres pouvant mener à des pratiques tendancieuses</li>
                    <li>Avis de recherche d'une personne</li>
                    <li>Produit portant des emblèmes protégés</li>
                </ul>
                Cette liste est non exhaustive.

                &nbsp;

                Protection des droits de propriété intellectuelle<br><br>

                L'insertion de logos ou d'adresse de site internet sur les photos reste strictement interdite. <br><br>

                Les photographies insérées doivent représenter le contenu. <br><br>

                Il est interdit d'insérer des photos comportant : <br><br>
                <ul>
                    <li>Enfants mineurs reconnaissables</li>
                    <li>Lien vers un site internet</li>
                    <li>Représentations sans lien avec l'offre proposée</li>
                </ul>
                &nbsp;

                &nbsp;
                </p>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'MentionsLegales',

}
</script>

<style scoped>
ul>li {
    color: #0095c3;
}

span {
    color: #005990;
}

strong {
    color: #c54200;
}
</style>