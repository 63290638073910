<template>
  <div class="container-fluid">
    <div class="container min-height ">
       <div class="row justify-content-center mt-5 ">
          <div class="col-xl-4 col-md-6 col-12 mt-5 bg-espace-professionnel dir-rtl">
            <p class="cont-p" v-html="$t('login_pro.text')"></p>
              <div class=" text-center cont-btn">
                <img class="text-center" src="@/assets/images/sand-glass.png" />
                <p class="font-weight-normal text-white " style="font-size: 15.6px;"> {{ $t('login_pro.invitation') }} </p>
                <div class="buttons m-2 text-center">
                  <router-link to="/login"> 
                   <button class="btn btn-white rounded-pill  m-1"> {{ $t('login_pro.login') }}</button>
                  </router-link>
                  <router-link to="/professionnel/informations-compte">
                    <button class="btn btn-white rounded-pill  m-1">{{ $t('login_pro.register') }}</button>
                  </router-link>
                </div>
              </div>
              <div class="row justify-content-center mt-5" v-if="isLoggedIn && parseInt(role_id) === 1">
                <div class="col-md-5 ">
                 <div class="bg-info-logout-espace" style="">
                  <p v-html="$t('espace_voyageur.deconnectez_vous')"></p>
                 </div>
                </div>
              </div>
          </div>
       </div>
     </div>
  </div>
</template>


<script>

export default {

        metaInfo() {
                const descriptionFr = "Avec Msafar.ma faites la promotion de vos services : Hôtel, Riad, Maison d’hôtes, Appart-hôtel, Randonnées, Excurssions, Activités touristiques.";
                const descriptionAr = "مع مسافر.ما، قم بالترويج لخدماتك: فندق، رياض، دار ضيافة، شقة فندقية، رحلات، جولات، أنشطة سياحية.";
                const titleFr = "Msafar.ma - Espace Professionnel";
                const titleAr = "مسافر.ما - فضاء المهنيين";
                const keywordsFr = "Hôtels au Maroc, Riad au Maroc, Maisons d’hôtes au Maroc, Appart-hôtel au Maroc, Randonnées Aït Bougemez, Randonnées Akchour, Randonnées Merzouga";
                const keywordsAr = "فنادق في المغرب, رياض في المغرب, دور الضيافة في المغرب, شقة فندقية في المغرب, رحلات أيت بوجماز, رحلات أكشور, رحلات مرزوكة";
                const image = "@/assets/images/logo.png"; // Update with your actual image URL

                return {
                        title: this.$i18n.locale === "Français" ? titleFr : titleAr,
                        meta: [
                                {
                                        name: "description",
                                        content: this.$i18n.locale === "Français" ? descriptionFr : descriptionAr,
                                },
                                {
                                        name: "keywords",
                                        content: this.$i18n.locale === "Français" ? keywordsFr : keywordsAr,
                                },
                                {
                                        property: "og:title",
                                        content: this.$i18n.locale === "Français" ? titleFr : titleAr,
                                },
                                {
                                        property: "og:description",
                                        content: this.$i18n.locale === "Français" ? descriptionFr : descriptionAr,
                                },
                                {
                                        property: "og:type",
                                        content: "website",
                                },
                                {
                                        property: "og:url",
                                        content: window.location.href,
                                },
                                {
                                        property: "og:image",
                                        content: image,
                                },
                                {
                                        name: "twitter:card",
                                        content: "summary_large_image",
                                },
                                {
                                        name: "twitter:title",
                                        content: this.$i18n.locale === "Français" ? titleFr : titleAr,
                                },
                                {
                                        name: "twitter:description",
                                        content: this.$i18n.locale === "Français" ? descriptionFr : descriptionAr,
                                },
                                {
                                        name: "twitter:image",
                                        content: image,
                                },
                                {
                                        name: "robots",
                                        content: "index, follow",
                                },
                                {
                                        name: "viewport",
                                        content: "width=device-width, initial-scale=1",
                                },
                        ],
                };
        },

        name: "formLogin",
        data() {
                return {
                        role_id: [],
                        isLoggedIn: false,

                };
        },
        mounted() {
                this.$root.$on("login", () => {
                        this.isLoggedIn = true;
                });
                this.isLoggedIn = !!localStorage.getItem('token');
                this.role_id = localStorage.getItem('role_id');
        },
}
</script>
<style scoped>
.bg-espace-professionnel {
        /* background: transparent url('@/assets/images/bg-espace-professionnel.png') no-repeat;
        background-size: cover; */
        /* width: 450px; */
        margin: auto;
        /* height: 414px; */

}
.cont-p{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        padding: 30px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        min-height: 170px;
        box-shadow: 0px 3px 9px gray;
        background: transparent url('@/assets/images/login-form-01.jpg') no-repeat;
        background-size: cover;

}
.bg-espace-professionnel p {
        margin: auto;
        /* margin-top: 24px; */
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        
}

.bg-espace-btn {
        background: rgb(221, 122, 101);
        background: linear-gradient(50deg, rgba(221, 122, 101, 1) 9%, rgba(221, 125, 99, 1) 62%, rgba(216, 156, 81, 1) 93%);
        width: 450px;
        padding: 15px 40px 40px 40px;
        margin: auto;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
}

.bg-espace-btn img {
        width: 60px;
}

.cont-btn{
        background: linear-gradient(43deg, rgba(220, 122, 101, 1) 0%, rgba(218, 146, 88, 1) 100%);
        margin: 0 !important;
        padding: 23px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        box-shadow: 0px 3px 9px gray;
        min-height: 260px;
}
.bg-espace-btn .buttons a {
        color: white;

}

.bg-espace-btn p {
        color: white;
        margin-top: 15px;
        margin-bottom: 5px;
        text-align: center;

}

.bg-espace-professionnel .buttons button {
        width: 158px;
        /* height: 40px; */
        text-align: center;
        line-height: 20px;

}

.bg-espace-professionnel .btn-white {
        background-color: transparent;
        border: 1px solid white;
        color: white;
}

.bg-espace-professionnel .btn-white:hover {
        background-color: white;
        border: 1px solid white;
        color: #ff8500;
}
</style>