import axios from "axios";

// let Api = axios.create({
//   baseURL: "http://192.168.100.79:8000/api"
// });

// Api.defaults.withCredentials = true;

let BaseApi = axios.create({
  //baseURL: "http://api.msafar.ma/api"
  //baseURL: "http://localhost:83/api"
  baseURL: process.env.VUE_APP_API_URL + "/api",
});

let Api = function () {
  let token = localStorage.getItem("token");

  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return BaseApi;
};

export default Api;
