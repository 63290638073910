<template>
  <article>
    <h3 class="display-inline">Activite</h3>
    <b-button class="add-button border-info float-right" variant="info" @click="addRowHandler">Ajouter</b-button>
    <b-table class="b-table mb-5" :items="tableItems" :fields="fields" fixed>
      <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
       

        <vue-bootstrap-typeahead   v-if="field.type === 'option' && tableItems[data.index].isEdit" :key="index"
          :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)"
          :options="field.options"
              :data="field.options"
              :minMatchingChars="-1"
            />

            <vue-bootstrap-typeahead   v-else-if="field.type === 'select' && tableItems[data.index].isEdit" :key="index"
          :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)"
          :options="field.options"
              :data="field.options"
              :minMatchingChars="0"
            />
        <!--<b-form-select v-else-if="field.type === 'select' && tableItems[data.index].isEdit" :key="index"
          :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)"
          :options="field.options"></b-form-select>-->
        <b-button class="border-info btn-info" :key="index" v-else-if="field.type === 'edit'"
          @click="editRowHandler(data)">
          <span v-if="!tableItems[data.index].isEdit">            
          <i class="fa-solid fa-edit" ></i>
          </span>
          <span v-else>
          <i class="fa-solid fa-save" ></i>
          </span>
        </b-button>
        <b-form-input v-else-if="field.type && tableItems[data.index].isEdit" :key="index" :type="field.type"
          :value="tableItems[data.index][field.key]" @blur="(e) => inputHandler(e.target.value, data.index, field.key)">
        </b-form-input>
        <span :key="index" v-else>{{ data.value }}</span>
      </template>
    </b-table>
  </article>
</template>
<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: "EditableTable",
  components: {VueBootstrapTypeahead},
  props: {
    value: Array,
    fields: Array
  },
  data() {
    return {
      tableItems: this.value.map(item => ({ ...item, isEdit: false }))
    }
  },
  methods: {
    editRowHandler(data) {
      this.tableItems[data.index].isEdit = !this.tableItems[data.index].isEdit;
    },
    inputHandler(value, index, key) {
      this.tableItems[index][key] = value;
      this.$set(this.tableItems, index, this.tableItems[index]);
      this.$emit("input", this.tableItems);
    },
    addRowHandler() {
      const newRow = this.fields.reduce((a, c) => ({ ...a, [c.key]: null }), {})
      newRow.isEdit = true;
      this.tableItems.unshift(newRow);
      this.$emit('input', this.tableItems);
      console.log(this.tableItems);
    }
  }
};
</script>

<style scoped>
.add-button {
  margin-bottom: 10px;
}

.table thead th {
  border-bottom: 2px solid #dee2e6 !important;
}

.display-inline {
  display: inline;
}
</style>