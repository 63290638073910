import Api from "./Api";

export default {
    register(form) {
        return Api().post("/register", form);
    },
    registerPro(form) {
        return Api().post("/registerpro", form);
    },

    login(form) {
        return Api().post("/login", form);
    },

    async logout() {
        return Api().post("/logout");
    },

    auth() {
        return Api().get("/user");
    },
    updateprofil(user) {
        return Api().post(
            `/users/${user.id}`,
            user
            // {
            //   headers: {
            //     "Content-Type": "multipart/form-data",
            //   },
            // }
        );
    },
    updatePassword(user) {
        return Api().post(`/updatepassword/${user.id}`, user);
    },
    partageList() {
        return Api().get(`/mes-partages/1`);
    },

    ajtPartage(form) {
        return Api().post("/partages", form);
    },

    // async auth() {
    //   const token = localStorage.getItem("token");
    //   return Api.get("/user", {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     },
    //   });
    // },
};