<template>
  <div class="container-fluid">

    <div class="container min-height mt-5">

      <div class="row espace-prof top-forme justify-content-center">
        <div class="col-md-10 bg-white  shadow">
          <RubriquePro />
          <div class="row    p-5 justify-content-center">
            <div class="col-md-12 justify-content-center">
              <div class="form-group text-center">
                <div class="image-upload">
                    <label for="images">
                      <img src="@/assets/images/icone-photos.png" />
                    </label>
                    <div class="mt-4 mb-4">
                    <label class="btn btn-add-photos" for="images">Ajouter des photos</label>
                    </div>

                    <b-form-file id="images" v-model="images" ref="file"  multiple @change="onFileChange"  />
                  </div>


                <span class="text-danger" v-if="errors.name">
                  {{ errors.name[0] }}
                </span>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div class="row images-view">
                <div class="col-md-3 py-1" v-for="(image, key) in newImages" :key="key">
                  <div class="img text-center pl-2 pr-2">
                  <button type="button" class="btn btn-danger rounded-pill btn-delete-image" @click="deleteNouveauImage(key)"><i class="fa fa-times"></i></button>
                  <img class="preview" style="max-height:150px;max-width:100%;" v-bind:ref="'image' + parseInt(key)" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-5 text-center">
              <div class="form-group-check" style="">
                 <div class="round">
                      <input type="checkbox" id="checkbox" />
                      <label id="lblcheckbox" for="checkbox"></label>
                </div>
                <label for="checkbox">J'accepte les régles conditions générales d'utilisation du site msafar.ma</label>

              </div>

            </div>

            <div class="col-md-12 mt-5 text-center connectez-vous">

              <b-button class="rounded-pill text-white pl-5 pr-5 bg-msafar-primary"
                v-on:click="this.saveImages">Valider
              </b-button>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

//import http from "./../../http-common";

import RubriquePro from "@/components/EspaceProfessionnel/RubriquePro";
import Api from '@/apis/Api';

export default {
  name: "PhotosComponent",
  components: {  RubriquePro },
  data() {
    return {
      images: [],
      errors: [],
      newImages: [],
      attachments: [],
      form: new FormData,
      $path : "/images"
    };
  },
  methods: {


    onFileChange(e) {
     // this.newImages = [];
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.newImages.push(selectedFiles[i]);
      }
     // this.images = this.listImages;
     this.readImages();
    },
    readImages(){
         for (let i = 0; i < this.newImages.length; i++) {
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener('load', function () {
          this.$refs['image' + parseInt(i)][0].src = reader.result;
        }.bind(this), false);  //add event listener

        reader.readAsDataURL(this.newImages[i]);
      }

    },
    deleteNouveauImage(index){
           this.newImages.splice(index, 1);
           this.$refs.file.files.splice(index, 1);
           this.readImages();

           console.log('newImages');
           console.table(this.newImages);

           console.log('files');
           console.table(this.$refs.file.files);
    },
    saveImages() {

      let formData = new FormData();
      for (var i = 0; i < this.newImages.length; i++) {
        let file = this.newImages[i];
        formData.append('images[' + i + ']', file);
      }
      this.path = "/images";
      if(this.$typeService.value == 'hotel')
      formData.append("id_hotel", this.$newhotel.value.id);
      if(this.$typeService.value == 'riad')
      formData.append("id_riad", this.$newhotel.value.id);
      if(this.$typeService.value == 'maison-hote')
      formData.append("id_maison_hote", this.$newhotel.value.id);
      if(this.$typeService.value == 'restaurant')
      formData.append("id_restaurant", this.$newhotel.value.id);
      if(this.$typeService.value == 'appart-hotels')
      formData.append("id_apparthotels", this.$newhotel.value.id);

      if(this.$typeService.value == 'guide'){
        formData.append("id_guide", this.$newhotel.value.id);
        this.path = "/post-images";
      }
      if(this.$typeService.value == 'activite'){
        formData.append("id_activite", this.$newhotel.value.id);
        this.path = "/post-images";
      }

      Api().post(this.path, formData).then(response => {
                 this.$router.push("/professionnel/felicitation");
                  console.log(response);
                 // this.$toaster.success('Votre espace professionnel a bien été créé.')
                  //setTimeout(function () { this.$router.push("/login"); }, 6000)
      })
        .catch(response => {
          //error
          console.log(response);
          this.$toaster.error(response.data)
        });

    }

  }
  ,
  created() {
    window.scrollTo(0, 350);
    console.log(this.$hotel.value);

  }
}
</script>
<style scoped>
legend {
  display: none!important;
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: 'Parcourir' !important;
}

.custom-file-input~.custom-file-label {
  border: 2px solid #008ec6;
}
.form-group-check label{
  font-size: 18px;
}
.form-group-check{
  width: 550px;margin: auto;
}

/* style */

.round {
      position: relative;
    width: 29px;
    float: left;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
    border: 2px solid #4caf50;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    width: 22px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: white;
  border-color: #4caf50;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.image-upload img{
    border-radius: 100px;
    width: 150px;
    cursor: pointer;
}
.image-upload>.b-form-file {
  display: none;
}

.btn-delete-image{
    position: absolute;
    right: 7px;
    top: 8px;
    padding: 0px 6px;
    background-color: #ececec;
    border-color: #ececec;
    font-size: 14px;
}
.images-view .img{

    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio : 1 / 1;
    background-color: #ccccccc2;

}
.images-view .col-md-3{
    padding-right: 4px;
    padding-left: 4px;
}

.btn-add-photos ,.btn-add-photos:hover{
    color: #008bca;
    background-color: #fff;
    border-color: #008bca;
    border-radius: 50px;
    padding: 10px 40px;
    font-size: 22px;
}


</style>