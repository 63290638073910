<template>
  <div class="row hotels">
    <div class="col-md-4 py-3" :class="id_activite ? 'col-md-6' : ''" v-for="item of actvites" :key="item.id">
      <div v-if="id_activite">
        <div class="card">
          <img v-if="item.image" :src="BaseUrl + '/storage/' + item.image" class="card-img-top responsive-img" />
          <img v-else src="@/assets/images/placeholder-image.jpg" class="card-img-top responsive-img" />
          <div class="card-body">
            <p style="color: rgb(108 108 108);">
              <span v-if="item.from_date && item.to_date">
                {{ item.from_date+' - '+ item.to_date  }}
              </span>
            </p>
            <div v-if="item.activite">
              <h5 class="card-title">{{ item.activite.toLowerCase() }}</h5>
            </div>
            <p class="card-text" style="color: #007abc;">
              <img src="@/assets/images/localisation.png" class="map-marker" />
              <span v-if="$i18n.locale === 'Français' || $i18n.locale === 'English'">
                {{ item.activte.ville ? item.activte.ville.ville : '' }}
              </span>
              <span v-else>
                {{ item.activte.ville ? item.activte.ville.ville_ar : '' }}
              </span>
            </p>
            <div class="d-flex justify-content-between">
              <p class="tele"  v-if="!id_activite "><img  v-if="item.activte.whatsapp" src="@/assets/images/whatsapp.png" class="map-marker" />{{ item.activte.whatsapp }}</p>
              <a class="programme" :href="BaseUrl + '/storage/' + item.programme"  v-if="id_activite && item.programme"   download >{{ $t('forms.activite_pro.programme') }}</a>
              <p class="prix" v-if="item.prix_actv">{{ item.prix_actv}} DH</p>
            </div>
          </div>
        </div>
      </div>
      <router-link v-else :to="`/${item.activte.url}/` + ($i18n.locale === 'Français' || $i18n.locale === 'English' ? item.activte.slug : (item.activte.slug_ar || item.activte.slug))">
        <div class="card">
          <img v-if="item.image" :src="BaseUrl + '/storage/' + item.image" class="card-img-top responsive-img" />
          <img v-else src="@/assets/images/placeholder-image.jpg" class="card-img-top responsive-img" />
          <div class="card-body">
            <p style="color: rgb(108 108 108);">
              <span v-if="item.from_date && item.to_date">
                {{ item.from_date+' - '+ item.to_date  }}
              </span>
            </p>
            <div v-if="item.activite">
              <h5 class="card-title">{{ item.activite.toLowerCase() }}</h5>
            </div>
            <p class="card-text" style="color: #007abc;">
              <img src="@/assets/images/localisation.png" class="map-marker" />
              <span v-if="$i18n.locale === 'Français' || $i18n.locale === 'English'">
                {{ item.activte.ville ? item.activte.ville.ville : '' }}
              </span>
              <span v-else>
                {{ item.activte.ville ? item.activte.ville.ville_ar : '' }}
              </span>
            </p>
            <div class="d-flex justify-content-between">
              <p class="tele"  v-if="!id_activite "><img  v-if="item.activte.whatsapp" src="@/assets/images/whatsapp.png" class="map-marker" />{{ item.activte.whatsapp }}</p>
              <a class="programme" :href="BaseUrl + '/storage/' + item.programme"  v-if="id_activite && item.programme"   download >{{ $t('forms.activite_pro.programme') }}</a>
              <p class="prix" v-if="item.prix_actv">{{new Intl.NumberFormat().format(item.prix_actv) }} DH</p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "ActivitesComp",
  props: {
    actvites: Array,
    slug: String,
    BaseUrl: String,
    id_activite: String,
  }
};
</script>

<style scoped>
.hotels .card{
  width: 100%;
  min-height: 450px;
}
.prix{
  background-color: #fecb01;
    padding: 3px 18px;
    border-radius: 20px;
    display: flex;
    box-shadow: 2px 2px 5px;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 1rem;
    height: 31px;
}.tele{
  display: flex;
    align-items: center;
    justify-content: center;
    color: #62c952;
}
.card-text img ,.tele img {
  max-height:20px;
  margin-right: 5px;
}
.programme{
  color: #0056b3 !important;
}
</style>
