<template>
  <div class="container-fluid">
    <div class="container min-height">
      <div class="row espace-voyageur top-forme justify-content-center mt-5">
        <div class="col-md-8 col-12 bg-white p-5 bg-login">
          <div class="row">
            <div class="container">
              <h1 class=""><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                  src="@/assets/images/img-bladi.png" />Mot de passe oublié</h1>
              <form @submit.prevent="submit">
                <input v-model="email" type="email" class="form-control" placeholder="Entrer votre Email">
                <div class="col-md-11 mt-3">
                  <button type="submit" class="btn btn-success mr-2" :disabled="loading">
                    <span v-if="loading"><b-spinner label="Busy"></b-spinner></span>
                    Envoyer le lien
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http-common";
export default {
  data() {
    return {
      email: '',
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await http.post('/forget-password', { email: this.email });
        this.loading = false;
        this.$toaster.success("Le lien de réinitialisation a été envoyé avec succès ! Veuillez regarder votre boîte de réception email.");
        this.email = "";
      } catch (error) {
        this.loading = false;
        console.error(error);
        alert('An error occurred.');
      }
    },
  },
};
</script>
<style scoped>
.btn-success {
  background-color: white !important;
  background: white !important;
  color: #1e594d !important;
  border: 1px solid #1e594d !important;
  padding: 1px 40px;
  border-radius: 25px;
  font-family: GillSansMT;
  font-size: 20px;
}

.btn-success:hover {
  background-color: #008ec6 !important;
  background: #008ec6 !important;
  border: 1px solid #008ec6 !important;
  color: white !important;
}

.espace-voyageur a {
  font-family: GillSansMT;
  font-size: 14px !important;
  background: none !important;
  background: none !important;
  color: #008ec6 !important;
  padding: none !important;
  border-radius: none !important;
}

.bg-login {
  background: transparent url('@/assets/images/bg-connexion.png') no-repeat;
  background-size: cover;
  width: 450px;
  padding: 54px;
  margin: auto;
  height: 490px;
  /* margin-left: -17px;
    margin-right: -17px;
    margin-top: -17px;
    height: 414px; */
}

.form-control:focus,
.password-eye:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.password-eye {
  background-color: #eeeeee;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid #ced4da;
}

.password-eye i {
  color: #bbbbbb;
}

.spinner-border {
  width: 20px !important;
  height: 20px !important;
}

@media screen and (max-width: 700px) {
  .bg-login {
    background: transparent url('@/assets/images/fond-mobile-login.png') no-repeat;
    /* background-size: cover;
    width: 450px;
    padding: 54px;
    margin: auto;
    height: 490px;
     margin-left: -17px;
    margin-right: -17px;
    margin-top: -17px;
    height: 414px; */
    /*  background-size: cover;
    padding-top: 180px!important;
    padding-bottom: 140px!important;
    height: auto;*/
    background-size: contain;
  }
}
</style>