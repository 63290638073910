<template>
  <div class="container-fluid">
 
    <div class="container min-height mt-5">

      <div class="row espace-prof top-forme justify-content-center">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro />
          <div class="row descriptif-body p-3">
              

                         <div class="col-md-12">
                          <div class="form-group">

                            <label class="control-label" for="">Description</label>
                                <textarea class="control-label" v-model="description" style="width:100%;" name="" id="" cols="30" rows="7"></textarea>
                        </div>
                  </div>


                    <div class="col-md-12">
                        <h3 class="p-3 m-3">Ajouter les menus</h3>

                      <div class="accordion" role="tablist">
                        <div class="text-center">
                              <b-button class="m-auto entree border-0" v-b-toggle.accordion-1 > Entrée
                                 <span class="when-opened">
                                <b-icon-chevron-down  />
                            </span>
                            <span class="when-closed">
                                <b-icon-chevron-right  />
                            </span>
                              </b-button>
                           </div>
                            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">                            
                                    <div class="">

                                      <div class="form-group">
                                        <label class="p-2" for="">Nom </label>
                                        <input type="text" v-model="menu"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                      </div>
                                     <div class="form-group">
                                        <label class="p-2" for="">Description </label>
                                        <textarea type="text" v-model="descriptionMenu"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder=""></textarea>
                                      </div>

                                       <div class="form-group">
                                        <label class="p-2" for="">Prix </label>
                                        <input type="number" v-model="prix"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                      </div>

                                      <div class="form-group text-center">
                                      
                                        <button class="btn btn-primary m-auto" v-on:click="addEntree"><b-icon-plus></b-icon-plus></button>
                                      </div>

                                   </div>

                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>Menu</th>
                                        <th>Description</th>
                                        <th>Prix (DH)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item,index) in itemsEntree" :key="index">
                                        <td>{{ item.menu }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.prix}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                         
                            </b-collapse>
                      

                          
                           
                             <div class="text-center m-2">
                              <b-button class="m-auto plats border-0" v-b-toggle.accordion-2 >  Plats 
                                 <span class="when-opened">
                                <b-icon-chevron-down  />
                            </span>
                            <span class="when-closed">
                                <b-icon-chevron-right  />
                            </span>
                              </b-button>
                           </div>
                      
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                             
                                   <div class="">

                                      <div class="form-group">
                                        <label class="p-2" for="">Nom </label>
                                        <input type="text" v-model="menu"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                      </div>
                                     <div class="form-group">
                                        <label class="p-2" for="">Description </label>
                                        <textarea type="text" v-model="descriptionMenu"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder=""></textarea>
                                      </div>

                                       <div class="form-group">
                                        <label class="p-2" for="">Prix </label>
                                        <input type="number" v-model="prix"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                      </div>

                                      <div class="form-group text-center">
                                      
                                        <button class="btn btn-primary m-auto" v-on:click="addPlats"><b-icon-plus></b-icon-plus></button>
                                      </div>

                                   </div>

                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>Menu</th>
                                        <th>Description</th>
                                       <th>Prix (DH)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item,index) in itemsPlats" :key="index">
                                        <td>{{ item.menu }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.prix}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                            
                            </b-collapse>
                      

                       
                           
                               <div class="text-center m-2">
                              <b-button class="m-auto dessert border-0" v-b-toggle.accordion-3 >  Dessert 
                                 <span class="when-opened">
                                <b-icon-chevron-down  />
                            </span>
                            <span class="when-closed">
                                <b-icon-chevron-right  />
                            </span>
                              </b-button>
                           </div>
                           
                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                              
                                   <div class="">

                                      <div class="form-group">
                                        <label class="p-2" for="">Nom </label>
                                        <input type="text" v-model="menu"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                      </div>
                                     <div class="form-group">
                                        <label class="p-2" for="">Description </label>
                                        <textarea type="text" v-model="descriptionMenu"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder=""></textarea>
                                      </div>

                                       <div class="form-group">
                                        <label class="p-2" for="">Prix </label>
                                        <input type="number" v-model="prix"
                                          class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                      </div>

                                      <div class="form-group text-center">
                                      
                                        <button class="btn btn-primary m-auto" v-on:click="addDessert"><b-icon-plus></b-icon-plus></button>
                                      </div>

                                   </div>

                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>Menu</th>
                                        <th>Description</th>
                                        <th>Prix (DH)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item,index) in itemsDessert" :key="index">
                                        <td>{{ item.menu }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.prix}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                         
                            </b-collapse>
                       
                        </div>

                    </div>

                 <div class="col-md-6 ml-4">
                       <h4 >Prix Moyens du menu</h4>           
                      <div class="form-group form-row mt-4">
                        <label class="col-sm-3" for="">Prix Min</label>
                        <input type="number" v-model="prixMin" id="" class="form-control col-sm-7" placeholder="" aria-describedby="helpId">          
                      </div>

                      <div class="form-group form-row">
                        <label class="col-sm-3" for="">Prix Max</label>
                        <input type="number" v-model="prixMax" id="" class="form-control col-sm-7" placeholder="" aria-describedby="helpId">          
                      </div>
                </div>


        <div class="col-md-12 m-5">
                                <button type="submit" class="bg-msafar-primary btn text-white rounded-pill pl-5 pr-5 pt-2 pb-2"
                                    @click="validate">Passer à l étape
                                    suivante</button>
                                <span class="h1 color-msafar-primary flesh" style="">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </span>
                 </div>


          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import RubriquePro from "@/components/EspaceProfessionnel/RubriquePro";
import http from "./../../http-common";


export default {
  name: "RestaurantEspaceProComponent",
  components: { RubriquePro },
 data() {
       return {
    text:'',
    description:'',
    prixMin:"",
    prixMax:"",
    itemsEntree:[],    
    itemsPlats:[],    
    itemsDessert:[],    
    menu:'',
    descriptionMenu:'',
    prix:0,
    id_restaurant:"",
  }
  },
  methods: {
    //function to add data to table    
    addEntree(){
      this.itemsEntree.push({menu:this.menu,description:this.descriptionMenu,prix:this.prix});
      this.vider();
    },
    addPlats(){
      this.itemsPlats.push({menu:this.menu,description:this.descriptionMenu,prix:this.prix});
      this.vider();
    },
    addDessert(){
      this.itemsDessert.push({menu:this.menu,description:this.descriptionMenu,prix:this.prix});
      this.vider();
    },
    vider(){
      this.menu = '';this.descriptionMenu = '';this.prix = '';
    },
    validate(){
              
              this.$hotel.value['description'] = this.description;
              this.$hotel.value['prix_min'] = this.prixMin;
              this.$hotel.value['prix_max'] = this.prixMax;
              this.$hotel.value['user_id'] = this.$user.value.user.id;

            http.post('/restaurants',this.$hotel.value)
                .then((response) => {
                    this.$newhotel.value = response.data
                    this.id_restaurant = response.data.id;
                    this.addMenus();
                   // this.$router.push("/professionnel/photos");                   
                })
                .catch(e => {
                    console.log(e);
                    this.$toaster.error(e.data)
                })
                
    },
    addMenus(){

                var formData = new FormData();
                formData.append('itemsEntree', JSON.stringify(this.itemsEntree));
                formData.append('itemsPlats', JSON.stringify(this.itemsPlats));
                formData.append('itemsDessert', JSON.stringify(this.itemsDessert));
                
                formData.append('id_restaurant', this.id_restaurant);

                http.post('/ligne-menus',formData)
                  .then((response) => {
                        console.log(response.data);
                  })
                  .catch(e => {
                      console.log(e);
                      this.$toaster.error(e.data)
                  })
        }
  }
}
</script>

<style scoped>
.connectez-vous a {
  font-family: GillSansMT;
  font-size: 15px;
  background: transparent;
  color: rgb(152, 26, 29);
  padding: 0px 40px;
  border-radius: 25px;
}

.image-bladi {
  margin-top: -4px;
  margin-right: -30px;
  width: 30px;
}



.image-bladi-right {
  margin-top: -4px;
  margin-left: -30px;
  width: 30px;
}


    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }

    .entree{
      background-color: #ff7a01;
    }
    .plats{
      background-color: #1fa4cd;
    }
    .dessert{
      background-color: #215b4f;
    }
    .entree,.plats,.dessert{
      color: white;      
    }
  .border-0,.btn-primary{
    border: 0px!important;
  }

</style>