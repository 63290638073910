<template>
  <div class="container-fluid">

    <Rubrique />

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Page Reservation</h1>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Rubrique from "@/components/Outils/Rubrique";


export default {
  name: "ReservationComponent",
  components: {Rubrique}

}
</script>

<style scoped>

</style>