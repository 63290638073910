<template>
    <b-modal id="modal-reservation" size="lg" class="" hide-footer>
        <div>
            <div class="modal-body">
                <!-- <h2 class="border-primary text-center color mb-3">Réservez votre {{ title }} dès
                    maintenant {{ owner_email }}
                </h2> -->
                <div class="row">
                    <!--Grid column-->
                    <div class="col-md-12 mb-md-0 mb-5">
                        <form name="contact-form" class="add-form">
                            <!--Grid row-->
                            <div class="row">
                                <!--Grid column-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="name" class="">Votre nom</label>
                                        <input type="text" v-model="username" class="form-control">
                                        <span class="text-danger" v-if="errors.username">
                                            {{ errors.username[0] }}
                                        </span>
                                    </div>
                                </div>
                                <!--Grid column-->
                                <!--Grid column-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="email" class="">Votre email</label>
                                        <input type="text" v-model="email" class="form-control">
                                        <span class="text-danger" v-if="errors.email">
                                            {{ errors.email[0] }}
                                        </span>
                                    </div>
                                </div>  
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="tel" class="">Votre Téléphone</label>
                                        <input type="text" v-model="tel" class="form-control">
                                        <span class="text-danger" v-if="errors.tel">
                                            {{ errors.tel[0] }}
                                        </span>
                                    </div>
                                </div>
                                <!--Grid column-->
                            </div>
                            <!-- {{ owner_email }} -->
                            <!--Grid row-->
                            <!--Grid row-->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="subject" class="">Objet</label>
                                        <input type="text" v-model="subject" class="form-control">
                                        <span class="text-danger" v-if="errors.subject">
                                            {{ errors.subject[0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--Grid row-->
                            <!--Grid row-->
                            <div class="row">
                                <!--Grid column-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="message">Votre message</label>
                                        <textarea type="text" v-model="message" rows="4"
                                            class="form-control md-textarea"></textarea>
                                        <span class="text-danger" v-if="errors.message">
                                            {{ errors.message[0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--Grid row-->
                        </form>
                        <div class=" espace-voyageur ">
                            <button class="btn btn-primary pl-5 pr-5 mt-2" @click="changeName()">Envoyer</button>
                        </div>
                    </div>
                    <!--Grid column-->
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
        <div slot="modal-footer">
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'ReservtionModal',
    props: {
        title: String,
        owner_email: String,
        hotel_id: Number,
        riad_id: Number,
        maison_id: Number,
        restaurant_id: Number,
        activite_id: Number,
        guide_id: Number,
        errors: Array,
    },
    data() {
        return {
            username: '',
            email: '',
            tel: '',
            subject: '',
            message: '',
            id_hotel: '',
            id_riad: '',
            id_maison_hotel: '',
            id_restaurant: '',
            id_activite: '',
        }
    },
    methods: {
        changeName() {
            const addBooking = {
                username: this.username,
                email: this.email,
                tel: this.tel,
                owner_email: this.$props.owner_email,
                subject: this.subject,
                message: this.message,
                id_hotel: this.$props.hotel_id,
                id_riad: this.$props.riad_id,
                id_maison_hote: this.$props.maison_id,
                id_restaurant: this.$props.restaurant_id,
                id_activite: this.$props.activite_id,
                id_guide: this.$props.guide_id,
            };
            this.$emit('Reserver', addBooking)
            // this.username = "";
            // this.email = "";
            // this.subject = "";
            // this.message = "";
        },
    },
}
</script>

<style scoped>
.color {
    color: #653010;
}
.btn,.btn:hover{
    color: #fff;
    background-color: #98191a;
    border-radius: 20px;
    border-color: #98191a;
}
</style>