import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import router from "./router";
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-range-component/dist/vue-range-slider.css";
//import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import StructuredData from "./StructuredData";
// import VueI18n from "vue-i18n";

Vue.use(Vuelidate);
StructuredData.addStructuredData();

// Vue.use(VueI18n);
// const messages = {
//   ar: {
//     message: {
//       hello: "{name} مرحبا",
//       welcome: "اهلا وسهلا",
//       yes_button: "نعم",
//       no_button: "لا",
//     },
//   },
//   en: {
//     message: {
//       hello: "Hello {name}",
//       welcome: "Welcome!",
//       yes_button: "Yes",
//       no_button: "No!",
//     },
//   },
//   de: {
//     message: {
//       hello: "Guten Tag, {name}!",
//       welcome: "Willkommen!",
//       yes_button: "Ja",
//       no_button: "Nein!",
//     },
//   },
// };

// const i18n = new VueI18n({
//   locale: "en",
//   messages,
// });

// import VueSweetalert2 from "vue-sweetalert2";
// import 'sweetalert2/dist/sweetalert2.min.css';
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import VueMeta from 'vue-meta';
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("d MMMM, YYYY hh:mm");
  }
});

//localStorage.clear();

Vue.prototype.$user = { value: "" };
Vue.prototype.$typeService = { value: "hotel" };
Vue.prototype.$newhotel = { value: "" };
Vue.prototype.$hotel = { value: null };

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.use(IconsPlugin);

library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);
// Vue.use(VueSweetalert2);
Vue.use(Toaster, { timeout: 5000 });
Vue.use(VueMeta);

Vue.prototype.$apiUrl = "http://192.168.100.79:8000";
new Vue({
  router,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
