<template>
    <div class="container-fluid">
        <div class="container min-height">
            <div class="row espace-prof top-forme justify-content-center text-left mt-5">
                <div class="col-md-10 bg-white coordonnees-activite-body shadow">
                    <RubriquePro :niveau2="1" :niveau3="0" :niveau4="0" :level="isDataAvailable" />
                    <form @submit.prevent="handleSubmit">
                        <div class="row  p-5 dir-rtl">
                            <div class="col-md-12 mb-5"><i class="fa fa-info-circle" aria-hidden="true"></i> <span
                                    v-html="$t('forms.hotel_pro.obligatoires')"></span></div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="nom">{{ $t('forms.hotel_pro.nom_etablissement') }} <sup>*</sup></label>
                                    <input type="text" v-model="form.nom" id="nom" name="nom" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.nom.$error }" />
                                    <div v-if="submitted && !$v.form.nom.required" class="invalid-feedback">
                                        {{ $t('forms.validations.required', {
                                                name: $i18n.locale === 'Français' ? 'Nom de l\'établissement en français' : 'إسم المؤسسة'
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="nom_ar">{{ $t('forms.hotel_pro.nom_etablissement_ar') }} <sup></sup></label>
                                    <input type="text" v-model="form.nom_ar" id="nom_ar" name="nom_ar" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="user.type_service === 'hotel'">
                                <div class="form-group">
                                    <label class="control-label" for="">{{ $t('forms.hotel_pro.nb_etoils') }} </label>
                                    <b-form-input type="number" v-model="form.nbr_etoiles"
                                        aria-label="Text input with checkbox"></b-form-input>
                                    <span class="text-danger" v-if="errors.nbr_etoiles">
                                        {{ errors.nbr_etoiles[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="tel">{{ $t('forms.hotel_pro.num_tele') }} <sup>*</sup></label>
                                    <input type="text" v-model="form.tel" id="tel" name="tel" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.tel.$error }" />
                                    <div v-if="submitted && !$v.form.tel.required" class="invalid-feedback">
                                        {{ $t('forms.validations.required', {
                                                name: $i18n.locale === 'Français' ?
                                                    'Téléphone' : 'رقم الهاتف'
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="email">{{ $t('forms.connexion.email') }} <sup>*</sup></label>
                                    <input type="text" v-model="form.email" id="email" name="email" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.email.$error }" />
                                    <div v-if="submitted && !$v.form.email.required" class="invalid-feedback">
                                        {{ $t('forms.validations.required', {
                                                name: $i18n.locale === 'Français' ?
                                                    'Email' : 'البريد الإلكتروني'
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="">{{ $t('forms.hotel_pro.whatsapp') }}</label>
                                    <b-form-input type="text" v-model="form.whatsapp"
                                        aria-label="Text input with checkbox"></b-form-input>
                                    <span class="text-danger" v-if="errors.whatsapp">
                                        {{ errors.whatsapp[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="">{{ $t('forms.hotel_pro.ville') }}
                                        <sup>*</sup></label>
                                    <select v-model="form.ville_id" id="ville_id" name="ville_id" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.ville_id.$error }">
                                        <option value="" selected></option>
                                        <option v-for="ville of villes" :key="ville.id" :value="ville.id">{{ ville.ville
                                        }}
                                        </option>
                                    </select>
                                    <div v-if="submitted && !$v.form.ville_id.required" class="invalid-feedback">
                                        {{ $t('forms.validations.required', {
                                                name: $i18n.locale === 'Français' ?
                                                    'Ville' : 'المدينة'
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="adresse">{{ $t('forms.hotel_pro.adresse') }} <sup>*</sup></label>
                                    <input type="text" v-model="form.adresse" id="adresse" name="adresse"
                                        class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.adresse.$error }" />
                                    <div v-if="submitted && !$v.form.adresse.required" class="invalid-feedback">
                                        {{ $t('forms.validations.required', {
                                                name: $i18n.locale === 'Français' ?
                                                    'Adresse' : 'العنوان '
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="adresse_ar">{{ $t('forms.hotel_pro.adresse_ar') }}</label>
                                    <input type="text" v-model="form.adresse_ar" id="adresse_ar" name="adresse_ar"
                                        class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.adresse_ar.$error }" />
                                    <!-- <div v-if="submitted && !$v.form.adresse_ar.required" class="invalid-feedback">
                                        {{ $t('forms.validations.required', {
                                                name: $i18n.locale === 'Français' ?
                                                    'adresse_ar' : 'العنوان بالعربية '
                                            })
                                        }}
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="confirmez-password">{{
                                            $t('forms.hotel_pro.site_web')
                                    }}</label>
                                    <b-form-input type="text" v-model="form.website"
                                        aria-label="Text input with checkbox">
                                    </b-form-input>
                                    <span class="text-danger" v-if="errors.website">
                                        {{ errors.website[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="confirmez-password">{{ $t('forms.hotel_pro.map') }}</label>
                                    <b-form-input type="text" v-model="form.map"
                                        aria-label="Text input with checkbox"></b-form-input>
                                    <span class="text-danger" v-if="errors.map">
                                        {{ errors.map[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="confirmez-password">{{
                                            $t('forms.hotel_pro.instagram')
                                    }}</label>
                                    <b-form-input type="text" v-model="form.instagram"
                                        aria-label="Text input with checkbox"></b-form-input>
                                    <span class="text-danger" v-if="errors.instagram">
                                        {{ errors.instagram[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="confirmez-password">{{
                                            $t('forms.hotel_pro.facebook')
                                    }}</label>
                                    <b-form-input type="text" v-model="form.facebook"
                                        aria-label="Text input with checkbox">
                                    </b-form-input>
                                    <span class="text-danger" v-if="errors.facebook">
                                        {{ errors.facebook[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label" for="confirmez-password">{{
                                            $t('forms.hotel_pro.tiktok')
                                    }}</label>
                                    <b-form-input type="text" v-model="form.tiktok"></b-form-input>
                                    <span class="text-danger" v-if="errors.tiktok">
                                        {{ errors.tiktok[0] }}
                                    </span>
                                </div>
                            </div>
                            <div :class="[this.$i18n.locale === 'العربية' ? 'float-right':'' , 'col-md-12 mt-5 ']">
                                <button type="submit"
                                    :class="[isDataAvailable ? 'btn-outline-msafar' : 'bg-msafar-primary text-white', 'btn rounded-pill pl-4 pr-5 pt-2 pb-2']">
                                    <i class="fas fa-check-circle mr-3"></i>
                                    <span v-if="isDataAvailable">{{ $t('forms.inscription.modifier') }}</span>
                                    <span v-else>{{ $t('forms.inscription.valider_pro') }}</span>
                                </button>

                                <div v-if="isDataAvailable"
                                    :class="this.$i18n.locale === 'العربية' ? 'float-left' : 'float-right'">
                                    <button type="button" @click="etapeSuivante()"
                                        class="bg-msafar-primary btn text-white rounded-pill  pl-5 pr-5 pt-2 pb-2">
                                        {{ $t('forms.inscription.etapes') }}
                                        <span class="h1 color-msafar-primary flesh-profil">
                                            <b-icon-chevron-left  v-if="this.$i18n.locale === 'العربية'" style="margin-right: 40px;margin-bottom: 20px;"></b-icon-chevron-left>
                                             <b-icon-chevron-right  v-else></b-icon-chevron-right>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import User from "@/apis/User";
//import Api from "@/apis/Api";
import Service from "@/apis/Services";
import http from "./../../http-common";
import { required } from 'vuelidate/lib/validators'

export default {
    name: "CoordonneesActivitesProfil",
    components: { RubriquePro },
    data() {
        return {
            form: {
                id: "",
                nom: "",
                nom_ar: "",
                email: "",
                nbr_etoiles: "",
                tel: "",
                website: "",
                adresse: "",
                adresse_ar: "",
                map: "",
                facebook: "",
                tiktok: "",
                whatsapp: "",
                instagram: "",
                ville_id: "",
            },
            errors: [],
            villes: [],
            user: [],
            linkToService: '',
            submitted: false,
            isDataAvailable: false
        };
    },
    validations: {
        form: {
            nom: { required },
            email: { required },
            tel: { required },
            ville_id: { required },
            adresse: { required },
        }
    },
    mounted() {

        this.getUser();

    },
    methods: {
        handleSubmit() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            // const input = document.querySelector("#tel");
            // const iti = window.intlTelInputGlobals.getInstance(input);
            // const countryCode = iti.getSelectedCountryData().dialCode;
            // const phoneNumber = input.value.replace(/\D/g, "");
            // this.form.tel="+" + countryCode + phoneNumber;
            // if(phoneNumber.length === 9){
            // }
            // this.form.tel= phoneNumber;
            this.form.user_id = this.user.id;
            // console.log(this.form);
            Service.updateHebergement(this.form.id, this.user.type_service, this.form).then((response) => {
                // console.log(response.data);
                this.form = response.data;
                this.isDataAvailable = true;
                this.$toaster.success('Votre espace professionnel a bien été modifié')
                this.$router.push("/profil-pro/" + this.user.type_service);
                // const input = document.querySelector("#tel");
                // const iti = window.intlTelInputGlobals.getInstance(input);
                // iti.destroy();
                // input.removeAttribute('style');
            }).catch(error => {
                console.log(error);
            });

        },
        etapeSuivante() {
            if (this.isDataAvailable) {
                this.$router.push("/profil-pro/" + this.user.type_service);
            } else {
                this.$toaster.info('Merci de valider le formulaire pour passer à l\'étape suivante')
            }
        },
        getUser() {
            User.auth().then((response) => {
                // console.log(response.data);
                this.user = response.data;
                this.getHebergement();

                var typeService = response.data.type_service;
                if (typeService == 'hotel')
                    this.linkToService = 'HotelProProfil';
                else if (typeService == 'riad')
                    this.linkToService = 'RiadProProfil';
                else if (typeService == 'maison-hote')
                    this.linkToService = 'MaisonHoteProProfil';
                else if (typeService == 'restaurant')
                    this.linkToService = 'RestaurantProProfil';
                else if (typeService == 'activite')
                    this.linkToService = 'ActiviteProProfil';
                else if (typeService == 'guide')
                    this.linkToService = 'GuideProProfil';
                else if (typeService == 'appart-hotels')
                    this.linkToService = 'AppartHotelProfil';
            }).catch(error => {
                console.log(error);
            });
        },

        getHebergement() {
            Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
                this.isDataAvailable = response.data.length !== 0;
                // console.log(this.isDataAvailable );
                if (this.isDataAvailable) {
                    this.form = response.data;
                    // console.log('true');
                }
                else{
                    // const input = document.querySelector("#tel");
                    // window.intlTelInput(input, {
                    //     separateDialCode: true,
                    //     initialCountry: "ma",
                    //     utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js"
                    // });
                }
                /*else{
                   //this.$toaster.error('Valider le formulaire pour passer à l\'étape suivante')
                }*/

            }).catch(error => {
                console.log(error);
            });
        }
    },

    created() {
        http.get('/villes')
            .then((response) => {
                this.villes = response.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    }

}
</script>

<style>
.iti--allow-dropdown  {
    width: 100% !important;
}
</style>
