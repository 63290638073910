<template>
    <div class="col-md-12">
        <div class="col-md-12 line mb-5"></div>
        <!-- <b-icon class="float-right" v-b-modal.modal-add icon="plus-square" font-scale="2.7" variant="grey">
                </b-icon> -->
        <div class="cold-md-12 d-flex">
            <a style="color: #0056b3;display: flex;align-items: center;font-size: 23px;margin-bottom: 20px;cursor: pointer;">
                {{$t('rubrique.ajt-part')}}
                <b-icon style="margin-left: 20px;margin-right: 20px;" class="float-right" @click="showModal()" v-b-modal.modal-add icon="plus-square"
                font-scale="1" variant="grey"
                v-b-popover.hover.top="$t('rubrique.ajt-part')">
                </b-icon>
            </a>
        </div>     
        <div class="row top-forme actualites margin-auto p-0">
            <template v-if="partages && partages.length > 0">
            <div class="col-md-4 py-3" v-for="(partage, index) in partages" :key="index">
                <div class="card" style="">
                    <!-- <img src="http://placehold.jp/300x200.png" class="card-img-top" alt="...">-->
                    <div class="cont-background" :style="{ 'background-image': 'url(' + BaseUrl + '/storage/' + partage.image + ')' }">
                     </div>
                    <div class="card-body">
                        <span class="card-description">{{ partage.created_at | formatDate }} </span>
                        <h5 class="card-title">{{ partage.titre }}</h5>
                        <router-link v-bind:to="'/partages/' + partage.slug">
                            <span class=" lire-plus">
                                <b-icon-arrow-right> </b-icon-arrow-right>Ouvrir l'emplacement
                            </span>
                        </router-link>
                        <div class="h2 mb-0">
                            <a href="#" v-b-modal.modal-update @click="edit(index)" class="mr-2">
                                <b-icon icon="pen" class="rounded-circle bg-warning p-2" variant="light">
                                </b-icon>
                            </a>
                            <a href="#" @click="showMsgBoxTwo(partage.id)">
                                <b-icon icon="trash" class="rounded-circle bg-danger p-2" variant="light">
                                </b-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            </template>
            <template v-else>                
                <div class="alert  w-100">
                    <h3>{{ $t("Partages.text") }}</h3>
                </div>
            </template>

        </div>
        <div>

            <b-modal ref="modal-update" id="modal-update" title="Modfier votre partage" size="lg">
                <div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 mb-md-0 mb-5">
                                <form id="contact-form" name="contact-form">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="name" class="">Votre titre</label>
                                                <input type="text" id="name" v-model="editInput.titre"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="message">Votre message</label>
                                                <textarea type="text" id="message" name="message" rows="4"
                                                    class="form-control md-textarea"
                                                    v-model="editInput.description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3 ">
                                            <div class="form-group">
                                                <label for="message">{{ $t('recherche.villes') }}</label>
                                                <select class="form-control" v-model="editInput.ville_id">
                                                <option value="" readonly>
                                                    {{ $t("recherche.villes") }}
                                                </option>
                                                <option v-for="ville of villes" :key="ville.id"  :value="ville.id">
                                                    {{
                                                    $i18n.locale === "Français"  || $i18n.locale == "English" ? ville.ville : ville.ville_ar
                                                    }}
                                                </option>
                                                </select>
                                                <span class="text-danger" v-if="errors.description">
                                                {{ errors.description[0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="form-group">
                                                <label for="email">Votre Image</label>
                                                <input type="file" id="image" @change="onFileChange1"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <img v-if="preview1" :src="preview1" width="100px" height="100px" />
                                            <img v-else :src="BaseUrl + '/storage/' + editInput.image" alt=""
                                                height="100px" width="100px">
                                        </div>
                                    </div>
                                </form>
                                <div class="text-center  espace-voyageur ">
                                    <a class="btn text-white mt-2" v-on:click="updatePartage(editInput.id)">Modifier</a>
                                </div>
                                <div class="status"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
                <div slot="modal-footer">
                </div>
            </b-modal>
            <b-modal ref="modal-add" id="modal-add" size="lg" centered hide-footer>
        <div>
          <div class="modal-body espace-voyageur dir-rtl" style="margin-top: -33px;">
            <div class="row">
              <div class="col-md-12 mb-md-0 mb-5 ">
                <div class="container m-4">
                  <h3><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                      src="@/assets/images/img-bladi.png" />{{ $t('pop_up.form_partage.titre') }}</h3>
                  <div class="row mt-4">
                    <div class="col-md-11 ">
                      <div class="form-group">
                        <label for="name" class="">{{ $t('pop_up.form_partage.votre_titre') }}</label>
                        <input type="text" id="name" v-model="form.titre" class="form-control ">
                        <span class="text-danger" v-if="errors.titre">
                          {{ errors.titre[0] }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-11 mt-3 ">
                      <div class="form-group">
                        <label for="message">{{ $t('pop_up.form_partage.votre_message') }}</label>
                        <textarea type="text" id="message" name="message" rows="6" cols="10"
                          class="form-control md-textarea" v-model="form.description"></textarea>
                        <span class="text-danger" v-if="errors.description">
                          {{ errors.description[0] }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-11 mt-3 ">
                      <div class="form-group">
                        <label for="message">{{ $t('recherche.villes') }}</label>
                        <select class="form-control" v-model="form.ville_id">
                          <option value="" readonly>
                            {{ $t("recherche.villes") }}
                          </option>
                          <option v-for="ville of villes" :key="ville.id" :value="ville.id">
                            {{
                              $i18n.locale === "Français"  || $i18n.locale == "English" ? ville.ville : ville.ville_ar
                            }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="errors.description">
                          {{ errors.description[0] }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-2 mt-3">
                      <div class="form-group image-upload">
                        <label for="message">{{ $t('pop_up.form_partage.votre_image') }}</label>
                        <label for="file-input1">
                          <img src="@/assets/images/partage-icon.png" height="100" width="100" />
                        </label>
                        <input id="file-input1" type="file" @change="onFileChange2" class="form-control" />
                        <span class="text-danger" v-if="errors.image">
                          {{ errors.image[0] }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-5">
                      <img v-if="preview2" :src="preview2" width="100px" height="100px" />
                    </div>
                  </div>
                  <div class="cold-md-3 text-center">
                    <a class="btn btn-success " v-on:click="addPartage()">{{ $t('pop_up.form_partage.ajouter') }}</a>
                  </div>
                  <div class="status"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
        </div>
    </div>

</template>

<script>
import Api from "@/apis/Api";
import User from "@/apis/User";
import http from "@/http-common";

export default {
    name: "MesPartages",
    components: {
    },
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            partages: [],
            errors: [],
            villes: [],
            preview1: null,
            preview2: null,
            editInput: {
                id: '',
                user_id: '',
                titre: '',
                description: '',
                ville_id: '',
                image: '',
            },
            form: {
                user_id: '',
                titre: '',
                description: '',
                ville_id: '',
                image: '',
            },
        }
    },
    mounted() {
    console.log(process.env.VUE_APP_API_URL);
    this.isLoggedIn = !!localStorage.getItem('token');
    http
      .get("/villes")
      .then((response) => {
        this.villes = response.data;
        this.$root.$on("login", () => {
          this.isLoggedIn = true;
        });
        this.isLoggedIn = !!localStorage.getItem("token");
        this.role_id = localStorage.getItem("role_id");
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
    methods: {
        onFileChange1(event) {
            // this.editInput.image = event.target.files[0];
            // this.preview = URL.createObjectURL(this.editInput.image);
            this.editInput.image = event.target.files[0];
            this.preview1 = URL.createObjectURL(this.editInput.image);
        },
        onFileChange2(event) {
            this.form.image = event.target.files[0];
            this.preview2 = URL.createObjectURL(this.form.image);
        },
        edit(index) {
            this.editInput.titre = this.partages[index].titre;
            this.editInput.description = this.partages[index].description;
            this.editInput.image = this.partages[index].image;
            this.editInput.id = this.partages[index].id;
            this.editInput.ville_id = this.partages[index].ville_id;
        },
        updatePartage() {
            let formData = new FormData;
            formData.set('titre', this.editInput.titre);
            formData.set('description', this.editInput.description);
            formData.set('ville_id', this.editInput.ville_id);
            formData.set('image', this.editInput.image);
            Api().post(`/partages/${this.editInput.id}`, formData).then(() => {
                this.getUserPartages();
                this.$toaster.error('Votre post a été modifié.')
                this.$refs['modal-update'].hide();
            })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });

        },
        addPartage() {

        let addData = new FormData();
        addData.append('user_id', localStorage.getItem('id'));
        addData.append('titre', this.form.titre);
        addData.append('description', this.form.description);
        addData.append('ville_id', this.form.ville_id);
        addData.append('image', this.form.image);
        User.ajtPartage(addData)
        .then(() => {
            this.getUserPartages();
                this.$toaster.error('Votre post a été modifié.')
                this.$refs['modal-add'].hide();
        })
        .catch(error => {
            if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            }
        });

        },
        showMsgBoxTwo(id) {
            this.boxTwo = ''
            this.$bvModal.msgBoxConfirm('Veuillez confirmer que vous souhaitez supprimer ce message.', {
                title: 'Veuillez confirmer',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Oui',
                cancelTitle: 'Non',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    this.boxTwo = value
                    if (this.boxTwo) {
                        Api().delete(`/partages/${id}`)
                        this.getUserPartages();
                        this.$toaster.error('Votre post a été supprimé.')
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
        },

        getUserPartages() {
            const id = localStorage.getItem("id");
            Api().get(`/partages/user/${id}`).then(response => {
                this.partages = response.data.partages;
                
                console.log(this.partages)

            }).catch((errors) => {
                console.log(errors)
            });
        },

    },
    created() {
        this.getUserPartages();
    },


}
</script>

<style scoped>

.line {

    border-bottom: 5px solid #cd001f;

}
.image-upload>input {
  display: none;
}
.margin-auto{
    margin: auto;
}
.cont-background{
    height: 200px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #dee2e661;
}
</style>